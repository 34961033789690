import React from "react";
import { useHistory, Link } from "react-router-dom";

const SearchingResult = ({
  searchingData,
  location,
  setSearchingData,
  setShowSearchingComponent,
}) => {
  const history = useHistory();
  return (
    <section id="tpc-section" className="section">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-9 col-md-7 mb-4 mx-auto">
            <div className="title-area text-center">
              <h3 className="text-white">
                Searching Results (
                <span style={{ fontSize: "1rem" }}>
                  {searchingData.length} Results Found
                </span>
                )
              </h3>
            </div>
          </div>
          <div className="col-9 mb-3">
            <nav aria-label="Page breadcrumb">
              <ol
                className="breadcrumb p-0 mb-0"
                style={{ backgroundColor: "transparent" }}
              >
                <li className="breadcrumb-item active" aria-current="page">
                  <a
                    href="!#"
                    className="tpc-sm-cap"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowSearchingComponent(false);
                      setSearchingData([]);
                    }}
                  >
                    Back To Support Page
                  </a>
                </li>
              </ol>
            </nav>
          </div>
          {searchingData.length < 1 ? (
            <div className="col-9 bg-table" style={{ minHeight: "5rem" }}>
              <div className="title-area">
                <h4 className="text-white text-center">No Result Found</h4>
              </div>
            </div>
          ) : (
            searchingData.map((article, index) => (
              <div
                className="col-9 bg-table mb-5 searching-data-hover"
                style={{ minHeight: "5rem", cursor: "pointer" }}
              >
                <div
                  className="art-tpc tpc-wrapper w-100"
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    return history.push(
                      `/support-detail?category=${article.article_id}&item=${article.item_id}`
                    );
                  }}
                >
                  <div className="img-wrapper mb-3">
                    <img
                      className="img-fluid"
                      src={article.image}
                      alt="Thumbnail"
                      style={{
                        height: "8rem",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div className="tpc-details">
                    {/* <p
                className="tpc-cat tpc-sm-cap mb-2"
                style={{ letterSpacing: "1.2px" }}
              >
                TOPIC CATEGORY
              </p> */}
                    <h5 className="tpc-title md-txt text-white article-title-hover">
                      {article.title}
                    </h5>
                    <div className="tpc-meta d-flex">
                      <p className="tpc-date mr-3 mb-0">
                        <i className="fa fa-calendar-o" aria-hidden="true" />
                        <span className="date ml-2">{article.created}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default SearchingResult;
