import {PUT_SEEK_VALUE} from "../actions/Types";

const initialState = {
  seekValue : 0
}

export default function(state = initialState, action){
  const {type,payload} = action;

  switch(type){
    case PUT_SEEK_VALUE:
      return {
        ...state,
        seekValue:payload
      }
      default :
      return state
  }

}