import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as DownArrowIcon } from "../../assets/images/down-arrow.svg";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import { baseURL } from "../../global/global";
import { setAlert } from "../../actions/alert";
import { setLoader, removeLoader } from "../../actions/loader";
import Alert from "../alert/Alert";

const PublishModal = ({
  auth: { user, loading },
  videoData,
  close,
  setAlert,
  setLoader,
  loader,
  removeLoader,
}) => {
  const [socialAccounts, setSocialAccounts] = useState({});
  const [user_id, setUserId] = useState("");
  const [socialImages, setSocialImages] = useState([]);
  const [selectData, setSelectData] = useState([]);
  const [accountActive, setAccountActive] = useState("facebook");
  const [titleDesc, setTitleDesc] = useState({
    title: "",
    description: "",
    account: {},
  });

  const linkImages = {
    facebook: "./assets/images/logo-facebook.svg",
    youtube: "./assets/images/logo-youtube.svg",
    scriptreel: "./assets/images/logo-scriptreel.svg",
    syvid: "./assets/images/logo-syvideo.svg",
    trimreel: "./assets/images/logo-trim.svg",
    livereel: "./assets/images/logo-livereel.svg",
  };

  useEffect(() => {
    setUserId(loading || !user.user_id ? "" : user.user_id);
  }, [user, loading]);

  useEffect(() => {
    if (user_id) {
      fetchSocialAccounts({ user_id: user_id });
    }
  }, [user_id]);

  useEffect(() => {
    if (
      !(
        Object.keys(socialAccounts).length === 0 &&
        socialAccounts.constructor === Object
      )
    ) {
      setSelectData(socialAccounts.facebook);
      for (let x in socialAccounts) {
        if (socialAccounts[x].length > 0) {
          setSocialImages((value) => [...value, x]);
        }
      }
    }
  }, [socialAccounts]);

  // Fetching Social Accounts
  const fetchSocialAccounts = async (data) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(data);

    try {
      const res = await axios.post(
        `${baseURL}fetch-social-accounts`,
        body,
        config
      );

      if (res.data.status === true) {
        setSocialAccounts(res.data.data);
      }
    } catch (err) {
      console.log(err.response);
    }
  };

  const handleChange = (e) => {
    let eve = e.target;
    setTitleDesc((value) => ({ ...value, [eve.name]: eve.value }));
  };

  // Upload Video on Social Accounts
  const uploadVideoInSocialAccount = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setLoader();

    const body = JSON.stringify({
      user_id: user_id,
      title: titleDesc.title,
      description: titleDesc.description,
      video_url: videoData.video_url,
      network_id: titleDesc.account.id,
      network: accountActive,
    });

    try {
      const res = await axios.post(`${baseURL}upload-to-social`, body, config);

      if (res.data.status === true) {
        setAlert(res.data.message, "success");
        removeLoader();
        close((value) => ({ ...value, isOpen: false }));
      } else if (res.data.status === false) {
        removeLoader();
        setAlert("Connect your facebook account once again", "danger");
      }
    } catch (err) {
      removeLoader();
      setAlert("There is something issue", "danger");
      console.log(err.response);
    }
  };

  // Upload Video on reel app
  const uploadVideoOnReelApp = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setLoader();

    const body = JSON.stringify({
      title: titleDesc.title,
      description: titleDesc.description,
      video_url: videoData.video_url,
      integration_id: socialAccounts[accountActive][0].id,
    });

    try {
      const res = await axios.post(`${baseURL}push-to-app`, body, config);

      if (res.data.status === true) {
        setAlert(res.data.message, "success");
        removeLoader();
        close((value) => ({ ...value, isOpen: false }));
      } else if (res.data.status === false) {
        removeLoader();
        setAlert(res.data.message, "danger");
      }
    } catch (err) {
      removeLoader();
      setAlert("There is something issue", "danger");
      console.log(err.response);
    }
  };

  useEffect(() => {
    return () => removeLoader();
  }, [removeLoader]);

  return (
    <div
      id="publish"
      className="modal fade d-block show"
      tabIndex="-1"
      role="dialog"
      data-backdrop="static"
      aria-labelledby="modal-title"
      aria-hidden="true"
      style={{ overflowY: "scroll", background: "rgba(0,0,0,0.9)" }}
    >
      <Alert />
      <div
        className="modal-dialog modal-dialog-centered mx-auto"
        role="document"
        style={{ maxWidth: "750px" }}
      >
        <div className="modal-content">
          <button
            type="button"
            className="close mb-3 text-right"
            data-dismiss="modal"
            aria-label="Close"
            style={{ opacity: "1" }}
            onClick={() => {
              removeLoader();
              close((value) => ({ ...value, isOpen: false }));
            }}
          >
            <img
              className="img-fluid"
              src="./assets/images/close.svg"
              alt="Close"
            />
          </button>
          <div className="modal-body bg-white text-center">
            <h4
              className="modal-title mb-4"
              style={{ lineHeight: "1.33", color: "#000000" }}
            >
              Publish This Video{" "}
            </h4>
            <div className="row inner-row icons-wrapper justify-content-center mb-4 mx-auto">
              <div className="col-12 text-center">
                <div
                  className="row justify-content-center"
                  style={{ minHeight: "120px" }}
                >
                  {socialImages.map((value, index) => (
                    <div className="col-2" key={index}>
                      <div
                        className={`img-wrapper soc-icon mb-2 ${
                          accountActive === value ? "active" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectData(socialAccounts[value]);
                          setAccountActive(value);
                        }}
                      >
                        <img
                          className="img-fluid"
                          src={linkImages[value]}
                          alt={value}
                        />
                      </div>
                      <p
                        style={{
                          textTransform: "capitalize",
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "0.8rem",
                        }}
                      >
                        {value}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="row mb-3 justify-content-center">
              <div className="col-12">
                <p className="sm-txt m-0 publish-video-details">
                  Video Details
                </p>
              </div>
            </div>
            <div className="mb-2">
              <form
                id="pub-form"
                method="POST"
                className="my-0 mx-auto"
                style={{ maxWidth: "420px" }}
                onSubmit={(e) => {
                  e.preventDefault();
                  uploadVideoInSocialAccount();
                }}
              >
                <label className="form-input d-block w-100 mb-2">
                  <input
                    id="proj_title"
                    type="text"
                    name="title"
                    className="input d-block mt-0 w-100 mx-auto publish-input"
                    required
                    onChange={(e) => handleChange(e)}
                    value={titleDesc.title}
                  />
                  <span className="label publish-label">Title</span>
                </label>
                <label className="form-input d-block w-100 mb-2">
                  <textarea
                    id="proj_desc"
                    className="input d-block mt-0 w-100 mx-auto publish-input"
                    name="description"
                    style={{ height: "112px" }}
                    onChange={(e) => handleChange(e)}
                    value={titleDesc.description}
                  >
                    {" "}
                  </textarea>
                  <span
                    className="label publish-label"
                    style={{ top: "10px", transform: "translateY(0)" }}
                  >
                    Description
                  </span>
                </label>
                <label className="form-input d-block w-100 mb-4">
                  <a
                    id="sel-acc"
                    className="btn w-100 has-value"
                    data-toggle="dropdown"
                    href="!#"
                  >
                    {" "}
                    {titleDesc.account.title
                      ? titleDesc.account.title
                      : "Add Account"}
                  </a>
                  <span className="icon">
                    <svg width="14px" height="14px" viewBox="0 0 14 14">
                      <DownArrowIcon />
                    </svg>
                  </span>
                  <ul
                    id="sel-opts-wrapper"
                    className="dropdown-menu collapse rounded-0 w-100 m-0"
                  >
                    {selectData.length > 0
                      ? selectData.map((data) => (
                          <li
                            className="d-flex align-items-center"
                            key={data.id}
                          >
                            {/*<img className="img-fluid" src="" alt="Social Icon" />*/}
                            <a
                              href="!#"
                              className="option-item flex-grow-1"
                              onClick={(e) => {
                                e.preventDefault();
                                setTitleDesc((value) => ({
                                  ...value,
                                  account: data,
                                }));
                              }}
                            >
                              {data.title}
                            </a>
                          </li>
                        ))
                      : null}

                    <li className="d-flex align-items-center">
                      <NavLink
                        id="add-acc"
                        to="/integration"
                        className="flex-grow-1"
                      >
                        Add Account
                      </NavLink>
                    </li>
                  </ul>
                  <span className="label">Account</span>
                </label>
                {loader ? (
                  <button
                    id="pub-proj"
                    className="btn btn-3"
                    disabled
                    style={{
                      padding: "15px",
                      fontWeight: "700",
                      maxHeight: "50px",
                    }}
                  >
                    {" "}
                    <span
                      className="spinner-border spinner-border-sm text-light mx-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Publishing...
                  </button>
                ) : (
                  <button
                    id="pub-proj"
                    type="submit"
                    className="btn btn-3"
                    style={{
                      padding: "15px",
                      fontWeight: "700",
                      maxHeight: "50px",

                      cursor: `${
                        titleDesc.title === "" ||
                        (Object.keys(titleDesc.account).length === 0 &&
                          titleDesc.account.constructor === Object)
                          ? "not-allowed"
                          : "pointer"
                      }`,
                    }}
                    disabled={
                      titleDesc.title === "" ||
                      (Object.keys(titleDesc.account).length === 0 &&
                        titleDesc.account.constructor === Object)
                        ? true
                        : false
                    }
                    onClick={
                      accountActive === "facebook" ||
                      accountActive === "youtube"
                        ? uploadVideoInSocialAccount
                        : uploadVideoOnReelApp
                    }
                  >
                    Publish
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loader: state.loader.loader,
});

PublishModal.propTypes = {
  auth: PropTypes.object.isRequired,
  videoData: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  loader: PropTypes.bool,
  removeLoader: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  setAlert,
  setLoader,
  removeLoader,
})(PublishModal);
