import React, { useState, useEffect } from "react";
import SideNav from "../SideNav";
import AllTemplate from "./AllTemplate";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import TitleBar from "../../Common/TitleBar";

const Template = ({ auth: { user, loading } }) => {
  const [is_client, setIsClient] = useState(false);
  useEffect(() => {
    if (user) {
      setIsClient(loading || !user.is_client ? false : user.is_client);
    }
  }, [user, loading]);

  if (is_client === true) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <div>
      <TitleBar title="Create Videos" />
      <section id="editor-section" className="bg-dark section-topmargin">
        <div className="container-fluid px-0" style={{ minHeight: "90vh" }}>
          <div className="row mx-auto" style={{ minHeight: "90vh" }}>
            <div className="col colmn-1 col-1 px-0 bg-dark">
              <SideNav activeTab={"template"} />
            </div>
            <div
              className="col colmn-2 col-11 px-0 flex-grow-1"
              style={{ maxWidth: "100%" }}
            >
              <div id="editor-tab-wrap" className="tab-content">
                <AllTemplate />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Template);
