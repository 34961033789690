import React from "react";
import { ReactComponent as ImageSearchIcon } from "../../../../../../assets/images/search.svg";
import PropTypes from "prop-types";
import {applyObjectReplace, applyTextObjectProperties} from "../../../../../../actions/normalTemplateActions";
import {connect} from "react-redux";

function Image({
                 setImagesSearchBool,
                 setkeyWord,
                 ImgAndAudio,
                 searchImagesAndAudio,
                 loader,
                 applyObjectReplace
               }) {

  const handleClick = (e,url) => {
    e.preventDefault();

    applyObjectReplace({
      type: 'image',
      id:0,
      source:{
        thumbnail:url,
        url:url
      }
    });
  }
  return (
      <>
        <div
            id="images"
            className="tab-pane fade show active"
            role="tabpanel"
            aria-labelledby="images-tab"
        >
          <div className="input-group mb-3">
            <form className="w-100" onSubmit={searchImagesAndAudio}>
              <input
                  id="search-img"
                  type="search"
                  name="search-img"
                  className="search-img-media form-control input-dark edit-input-height"
                  placeholder="Search"
                  onChange={(e) => {
                    setImagesSearchBool(false);
                    setkeyWord(e.target.value);
                  }}
              />
              <div className="input-group-prepend v-center cursor-pointer">
                <svg width="16px" height="16px" viewBox=" 0 0 16 16">
                  <ImageSearchIcon />
                </svg>
              </div>
            </form>
          </div>

          <div
              id="images_scroll"
              className="results-wrapper d-flex flex-wrap justify-content-center image-search-box"
              style={{maxHeight:"540px"}}
          >
            {ImgAndAudio.map((value, index) => {
              return (
                  <a href="/" className="add-img" key={index} onClick={(e)=>handleClick(e,value.url)}>
                    <img
                        src={value.thumbnail.length > 0 ? value.thumbnail : value.url}
                        className="img-fluid"
                        alt=""
                    />
                  </a>
              );
            })}
            {loader ? (
                <div
                    style={{
                      height: "75px",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                >
                  <div className="spinner-grow" style={{ color: "#1cb7f2" }}>
                    {" "}
                  </div>
                </div>
            ) : null}
          </div>
        </div>
      </>
  );
}

Image.propTypes = {
  setkeyWord: PropTypes.func.isRequired,
  setImagesSearchBool: PropTypes.func.isRequired,
  ImgAndAudio: PropTypes.array.isRequired,
  searchImagesAndAudio: PropTypes.func.isRequired,
  loader: PropTypes.bool.isRequired,
  applyObjectReplace: PropTypes.func.isRequired
};

export default connect(null, {applyObjectReplace})(Image);

