import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import BusinessNav from "../businessNav/BusinessNav";

const DfyCourse = ({ user, loading }) => {
  const [accountType, setAccountType] = useState([]);

  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  if (accountType.length > 0) {
    if (!(accountType.includes("bundle") || accountType.includes("dfy") || accountType.includes("com"))) {
      return <Redirect to="/dashboard" />;
    }
  }

  const firstCol = [
    {
      src:
        "https://coursereel-app.s3-us-west-2.amazonaws.com/CourseEngagementHacks.zip",
      value: 1,
    },
    {
      src:
        "https://coursereel-app.s3-us-west-2.amazonaws.com/EcommerceWithWoocommerce.zip",
      value: 2,
    },
    {
      src:
        "https://coursereel-app.s3-us-west-2.amazonaws.com/GoogleAdsMastery.zip",
      value: 3,
    },
    {
      src:
        "https://coursereel-app.s3-us-west-2.amazonaws.com/HowToUseWebinarsForYourBusiness.zip",
      value: 4,
    },
    {
      src:
        "https://coursereel-app.s3-us-west-2.amazonaws.com/IMForNewbiesDeluxe.zip",
      value: 5,
    },
    {
      src:
        "https://coursereel-app.s3-us-west-2.amazonaws.com/ModernPodcastingUp.zip",
      value: 6,
    },
    {
      src:
        "https://coursereel-app.s3-us-west-2.amazonaws.com/SimpleSocialMediaContent.zip",
      value: 7,
    },
    {
      src:
        "https://coursereel-app.s3-us-west-2.amazonaws.com/SimpleSocialMediaContent.zip",
      value: 8,
    },
    {
      src:
        "https://coursereel-app.s3-us-west-2.amazonaws.com/TIKTOKADSMASTERY.zip",
      value: 9,
    },
    {
      src:
        "https://coursereel-app.s3-us-west-2.amazonaws.com/UdemyForRecurringIncome.zip",
      value: 10,
    },
  ];

  const secondCol = [
    {
      src:
        "https://acadeable.s3.amazonaws.com/dfycoursex196/HealthyBoundaries_GOLD.zip",
      value: 11,
    },
    {
      src:
        "https://acadeable.s3.amazonaws.com/dfycoursex196/GOLD-WorkFromHome.zip",
      value: 12,
    },
    {
      src:
        "https://acadeable.s3.amazonaws.com/dfycoursex196/GOLD-PersonalBrandingBlueprint.zip",
      value: 13,
    },
    {
      src:
        "https://acadeable.s3.amazonaws.com/dfycoursex196/FacebookAdSecrets.zip",
      value: 14,
    },
    {
      src:
        "https://acadeable.s3.amazonaws.com/dfycoursex196/EmailMarketingBasicsVideo.zip",
      value: 15,
    },
    {
      src:
        "https://acadeable.s3.amazonaws.com/dfycoursex196/EcommerceWithWoocommerce.zip",
      value: 16,
    },
    {
      src:
        "https://acadeable.s3.amazonaws.com/dfycoursex196/DealClosingSecrets.zip",
      value: 17,
    },
    {
      src:
        "https://acadeable.s3.amazonaws.com/dfycoursex196/CourseEngagementHacks.zip",
      value: 18,
    },
    {
      src:
        "https://acadeable.s3.amazonaws.com/dfycoursex196/ClicksAndTraffic.zip",
      value: 19,
    },
    {
      src:
        "https://acadeable.s3.amazonaws.com/dfycoursex196/AntiAnexietyFormulaVideoUp.zip",
      value: 20,
    },
  ];

  return (
    <DfyStyle>
      <div className="contract-assets">
        <section id="contract-assets-content">
          <div className="container">
            <div className="row justify-content-center align-items-between fb-contract py-5">
              <BusinessNav />
              <div className="col-9 mb-5 ">
                <h6
                  className="text-white mb-0 mt-5 Account-Management-headings"
                  style={{ fontSize: "18px !important" }}
                >
                  DFY Courses
                </h6>
                <div className="row bg-table justify-content-center mt-2">
                  <div className="col-6">
                    <div className="row">
                      {firstCol.map((value) => (
                        <div className="col-10 mt-3">
                          <div className="card fb-card border-0">
                            <div className="card-body">
                              <a
                                className="btn btn-3 accnt-mngmt-btn p-2"
                                style={{ fontSize: "0.9rem" }}
                                href="!#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location = value.src;
                                }}
                              >
                                Download DFY Course {value.value}
                              </a>
                            </div>
                            <div className="card-footer p-0"></div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      {secondCol.map((value) => (
                        <div className="col-10 mt-3">
                          <div className="card fb-card border-0">
                            <div className="card-body">
                              <a
                                className="btn btn-3 accnt-mngmt-btn p-2"
                                style={{ fontSize: "0.9rem" }}
                                href="!#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location = value.src;
                                }}
                              >
                                Download DFY Course {value.value}
                              </a>
                            </div>
                            <div className="card-footer p-0"></div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </DfyStyle>
  );
};

const DfyStyle = styled.div`
  .btn-3 {
    max-width: 100% !important;
  }
  a {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
});

export default connect(mapStateToProps)(DfyCourse);
