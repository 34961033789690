import React, { useState } from "react";
import Facebook from "./facebook/Facebook";
import Youtube from "./youtube/Youtube";
import ScriptReel from "./scriptreel/ScriptReel";
import SyVid from "./syvideo/Syvid";
import TrimReel from "./trimreel/TrimReel";
import LiveReel from "./livereel/LiveReel";
import ConfirmDelete from "../confirmdelete/ConfirmDelete";
import CommonModal from "./CommonModal";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { deleteIntegrationAccount } from "../../actions/socialAccount";
import { removeAlert } from "../../actions/alert";
import Alert from "../alert/Alert";

function MyConnection({
  social,
  userGenId,
  deleteIntegrationAccount,
  removeAlert,
}) {
  const [modal, setModal] = useState({
    open: false,
    appName: false,
  });

  const [confirmDelete, setConfirmDelete] = useState({
    status: false,
    id: 0,
  });

  const handleClick = (type) => {
    setModal({
      ...modal,
      open: true,
      appName: type,
    });
    removeAlert();
  };

  const handleClose = () => {
    setModal({
      ...modal,
      open: false,
      appName: false,
    });
  };

  const handleDelete = (id) => {
    setConfirmDelete({
      status: true,
      id: id,
    });
  };

  const handleConfirmClose = () => {
    setConfirmDelete({
      status: false,
      id: 0,
    });
  };

  const handleConfirmDelete = async (id) => {
    const data = {
      user_id: userGenId,
      integration_id: confirmDelete.id,
    };
    await deleteIntegrationAccount(data);
    handleConfirmClose();
  };

  return (
    <>
      {modal.open ? null : <Alert />}
      <div className="row">
        <div className="col colmn-1 col-12 mb-4">
          <div className="title-area">
            <h4 className="text-white" style={{ fontWeight: 500 }}>
              My Connections
            </h4>
          </div>
        </div>
        <div className="col colmn-2 col-12">
          <Facebook accounts={social.facebook} userGenId={userGenId} />
          <Youtube accounts={social.youtube} userGenId={userGenId} />
          <SyVid
            accounts={social.syvid}
            click={handleClick}
            deleteAcc={handleDelete}
          />
          <ScriptReel
            accounts={social.scriptreel}
            click={handleClick}
            deleteAcc={handleDelete}
          />
          <TrimReel
            accounts={social.trimreel}
            click={handleClick}
            deleteAcc={handleDelete}
          />
          <LiveReel
            accounts={social.livereel}
            click={handleClick}
            deleteAcc={handleDelete}
          />
          {modal.open ? (
            <CommonModal appName={modal.appName} close={handleClose} />
          ) : null}
        </div>
        {confirmDelete.status ? (
          <ConfirmDelete
            close={handleConfirmClose}
            confirm={handleConfirmDelete}
          />
        ) : null}
      </div>
    </>
  );
}

MyConnection.propTypes = {
  deleteIntegrationAccount: PropTypes.func.isRequired,
  removeAlert: PropTypes.func.isRequired,
};

export default connect(null, { deleteIntegrationAccount, removeAlert })(
  MyConnection
);
