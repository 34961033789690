import React from "react";
import { ReactComponent as ImageSearchIcon } from "../../../../../../assets/images/search.svg";
import HoverVideoPlayer from "react-hover-video-player";
import PropTypes from "prop-types";
import { applyObjectReplace } from "../../../../../../actions/normalTemplateActions";
import { connect } from "react-redux";

function Video({
  setImagesSearchBool,
  setkeyWord,
  videoLibrary,
  searchImagesAndAudio,
  loader,
  applyObjectReplace,
}) {
  return (
    <>
      <div
        id="vid-clips"
        className="tab-pane fade"
        role="tabpanel"
        aria-labelledby="vid-clips-tab"
      >
        <div className="input-group mb-3">
          <form className="w-100" onSubmit={searchImagesAndAudio}>
            <input
              id="search-vid"
              type="search"
              name="search-vid"
              className="search-vid-media form-control input-dark py-1 edit-input-height"
              placeholder="Search"
              onChange={(e) => {
                setImagesSearchBool(false);
                setkeyWord(e.target.value);
              }}
            />
            <div className="input-group-prepend v-center cursor-pointer">
              <svg width="16px" height="16px" viewBox=" 0 0 16 16">
                <ImageSearchIcon />
              </svg>
            </div>
          </form>
        </div>
        <div id="videos_scroll" className="results-wrapper d-flex flex-wrap">
          {videoLibrary.map((value, index) => (
            <a
              href="!#"
              className="add-img"
              onClick={(e) => {
                e.preventDefault();

                applyObjectReplace({
                  type: "video",
                  id: 0,
                  source: {
                    thumbnail: value.thumbnail,
                    url: value.url,
                  },
                });
              }}
              key={index}
            >
              
              <HoverVideoPlayer
                style={{ height: '100%' }}
                videoSrc={value.url}
               
                loadingOverlay={
                  <div style={{ color: "#00c4a6", display: 'flex', alignItems: "center", justifyContent: "center", height: "100%" }}>
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                }
              />
            
            </a>
          ))}

          {loader ? (
            <div
              className="spinner-grow"
              style={{ color: "#1cb7f2", height: "75px" }}
            >
              {" "}
            </div>
          ) : null}
        </div>
        {/* <UploadModal /> */}
      </div>
    </>
  );
}

Image.propTypes = {
  setkeyWord: PropTypes.func.isRequired,
  setImagesSearchBool: PropTypes.bool.isRequired,
  videoLibrary: PropTypes.array.isRequired,
  searchImagesAndAudio: PropTypes.func.isRequired,
  loader: PropTypes.bool.isRequired,
};

export default connect(null, { applyObjectReplace })(Video);
