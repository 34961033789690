import React, { useState } from "react";

const ConfirmDelete = ({ close, confirm ,message}) => {
  const [buttonText, setButtonText] = useState("Yes, Delete Now");
  const handleConfirm = () => {
    setButtonText("Deleting...");
    confirm();
  };
  return (
    <div
      id="confirm-del"
      className="modal fade show d-block"
      style={{ background: "rgba(0,0,0,0.9)" }}
      tabIndex={-1}
      role="dialog"
      data-backdrop="static"
      aria-labelledby="delete-warn"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered mx-auto"
        role="document"
      >
        <div className="modal-content">
          <button
            type="button"
            className="close mb-3 text-right"
            data-dismiss="modal"
            aria-label="Close"
            style={{ opacity: "1" }}
            onClick={close}
          >
            <img
              className="img-fluid"
              src="./assets/images/close.svg"
              alt="Close"
            />
          </button>
          <div className="modal-body bg-white text-center">
            <div className="img-wrapper mb-3 pb-1 pt-5">
              <img
                className="img-fluid"
                src="./assets/images/error.svg"
                alt="Warning"
              />
            </div>
            <h4
              className="modal-title mb-2"
              id="delete-warn"
              style={{ color: "#000000" }}
            >
              Delete Warning
            </h4>
            <p className="w-100 mx-auto mb-3 pb-3 text-center mr-auto gdpr-warning-text">
              Are you sure you want to delete this ? { message !== '' ? message : 'This action is permanent.' }
            </p>
            <div className="modal-footer border-top-0 p-0 pb-5 justify-content-center">
              <button
                type="button"
                className="btn btn-2 fs-12"
                data-dismiss="modal"
                onClick={close}
              >
                Cancel
              </button>
              <button
                style={{width: "200px"}}
                id="confirm"
                type="button"
                className="btn btn-3 text-white fs-12"
                onClick={handleConfirm}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDelete;
