import React from 'react'
import { Modal } from 'react-bootstrap'
import { FaSpinner } from 'react-icons/fa'
import { IoDownloadOutline } from 'react-icons/io5'
import { FaRegTrashAlt } from "react-icons/fa";
import { AiOutlineClose } from 'react-icons/ai';
const CommonAlert = (props) => {
    return (
        <Modal className="white_label_modal" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
            <span className="white_modalClose text-right d-block" onClick={props.handleClose}><AiOutlineClose /></span>
                <div className="text-center">
                    <div className="white_modalIcon">
                        {props.icon === "delete" ?
                            <FaRegTrashAlt /> :
                            <IoDownloadOutline />
                        }
                    </div>
                    {/* <div className="modalIcon"><IoDownloadOutline /></div> */}
                </div>
                <div className="white-titleBar-left">
                    <p className="pt-4 text-center">{props.message}</p>
                </div>
                <div className="btnGroup mt-4 mb-4 text-center">
                    <button
                        className="white-theme-btn mr-2"
                        onClick={props.handleClose}
                        style={{
                            background: props.cancelButtonColor
                        }}
                    >
                        <span>{props.cancelButtonName}</span>
                    </button>
                    <button
                        className="white-theme-btn"
                        onClick={props.performDelete}
                        disabled={props.alertLoader}
                        style={{
                            background: props.confirmButtonColor
                        }}
                    >
                        <span>{props.confirmButtonName} {props.alertLoader ? <FaSpinner className='spin' /> : null}</span>
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CommonAlert