import React from "react";
import WebFontLoader from '@dr-kobros/react-webfont-loader';
const RecordTextObject = (props,ref)  => {
    const config = {google: {
        families: ['Pacifico'],
    }}
    return(
         <WebFontLoader config={config}>
             <div ref={ref} className="record-absolute record-text-object" style={props.objectItem.styles}>
                {props.objectItem.value}
            </div>
         </WebFontLoader>
    )

}
export default React.forwardRef(RecordTextObject);