import React from "react";

import AudioTrack from "./AudioTrack";

function TextToSpeech({
  ttsLangVoice,
  ttsUpdation,
  tts,
  ttsVoice,
  ttsAudioUrl,
  generate,
  ttsLoader,
  ttsName,
  setTtsName,
  user_id,
}) {
  return (
    <div
      id="tts"
      className="tab-pane fade"
      role="tabpanel"
      aria-labelledby="tts-tab"
    >
      <div className="input-group mb-3">
        <form className="w-100" onSubmit={(e) => e.preventDefault()}>
          <div className="form-group form-group-mb">
            <label className="input-label">Name </label>
            <input
              className="form-control input-dark-accnt personal-info  w-100"
              type="text"
              name="firstName"
              placeholder="Name"
              value={ttsName}
              onChange={(e) => setTtsName(e.target.value)}
              required
              style={{ height: "50px" }}
              autoComplete="off"
            />
          </div>
          <div className="form-group form-group-mb">
            <label className="input-label">Language </label>
            <select
              className="form-control input-account-type p-accnt"
              name="language"
              value={ttsLangVoice.language}
              onChange={(e) => {
                ttsUpdation(e);
              }}
              style={{ height: "50px" }}
            >
              <option value="-Select-">-Select-</option>
              {tts.length > 0
                ? tts.map((ttsVal) => {
                    return (
                      <option key={ttsVal.id} value={ttsVal.language_code}>
                        {ttsVal.name}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>

          <div className="form-group form-group-mb">
            <label className="input-label">Voice </label>
            <select
              className="form-control input-account-type p-accnt"
              name="voice"
              value={ttsLangVoice.voice}
              onChange={(e) => {
                ttsUpdation(e);
              }}
              style={{ height: "50px" }}
            >
              <option value="-Select-">-Select-</option>
              {ttsVoice.length > 0
                ? ttsVoice.map((voice) => {
                    return (
                      <option value={voice.voice_id} key={voice.id}>
                        {voice.voice_id}({voice.gender})
                      </option>
                    );
                  })
                : null}
            </select>
          </div>

          <div className="form-group form-group-mb">
            <textarea
              className="form-control input-dark-accnt personal-info"
              type="text"
              name="text"
              placeholder="Enter text to generate speech"
              value={ttsLangVoice.text}
              onChange={(e) => {
                ttsUpdation(e);
              }}
              autoComplete="off"
              required
              style={{ padding: "10px", height: "8rem" }}
            />
          </div>

          <button
            id="up-media-img"
            className="up-img-btn btn btn-3 mb-3 text-white d-block mx-auto"
            onClick={() => {
              generate();
            }}
            disabled={
              ttsLangVoice.language !== "-Select-" &&
              ttsLangVoice.voice !== "-Select-" &&
              ttsLangVoice.text !== "" &&
              ttsName !== ""
                ? false
                : true
            }
          >
            Generate
          </button>
        </form>
      </div>
      <div className="card pb-3">
        <div id="your-music" className="card-header"></div>

        <div className="card-body mt-3 p-0">
          <div className="results-wrapper d-flex flex-wrap justify-content-center">
            {ttsLoader ? (
              <div
                className="spinner-grow"
                style={{ color: "#1cb7f2", width: "80px", height: "80px" }}
              >
                {" "}
              </div>
            ) : null}

            <AudioTrack audio={ttsAudioUrl} user_id={user_id} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TextToSpeech;
