import React, { useEffect, useState, useCallback } from "react";
import Image from "./Image";
import Video from "./Video";
import { baseURL } from "../../../../../../global/global";
import axios from "axios";
import PropTypes from "prop-types";
import Upload from "./Upload";

// Redux
import { connect } from "react-redux";
import { removeAlert, setAlert } from "../../../../../../actions/alert";
import { fetchLoadTemplate } from "../../../../../../actions/video";
import UploadModal from "../../../../../uploadModal/UploadModal";

function Media({
  setAlert,
  removeAlert,
  auth: { user, loading },
  loadingTemplate,
  fetchLoadTemplate,
}) {
  const [user_id, setUserId] = useState("");
  const [pageNum, setPageNum] = useState(1);
  // const [keyWord, setkeyWord] = useState("");
  const [imageKeyWord, setImageKeyWord] = useState("");
  const [videoKeyWord, setVideoKeyWord] = useState("");
  const [ImgAndAudio, setImgAndAudio] = useState([]);
  const [scrollImagesNot, setScrollImagesNot] = useState(false);
  const [scrollVideosNot, setScrollVideosNot] = useState(false);
  const [videoLibraryPageNo, setVideoLibraryPageNo] = useState(1);
  const [imagesOrVideo, setImagesOrVideo] = useState("image");
  const [videoLibrary, setVideoLibrary] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isImagesBottom, setIsImagesBottom] = useState(false);
  const [isVideosBottom, setIsVideosBottom] = useState(false);
  const [imagesSearchbool, setImagesSearchBool] = useState(false);
  const [uploadData, setUploadData] = useState([]);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const [uploadStuff, setUploadStuff] = useState({
    uploadedPercentage: 0,
    show: false,
  });
  const [sourceCancel, setSourceCancel] = useState({});

  // get use id from redux
  useEffect(() => {
    if (user) {
      setUserId(loading || !user.user_id ? "" : user.user_id);
    }
  }, [user, loading]);

  // fetching uploaded image from api
  useEffect(() => {
    if (user_id !== "") {
      fetchLoadTemplate({ type: "image", user_id: user_id });
    }
  }, [fetchLoadTemplate, user_id]);

  //  fetching image from redux and save in state(upload image = uploadData)
  useEffect(() => {
    setUploadData(loadingTemplate.uploadImage);
  }, [loadingTemplate.loading, loadingTemplate.uploadImage]);

  //   searching images & videos with keyword
  const searchImagesAndAudio = (e) => {
    e.preventDefault();

    if (imagesOrVideo === "image") {
      setScrollImagesNot(false);
      setPageNum(1);
      getImagesAndAudio()();
    } else if (imagesOrVideo === "video") {
      setScrollVideosNot(false);
      setVideoLibraryPageNo(1);
      getImagesAndAudio()();
    }
  };

  //  Fetching images and videos from Library and
  // store in state(imges = ImgAndAudio)(video = videoLibrary)
  const getImagesAndAudio = useCallback(
    () => async () => {
      if (setImageKeyWord === "" && setVideoKeyWord === "") {
        return;
      }
      if (imagesOrVideo === "image") {
        if (pageNum === 1) {
          setImgAndAudio([]);
        }
      } else if (imagesOrVideo === "video") {
        if (videoLibraryPageNo === 1) {
          setVideoLibrary([]);
        }
      }

      setLoader(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        type: imagesOrVideo === "video" ? "video" : "images",
        keyword: imagesOrVideo === "video" ? videoKeyWord : imageKeyWord,
        page_number: imagesOrVideo === "video" ? videoLibraryPageNo : pageNum,
      });

      try {
        const res = await axios.post(
          `${baseURL}load-library-images`,
          body,
          config
        );

        if (res.data.status === true) {
          if (res.data.data === false) {
            setLoader(false);
            if (imagesOrVideo === "image") {
              setScrollImagesNot(true);
              setIsImagesBottom(false);
              setAlert("!Sorry, No Images Found", "danger");
            } else if (imagesOrVideo === "video") {
              setScrollVideosNot(true);
              setIsVideosBottom(false);
              setAlert("!Sorry, No Videos Found", "danger");
            }
            return;
          }
          setLoader(false);
          if (imagesOrVideo === "image") {
            if (res.data.data === null) {
              setScrollImagesNot(true);
              setAlert("Sorry!, No More Images", "danger");
            } else {
              setImgAndAudio((value) => [...value, ...res.data.data]);
            }
            setIsImagesBottom(false);
          } else if (imagesOrVideo === "video") {
            if (res.data.data === null) {
              setScrollVideosNot(true);
              setAlert("Sorry!, No More Videos", "danger");
            } else {
              setVideoLibrary((value) => [...value, ...res.data.data]);
            }
            setIsVideosBottom(false);
          }
        }
      } catch (err) {
        setLoader(false);
        console.log(err.response);
      }
    },
    [
      imageKeyWord,
      videoKeyWord,
      pageNum,
      setAlert,
      videoLibraryPageNo,
      imagesOrVideo,
    ]
  );

  // Uploading images from local
  const uploadImages = async (e) => {
    if (!e.target.files[0]) return;
    const fileSize = e.target.files[0].size / (1024 * 1024);
    const fileName = e.target.files[0].name;

    if (fileSize <= 5) {
      const fileExtensionArray = fileName.split(".");
      const fileExtension = fileExtensionArray[fileExtensionArray.length - 1];
      if (
        fileExtension === "jpg" ||
        fileExtension === "jpeg" ||
        fileExtension === "png" ||
        fileExtension === "mp4"
      ) {
        setUploadStuff((value) => ({
          ...value,
          show: true,
        }));
        setUploadLoader(true);
        setSelectedFile(e.target.files[0]);
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append(
          "upload_type",
          fileExtension === "mp4" ? "video" : "image"
        );
        formData.append("user_id", user_id);

        let source = axios.CancelToken.source();
        setSourceCancel(source);
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percentage = Math.floor((loaded * 100) / total);
            if (percentage < 99) {
              setUploadStuff((value) => ({
                ...value,
                uploadedPercentage: percentage,
              }));
            }
          },
          cancelToken: source.token,
        };

        try {
          const res = await axios.post(
            `${baseURL}upload-project-image`,
            formData,
            config
          );

          if (res.data.status === true) {
            fetchLoadTemplate({ type: "image", user_id: user_id });
            setSelectedFile({});
            setUploadLoader(false);
            setUploadStuff((value) => ({
              ...value,
              show: false,
              uploadedPercentage: 0,
            }));
          } else {
            setUploadLoader(false);
          }
        } catch (err) {
          console.log(err.response);
          setUploadLoader(false);
        }
      } else {
        setAlert(
          "The file extension you are trying to upload is not allowed, extension allowed only(png, jpeg, jpg,mp4)",
          "danger"
        );
      }
    } else {
      setAlert("You cannot upload more then 5MB in size", "danger");
    }
  };

  // onScrolling load images
  useEffect(() => {
    if (pageNum > 1 && imagesSearchbool) {
      getImagesAndAudio()();
      setImagesSearchBool(false);
    }
  }, [pageNum, getImagesAndAudio, imagesSearchbool]);

  // onScrolling load videos
  useEffect(() => {
    if (videoLibraryPageNo > 1 && imagesSearchbool) {
      getImagesAndAudio()();
      setImagesSearchBool(false);
    }
  }, [videoLibraryPageNo, getImagesAndAudio, imagesSearchbool]);

  // load images on Scrolling
  useEffect(() => {
    if (imageKeyWord !== "") {
      const getScrollId = document.getElementById("images_scroll");

      if (getScrollId) {
        getScrollId.addEventListener("scroll", handleImageScroll);
      }
      return () => {
        if (getScrollId) {
          getScrollId.removeEventListener("scroll", handleImageScroll);
        }
      };
    }
  }, [imageKeyWord]);

  function handleImageScroll() {
    const getScrollId = document.getElementById("images_scroll");

    if (getScrollId) {
      const scrollTop = getScrollId.scrollTop;
      const scrollHeight = getScrollId.scrollHeight;

      if (scrollTop + window.innerHeight + 100 >= scrollHeight) {
        setIsImagesBottom(true);
      }
    } else {
      return;
    }
  }

  useEffect(() => {
    if (!scrollImagesNot) {
      if (isImagesBottom) {
        if (document.getElementById("images_scroll")) {
          setImagesSearchBool(true);
          if (imageKeyWord !== "") {
            if (imagesOrVideo === "image") {
              setPageNum((value) => ++value);
            }
          }
        }
      }
    }
  }, [isImagesBottom, imageKeyWord, imagesOrVideo, scrollImagesNot]);

  // image Scroll finshed //

  // load videos on Scrolling
  useEffect(() => {
    if (videoKeyWord !== "") {
      const getScrollId = document.getElementById("videos_scroll");

      if (getScrollId) {
        getScrollId.addEventListener("scroll", handleVideoScroll);
      }
      return () => {
        if (getScrollId) {
          getScrollId.removeEventListener("scroll", handleVideoScroll);
        }
      };
    }
  }, [videoKeyWord]);

  function handleVideoScroll() {
    const getScrollId = document.getElementById("videos_scroll");
    if (getScrollId) {
      const scrollTop = getScrollId.scrollTop;
      const scrollHeight = getScrollId.scrollHeight;

      if (scrollTop + window.innerHeight + 100 >= scrollHeight) {
        setIsVideosBottom(true);
      }
    } else {
      return;
    }
  }

  useEffect(() => {
    if (!scrollVideosNot) {
      if (isVideosBottom) {
        if (document.getElementById("videos_scroll")) {
          setImagesSearchBool(true);
          if (videoKeyWord !== "") {
            if (imagesOrVideo === "video") {
              setVideoLibraryPageNo((value) => ++value);
            }
          }
        }
      }
    }
  }, [isVideosBottom, videoKeyWord, imagesOrVideo, scrollVideosNot]);

  // Scroll finshed //

  return (
    <>
      <ul
        id="media-tabs"
        className="nav nav-tabs border-0 justify-content-center mb-4"
        role="tablist"
      >
        <li className="nav-item">
          <a
            id="images-tab"
            className="nav-link label active"
            data-toggle="tab"
            href="#images"
            role="tab"
            aria-controls="images"
            aria-selected="true"
            onClick={() => {
              setImagesOrVideo("image");
            }}
          >
            Images
          </a>
        </li>
        <li className="nav-item">
          <a
            id="vid-clips-tab"
            className="nav-link label"
            data-toggle="tab"
            href="#vid-clips"
            role="tab"
            aria-controls="vid-clips"
            aria-selected="false"
            onClick={() => {
              setImagesOrVideo("video");
            }}
          >
            Video
          </a>
        </li>
        <li className="nav-item">
          <a
            id="upload-tab"
            className="nav-link label"
            data-toggle="tab"
            href="#upload-clips"
            role="tab"
            aria-controls="upload-clips"
            aria-selected="false"
          >
            Uploads
          </a>
        </li>
      </ul>
      <div id="media-tab-cont" className="tab-content">
        <Image
          setImagesSearchBool={setImagesSearchBool}
          setkeyWord={setImageKeyWord}
          ImgAndAudio={ImgAndAudio}
          searchImagesAndAudio={searchImagesAndAudio}
          uploadData={uploadData}
          uploadImages={uploadImages}
          loader={loader}
        />
        <Video
          videoLibrary={videoLibrary}
          setkeyWord={setVideoKeyWord}
          setImagesSearchBool={setImagesSearchBool}
          searchImagesAndAudio={searchImagesAndAudio}
          uploadData={uploadData}
          uploadImages={uploadImages}
          loader={loader}
        />
        <Upload
          uploadData={uploadData}
          uploadImages={uploadImages}
          uploadLoader={uploadLoader}
          user_id={user_id}
        />
      </div>
      {uploadStuff.show ? (
        <UploadModal
          show={setUploadStuff}
          percentage={uploadStuff.uploadedPercentage}
          removeLoader={() => setUploadLoader(false)}
          sourceCancel={sourceCancel}
          close={() =>
            setUploadStuff((value) => ({
              ...value,
              show: false,
              uploadedPercentage: 0,
            }))
          }
          heading={"Uploading media..."}
          desc={"Your media is uploading"}
        />
      ) : null}
    </>
  );
}

Media.propTypes = {
  removeAlert: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  fetchLoadTemplate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loadingTemplate: state.loadingTemplate,
});

export default connect(mapStateToProps, {
  setAlert,
  removeAlert,
  fetchLoadTemplate,
})(Media);
