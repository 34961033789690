import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { ReactComponent as ProjectIcon } from "../../assets/images/ico-tiles.svg";
import { ReactComponent as Slides } from "../../assets/images/ico-slides.svg";
import { connect } from "react-redux";

const SideNav = ({ activeTab, user, loading }) => {
  const [is_client, setIsClient] = useState(false);
  useEffect(() => {
    if (user) {
      setIsClient(loading || !user.is_client ? false : user.is_client);
    }
  }, [user, loading]);
  return (
    <>
      <div
        id="editor-nav"
        className="nav flex-column nav-pills text-center nav-sidebar-bg"
        role="tablist"
        aria-orientation="vertical"
      >
        {is_client === true ? null : (
          <Link
            id="projects-tab"
            className={`nav-link v-tab-link ${
              activeTab === "template" ? "active" : ""
            }`}
            to="/create-videos"
            role="tab"
            aria-controls="projects-tab-cont"
            aria-selected="true"
          >
            <span className="icon d-block mb-2">
              <svg width="21px" height="21px" viewBox="0 0 21 21">
                <ProjectIcon />
              </svg>
            </span>
            <span style={{ fontSize: "14px" }}>Templates</span>
          </Link>
        )}
        {is_client === true ? null : (
          <Link
            id="script-tab"
            className={`nav-link v-tab-link  ${
              activeTab === "editor" ? "active" : ""
            }`}
            to={
              activeTab === "template"
                ? "/create-videos"
                : activeTab === "myvideo"
                ? "/my-videos"
                : "/editor"
            }
            role="tab"
            aria-controls="script-tab-cont"
            aria-selected="false"
          >
            <span className="icon d-block mb-2">
              <svg width="19px" height="24px" viewBox="0 0 19 24">
                <Slides />
              </svg>
            </span>
            <span style={{ fontSize: "14px" }}>Editor</span>
          </Link>
        )}
        <Link
          id="save-tab"
          className={`nav-link v-tab-link ${
            activeTab === "myvideo" ? "active" : ""
          }`}
          to="/my-videos"
          role="tab"
          aria-controls="save-tab-cont"
          aria-selected="false"
        >
          <span className="icon d-block mb-2">
            <svg width="24px" height="20px" viewBox="0 0 41 34">
              <use xlinkHref="./assets/images/ico-video.svg#ico-video" />
            </svg>
          </span>
          <span style={{ fontSize: "14px" }}>My Videos</span>
        </Link>
      </div>
    </>
  );
};

SideNav.propTypes = {
  activeTab: PropTypes.string.isRequired,
  user: PropTypes.object,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
});

export default connect(mapStateToProps)(SideNav);
