import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import BusinessNav from "../businessNav/BusinessNav";

const SocialMedia = ({ user, loading }) => {
  const [accountType, setAccountType] = useState([]);

  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  if (accountType.length > 0) {
    if (
      !(
        accountType.includes("bundle") ||
        accountType.includes("dfy") ||
        accountType.includes("dfylite") || accountType.includes("com")
      )
    ) {
      return <Redirect to="/dashboard" />;
    }
  }

  const firstCol = [
    {
      src:
        "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/24+Month+Social+Media+Calendar.xlsx",
      value: "Social Media Calendar",
    },
    {
      src:
        "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/800+Motivational+Videos.zip",
      value: "Social Media Video Posts",
    },
    {
      src:
        "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/6900%2B+Facts+Images.zip",
      value: "Social Media Image Posts",
    },
  ];

  const secondCol = [
    {
      src:
        "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/Captions.xlsx",
      value: "Social Media Captions",
    },
    {
      src:
        "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/3565TopQuotePics.zip",
      value: "Inspirational, Motivational Quotes Images",
    },
    {
      src:
        "https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/180K-famous-quotations5RR.zip",
      value: "Library of Quotes for Social Media",
    },
  ];

  return (
    <DfyStyle>
      <div className="contract-assets">
        <section id="contract-assets-content">
          <div className="container">
            <div className="row justify-content-center align-items-between fb-contract py-5">
              <BusinessNav />
              <div className="col-9 ">
                <h6
                  className="text-white mb-0 mt-5 Account-Management-headings"
                  style={{ fontSize: "18px !important" }}
                >
                  Social Media Assets
                </h6>

                <div className="row bg-table justify-content-center">
                  <div className="col-8">
                    <div class="card fb-card border-0">
                      <div
                        class="card-header"
                        style={{ background: "#1cb7f2" }}
                      >
                        <h5 className="text-light">Social Media Assets</h5>
                      </div>
                      <div class="card-body">
                        Download your plug & play social media assets bellow
                      </div>
                      <div class="card-footer p-0"></div>
                    </div>
                  </div>
                </div>

                <div className="row bg-table justify-content-center mt-2">
                  <div className="col-6">
                    <div className="row">
                      {firstCol.map((value) => (
                        <div className="col-10 mt-3">
                          <div className="card fb-card border-0">
                            <div className="card-body">
                              <a
                                className="btn btn-3 accnt-mngmt-btn p-2"
                                style={{ fontSize: "0.9rem" }}
                                href="!#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location = value.src;
                                }}
                              >
                                {value.value}
                              </a>
                            </div>
                            <div className="card-footer p-0"></div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      {secondCol.map((value) => (
                        <div className="col-10 mt-3">
                          <div className="card fb-card border-0">
                            <div className="card-body">
                              <a
                                className="btn btn-3 accnt-mngmt-btn p-2"
                                style={{ fontSize: "0.9rem" }}
                                href="!#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location = value.src;
                                }}
                              >
                                {value.value}
                              </a>
                            </div>
                            <div className="card-footer p-0"></div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </DfyStyle>
  );
};

const DfyStyle = styled.div`
  .btn-3 {
    max-width: 100% !important;
  }

  a {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
});

export default connect(mapStateToProps)(SocialMedia);
