import {
  NORMAL_TIMELINE,
  RECONFIGURE_TIMELINE,
  RESELECT_TIMELINE,
  REARRANGE_TIMELINE,
  DELETE_REARRANGE_TIMELINE,
  SET_TIMELINE_PLAYTIME,
  ADD_TIMELINE
} from "../actions/Types";
import undoable from "redux-undo";
const initialState = {
  code: false,
  templateId: 0,
  template_duration: 0,
  currentSeek:0
};

const normalTemplates = (state = initialState, action) => {
  switch (action.type) {
    case NORMAL_TIMELINE:
    case RECONFIGURE_TIMELINE:
    case REARRANGE_TIMELINE:
    case DELETE_REARRANGE_TIMELINE:
    case ADD_TIMELINE:
      return {
        ...state,
        code: action.payload,
      };
    case RESELECT_TIMELINE:
      return {
        ...state,
        code:action.payload.code,
        currentSeek: action.payload.currentSeek
      }
    case SET_TIMELINE_PLAYTIME:
      return{
        ...state,
        currentSeek: action.payload
    }
    default:
      return state;
  }
};

const normalTimelineF = undoable(normalTemplates);
export default normalTimelineF;
