import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import BusinessNav from "../businessNav/BusinessNav";

const VideoPromos = ({ user, loading }) => {
  const [accountType, setAccountType] = useState([]);

  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  if (accountType.length > 0) {
    if (!(accountType.includes("bundle") || accountType.includes("dfy") || accountType.includes("com"))) {
      return <Redirect to="/dashboard" />;
    }
  }

  return (
    <ContractStyle>
      <div className="contract-assets">
        <section id="contract-assets-content">
          <div className="container">
            <div className="row justify-content-center align-items-between fb-contract mt-5">
              <BusinessNav />
              <div className="col-9 mb-4">
                <h6
                  className="text-white  mb-0 mt-5 mb-2 Account-Management-headings"
                  style={{ fontSize: "18px !important" }}
                >
                  Video Promos
                </h6>
                <div className="row bg-table justify-content-center">
                  <div className="col-8">
                    <div class="card fb-card border-0">
                      <div
                        class="card-header"
                        style={{ background: "#1cb7f2" }}
                      >
                        <h5 className="text-light">Video Promos</h5>
                      </div>
                      <div class="card-body">
                        <p className="text-dark">
                          Open a ticket with the following details per video:
                        </p>
                        <ol>
                          <li>Your VideoReel credentials</li>
                          <li>Permission to access your account</li>
                          <li>
                            What is the purpose of this video that you wish to
                            create?
                          </li>
                          <li>Name of the template you wish to use</li>
                          <li> Number of the template you wish to use</li>
                          <li>Screenshot of the template you wish to use</li>
                          <li>
                            Content for the video. Please click on the EDIT
                            option for the template you wish to use and share
                            with us all the text content, logo, music file or
                            Text-to-speech content along with images/video clips
                            you wish to use in your videos.
                          </li>
                        </ol>
                      </div>
                      <div class="card-footer p-0"></div>
                    </div>
                  </div>
                </div>

                <div className="row bg-table mb-3 justify-content-center secHT">
                  <div className="col-4 mt-2">
                    <div class="card fb-card border-0">
                      <div class="card-body">
                        {" "}
                        <a
                          className="btn btn-3 accnt-mngmt-btn "
                          style={{ fontSize: "0.9rem" }}
                          target="_blank"
                          href="https://support.vineasx.com"
                        >
                          <div>Raise a Ticket</div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="col-9 mt-5">
                    <div class="card fb-card border-0">
                      <div class="card-header text-center">NOTE</div>
                      <div class="card-body">
                        {" "}
                        we have the rights to deny a request if it does not
                        match with the video template requirements. Video
                        creation can take up to 5-6 working days. The video will
                        be available inside your VideoReel Account.
                      </div>
                      <div class="card-footer p-0"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </ContractStyle>
  );
};

const ContractStyle = styled.div`
  .btn-3 {
    max-width: 100% !important;
  }

  a {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li {
    line-height: 1.5rem;
  }
`;

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
});

export default connect(mapStateToProps)(VideoPromos);
