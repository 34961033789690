import React, { useState, useEffect } from "react";
import queryString from "query-string";
import "./style.css";
import axios from "axios";
import { baseURL } from "../../global/global";
import useDynamicRefs from "use-dynamic-refs";
import RecordVideoObject from "./RecordVideoObject";
import RecordTextObject from "./RecordTextObject";
import RecordImageObject from "./RecordImageObject";
import { gsap, TimelineMax, CSSPlugin } from "gsap";
gsap.registerPlugin(CSSPlugin);
// const JsonData = require("../../global/source.json");
let globalTimeline = false;
const Extra = ({ location }) => {
  const [animateDate, setAnimateDate] = useState([]);
  const [getRef, setRef] = useDynamicRefs();
  const id = queryString.parse(location.search).id;
  let objectArray = [];
  useEffect(() => {
    loadVideo();
    // if (JsonData.length > 0) {
    //   JsonData.map((item, index) => {
    //     item.objects.map((objectItem) => {
    //       const objStyle = {
    //         ...objectItem.styles,
    //       };

    //       if (objStyle.hasOwnProperty("width"))
    //         objStyle.width = objectItem.styles.width * 4;
    //       if (objStyle.hasOwnProperty("height"))
    //         objStyle.height = objectItem.styles.height * 4;
    //       if (objStyle.hasOwnProperty("top"))
    //         objStyle.top = objectItem.styles.top * 4;
    //       if (objStyle.hasOwnProperty("left"))
    //         objStyle.left = objectItem.styles.left * 4;
    //       if (objStyle.hasOwnProperty("fontSize"))
    //         objStyle.fontSize = objectItem.styles.fontSize * 4;

    //       objectArray.push({
    //         ...objectItem,
    //         styles: objStyle,
    //       });
    //     });
    //   });
    // }
    // console.log(objectArray, "updatedArray");
    // setAnimateDate(objectArray);
  }, [id]);

  const loadVideo = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ project_id: id });

    try {
      const res = await axios.post(`${baseURL}edit-project`, body, config);
      if (res.data.status === true) {
        const JsonData = res.data.data[0].data;
        console.log(res.data.data[0].data);

        if (JsonData.length > 0) {
          JsonData.map((item, index) => {
            item.objects.map((objectItem) => {
              const objStyle = {
                ...objectItem.styles,
              };

              if (objStyle.hasOwnProperty("width"))
                objStyle.width = objectItem.styles.width * 4;
              if (objStyle.hasOwnProperty("height"))
                objStyle.height = objectItem.styles.height * 4;
              if (objStyle.hasOwnProperty("top"))
                objStyle.top = objectItem.styles.top * 4;
              if (objStyle.hasOwnProperty("left"))
                objStyle.left = objectItem.styles.left * 4;
              if (objStyle.hasOwnProperty("fontSize"))
                objStyle.fontSize = objectItem.styles.fontSize * 4;
              if (objStyle.hasOwnProperty("clip"))
              {
                const c = objStyle.clip;
                const cR = c.replace("rect(","").replace(")","").split(",");
                objStyle.clip = "rect("+parseInt(cR[0])*4+ ","+ parseInt(cR[1])*4+ ", "+ parseInt(cR[2])*4+", "+ parseInt(cR[3])*4+")"
              }

              objectArray.push({
                ...objectItem,
                styles: objStyle,
              });
            });
          });
        }
        setAnimateDate(objectArray);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    globalTimeline = new TimelineMax({paused:true});
    animateDate.forEach((item, index) => {
      const subTimeLine = new TimelineMax();
      item.transition.forEach((trans, transIndex) => {
        const fromStyle = {
          ...trans.from,
        };
        if (fromStyle.hasOwnProperty("width"))
          fromStyle.width = trans.from.width * 4;
        if (fromStyle.hasOwnProperty("height"))
          fromStyle.height = trans.from.height * 4;
        if (fromStyle.hasOwnProperty("top")) fromStyle.top = trans.from.top * 4;
        if (fromStyle.hasOwnProperty("left")) fromStyle.left = trans.from.left * 4;
        if (fromStyle.hasOwnProperty("y")) fromStyle.y = trans.from.y * 4;
        if (fromStyle.hasOwnProperty("x")) fromStyle.x = trans.from.x * 4;
        if (fromStyle.hasOwnProperty("fontSize"))
          fromStyle.fontSize = trans.from.fontSize * 4;
        if (fromStyle.hasOwnProperty("clip"))
        {
          const c = fromStyle.clip;
          const cR = c.replace("rect(","").replace(")","").split(",");
          fromStyle.clip = "rect("+parseInt(cR[0])*4+ ","+ parseInt(cR[1])*4+ ", "+ parseInt(cR[2])*4+", "+ parseInt(cR[3])*4+")"
        }

        const toStyle = {
          ...trans.to,
        };
        if (toStyle.hasOwnProperty("width")) toStyle.width = trans.to.width * 4;
        if (toStyle.hasOwnProperty("height"))
          toStyle.height = trans.to.height * 4;
        if (toStyle.hasOwnProperty("top")) toStyle.top = trans.to.top * 4;
        if (toStyle.hasOwnProperty("left")) toStyle.left = trans.to.left * 4;
        if (toStyle.hasOwnProperty("y")) toStyle.y = trans.to.y * 4;
        if (toStyle.hasOwnProperty("x")) toStyle.x = trans.to.x * 4;
        if (toStyle.hasOwnProperty("fontSize"))
          toStyle.fontSize = trans.to.fontSize * 4;
        if (toStyle.hasOwnProperty("clip"))
        {
          const c = toStyle.clip;
          const cR = c.replace("rect(","").replace(")","").split(",");
          toStyle.clip = "rect("+parseInt(cR[0])*4+ ","+ parseInt(cR[1])*4+ ", "+ parseInt(cR[2])*4+", "+ parseInt(cR[3])*4+")"
        }

        console.log(fromStyle,toStyle)
        subTimeLine.fromTo(getRef(item.id).current, fromStyle, toStyle);
      });
      globalTimeline.add(subTimeLine, item.startTime);
    });

    setTimeout(function () {
      globalTimeline.play();
    }, 2000)

  }, [animateDate.length]);
  return (
    <div className="record-canvas">
      {animateDate
        .slice(0)
        .reverse()
        .map((objectItem, objectIndex) => {
          if (objectItem.objectType === "video") {
            return (
              <RecordVideoObject
                key={"obj_" + objectIndex}
                objectItem={objectItem}
                ref={setRef(objectItem.id)}
              />
            );
          } else if (objectItem.objectType === "text") {
            return (
              <RecordTextObject
                key={"obj_" + objectIndex}
                objectItem={objectItem}
                ref={setRef(objectItem.id)}
              />
            );
          } else if (objectItem.objectType === "image") {
            return (
              <RecordImageObject
                key={"obj_" + objectIndex}
                objectItem={objectItem}
                ref={setRef(objectItem.id)}
              />
            );
          } else {
            return false;
          }
        })}
    </div>
  );
};

export default Extra;
