import React from "react";
import {
  applyObjectSelect,
  loadTemplate,
  setTimeLinePlayTime,
} from "../../../../../../../actions/normalTemplateActions";
import { connect } from "react-redux";
function Tracks(props) {
  const object = props.object;
  const style1 = {
    left: props.object.startTime * 60 + "px",
  };
  const style2 = {
    width: props.object.duration * 60 + "px",
  };
  let style2F;
  if (
    props.object.objectType === "video" ||
    props.object.objectType === "image"
  ) {
    style2F = {
      ...style2,
      background: "url(" + object.source.thumbnail + ")",
      backgroundRepeat: "repeat-x",
      backgroundSize: "contain",
    };
  }
  if (props.object.objectType === "text") {
    style2F = {
      ...style2,
      background: object.styles.backgroundColor,
      color: object.styles.color,
    };
  }
  if (props.object.objectType === "audio") {
    style2F = {
      ...style2,
      backgroundColor: "#2ab365",
    };
  }

  const handleClick = (e, id) => {
    //props.setTimeLinePlayTime(props.object.startTime);
    props.applyObjectSelect(id);
  };

  return (
    <div
      className={
        "timeline-slide " + (object.isSelected === true ? "selected" : "")
      }
      style={style1}
      onClick={(e) => handleClick(e, object.id)}
    >
      <div className="slide-tile" style={style2F}>
        <span className="slide-title" style={style2F}>
          {object.value}
        </span>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    applyObjectSelect: (id) => dispatch(applyObjectSelect(id)),
    setTimeLinePlayTime: (newTime) => dispatch(setTimeLinePlayTime(newTime)),
  };
};
export default connect(null, mapDispatchToProps)(Tracks);
