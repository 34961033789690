import React from "react";
// import { ReactComponent as ImageSearchIcon } from "../../../../../../assets/images/search.svg";
import AudioTrack from "./AudioTrack";
import AudioUpload from "./AudioUpload";
import PropTypes from "prop-types";
// import { parseUrl } from "query-string";

function UploadAudio({
  audio,
  uploadAudio,
  uploadAudioFunc,
  loader,
  uploadLoader,
  user_id,
}) {
  return (
    <div
      id="upload-audio"
      className="tab-pane fade show "
      role="tabpanel"
      aria-labelledby="upload-tab"
    >
      <AudioUpload
        uploadAudio={uploadAudio}
        uploadAudioFunc={uploadAudioFunc}
        uploadLoader={uploadLoader}
        user_id={user_id}
      />
    </div>
  );
}

UploadAudio.propTypes = {
  audio: PropTypes.array.isRequired,
  uploadAudio: PropTypes.array.isRequired,
  uploadAudioFunc: PropTypes.func.isRequired,
  loader: PropTypes.bool.isRequired,
  uploadLoader: PropTypes.bool.isRequired,
};

export default UploadAudio;
