import {FETCH_ACCOUNTS, CLEAR_SOCIAL_ACCOUNTS} from "../actions/Types";
const initialState = {
   
    youtube:[],
    facebook:[],
    scriptreel:[],
    livereel:[],
    trimreel:[],
    syvid:[],
    loading:true
  
}

export default function (state = initialState, action) {
    const{type,payload} = action;
    switch (type) {
        case FETCH_ACCOUNTS:
            return{
                    loading:false,
                    youtube:payload.youtube,
                facebook:payload.facebook,
                scriptreel:payload.scriptreel,
                livereel:payload.livereel,
                trimreel:payload.trimreel,
                syvid:payload.syvid
                
            }
        case CLEAR_SOCIAL_ACCOUNTS:
             return {
                 ...initialState
             }
        default:
            return {
                ...state
            }
    }
}