import React, {useEffect, useState} from "react";
import { ReactComponent as UndoIcon } from "../../../../../../assets/images/undo.svg";
import { ReactComponent as RedoIcon } from "../../../../../../assets/images/redo.svg";
import { ReactComponent as LayerBackIcon } from "../../../../../../assets/images/layer-back.svg";
import { ReactComponent as LayerFrontIcon } from "../../../../../../assets/images/layer-front.svg";
import { ReactComponent as DuplicateIcon } from "../../../../../../assets/images/duplicate.svg";
import { ReactComponent as DeleteIcon } from "../../../../../../assets/images/delete.svg";
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import { connect } from 'react-redux'
import {applyObjectDelete, applyObjectRearrange} from "../../../../../../actions/normalTemplateActions";
function Controls(props) {
    const [object, setObject] = useState(props.object);
    const styleUndo = {
        color:props.canUndo ? '#ffffff' : '#4f5050'
    }
    const styleRedo = {
        color:props.canRedo ? '#ffffff' : '#4f5050'
    }

    const handleClick = (e,type) => {
        e.preventDefault();
        if(type === 'undo')
        {
            if(props.canUndo === true){
                props.onUndo()
            }
        }
        else
        {
            if(props.canRedo === true){
                props.onRedo()
            }
        }
    }

    const handleReArrange = (e,type) => {
        e.preventDefault();
        const rearrange = {
            id:object.id,
            type:type,
            trackId:object.trackId
        }
        props.reArrange(rearrange);
    }

    const handleLayerDelete = (e) => {
      e.preventDefault();
      const delObject = {
          id:object.id,
          trackId:object.trackId
      }
      props.deleteObject(delObject);
    }

    useEffect( () => {
        setObject(props.object)
    }, [props.object])
    return (
        <ul className="controls d-flex flex-wrap justify-content-center align-items-center">
            <li className="control-btn pr-4">
                <a id="undo" href="/" onClick={(e) => handleClick(e,'undo')} className="text-white" data-toggle="tooltip" title="Undo" style={styleUndo}>
                    <svg width="18px" height="18px" className="d-block" viewBox="0 0 18 18">
                        <UndoIcon />
                    </svg>
                </a>
            </li>
            <li className="control-btn pr-4 mr-4 border-right">
                <a id="redo" href="/" onClick={(e) => handleClick(e,'redo')} className="text-white" data-toggle="tooltip" title="Redo" style={styleRedo}>
                    <svg width="18px" height="18px" className="d-block" viewBox="0 0 18 18">
                        <RedoIcon />
                    </svg>
                </a>
            </li>
            <li className="control-btn pr-4">
                <a id="layer-back" href="/" onClick={(e) => handleReArrange(e,'back')} className="text-white" data-toggle="tooltip" title="Layer Back" >
                    <svg width="18px" height="18px" className="d-block" viewBox="0 0 18 18">
                        <LayerBackIcon />
                    </svg>
                </a>
            </li>
            <li className="control-btn pr-4 mr-4 border-right">
                <a id="layer-front" href="/" onClick={(e) => handleReArrange(e,'forward')} className="text-white" data-toggle="tooltip"
                   title="Layer Front" >
                    <svg width="18px" height="18px" className="d-block" viewBox="0 0 18 18">
                        <LayerFrontIcon />
                    </svg>
                </a>
            </li>
            {/*<li className="control-btn pr-4 mr-4 border-right" >*/}
            {/*    <a id="duplicate" href="#" className="text-white" data-toggle="tooltip" title="Duplicate">*/}
            {/*        <svg width="18px" height="18px" className="d-block" viewBox="0 0 18 18">*/}
            {/*            <DuplicateIcon />*/}
            {/*        </svg>*/}
            {/*    </a>*/}
            {/*</li>*/}
            <li className="control-btn" >
                <a id="delete" href="/" onClick={(e) => handleLayerDelete(e)} className="text-white" data-toggle="tooltip" title="Delete" >
                    <svg width="18px" height="18px" className="d-block" viewBox="0 0 18 18">
                        <DeleteIcon />
                    </svg>
                </a>
            </li>
        </ul>
    )
}

const mapStateToProps = state => {
    return {
        canUndo: state.normalTemplates.past.length > 1,
        canRedo: state.normalTemplates.future.length > 0
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUndo: () => dispatch(UndoActionCreators.undo()),
        onRedo: () => dispatch(UndoActionCreators.redo()),
        reArrange: (rearrange) => dispatch(applyObjectRearrange(rearrange)),
        deleteObject: (newObject) => dispatch(applyObjectDelete(newObject))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Controls)