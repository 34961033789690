import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { connect } from "react-redux";
import { masterLogin } from "../../actions/auth";

const MasterLogin = ({ history, masterLogin, location }) => {
  const email = queryString.parse(location.search).email;
  const token = queryString.parse(location.search).token;

  useEffect(() => {
    if (email !== "" && token !== "") {
      let newToken = "";

      token.split(" ").forEach((element) => {
        newToken = newToken.concat(element + "+");
      });
      newToken = newToken.slice(0, newToken.length - 1);
      masterLogin(email, newToken, history);
    }
  }, [email, token]);

  return <div></div>;
};

export default connect(null, { masterLogin })(withRouter(MasterLogin));
