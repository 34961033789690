import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Plyr from "plyr";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import BusinessNav from "../businessNav/BusinessNav";

// let playerPlyr = false;

const AgencyWebsite = ({ user, loading }) => {
  const [accountType, setAccountType] = useState([]);
  const [playerPlyr, setPlayerPlyr] = useState(false);

  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  // const destroyPlayer = () => {
  //   if (playerPlyr.length > 0) {
  //     playerPlyr[0].pause();
  //     // for (const playerEl of player) {
  //     //     playerEl.pause();
  //     // }
  //   }
  // };

  useEffect(() => {
    if (accountType.length > 0) {
      setPlayerPlyr(Plyr.setup("#player"));
      // playerPlyr = Plyr.setup("#player");
      return () => {
        if (playerPlyr.length > 0) {
          playerPlyr[0].pause();
          // for (const playerEl of player) {
          //     playerEl.pause();
          // }
        }
      };
    }
  }, [accountType]);

  if (accountType.length > 0) {
    if (!(accountType.includes("bundle") || accountType.includes("business"))) {
      return <Redirect to="/dashboard" />;
    }
  }

  return (
    <AgencyStyle>
      <div className="contract-assets">
        <section id="contract-assets-content">
          <div className="container">
            <div className="row justify-content-center align-items-between fb-contract py-5">
              <BusinessNav />
              <div className="col-9 ">
                <h6
                  className="text-white  mb-0 mt-5 mb-2 Account-Management-headings"
                  style={{ fontSize: "18px !important" }}
                >
                  Agency
                </h6>
                <div className="row bg-table justify-content-center">
                  <div className="col-8">
                    <div className="card fb-card border-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-2 d-flex align-items-center justify-content-center">
                            <img
                              src="./assets/images/round-icon-wordpress.png"
                              alt="wordpress"
                            />
                          </div>
                          <div className="col-10 d-flex align-items-center">
                            Fresh Install and configure wordpress at your
                            server. If you need help in installing and
                            configuring wordpress, Click here to find the
                            tutorial.
                          </div>
                        </div>
                      </div>
                      <div className="card-footer p-0"></div>
                    </div>
                  </div>
                  <div className="col-8 mt-3">
                    <div className="card fb-card border-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-2 d-flex align-items-center justify-content-center">
                            <img
                              src="./assets/images/round-icon-download.png"
                              alt="download"
                            />
                          </div>
                          <div className="col-10 d-flex align-items-center flex-column">
                            <div>
                              Once wordpress is installed at your server.
                              Download the site content by clicking the
                              "Download site button". This will download a
                              wpress file which you will be needing to import in
                              your wordpress site.
                            </div>

                            <a
                              className="btn btn-3 accnt-mngmt-btn p-2 mt-2"
                              style={{
                                fontSize: "0.9rem",
                                color: "white",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              href="!#"
                              onClick={(e) => {
                                e.preventDefault();
                                window.location =
                                  "https://misctrainingvideos.s3.amazonaws.com/Agency+Website/AgencyWebsite_new.wpress";
                              }}
                            >
                              Download the site
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer p-0"></div>
                    </div>
                  </div>
                  <div className="col-8 mt-3">
                    <div className="card fb-card border-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-2 d-flex align-items-center justify-content-center">
                            <img
                              src="./assets/images/round-icon-import.png"
                              alt="import"
                            />
                          </div>
                          <div className="col-10 d-flex align-items-center">
                            Import the xml file in to your wordpress site. If
                            you need help importing the xml file in wordpress,
                            Click here to find the tutorial.
                          </div>
                        </div>
                      </div>
                      <div className="card-footer p-0"></div>
                    </div>
                  </div>
                  <div className="col-8 mt-3">
                    <div className="card fb-card border-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-2 d-flex align-items-center justify-content-center">
                            <img
                              src="./assets/images/round-icon-customize.png"
                              alt="customize"
                            />
                          </div>
                          <div className="col-10 d-flex align-items-center">
                            Customize the site as according to your need. If you
                            need help customizing the site. Click here to find
                            the tutorial
                          </div>
                        </div>
                      </div>
                      <div className="card-footer p-0"></div>
                    </div>
                  </div>
                </div>

                <h6
                  className="text-white mb-0 mt-5 Account-Management-headings"
                  style={{ fontSize: "18px !important" }}
                >
                  Tutorials
                </h6>
                <div className="row bg-table justify-content-center mt-2">
                  <div className="col-6 mt-2">
                    <div className="row">
                      <div className="col-11">
                        <div className="card fb-card border-0">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-3 d-flex align-items-center justify-content-center">
                                <img
                                  src="./assets/images/round-icon-wordpress.png"
                                  alt="wordpress"
                                  style={{ width: "3rem", height: "3rem" }}
                                />
                              </div>
                              <div className="col-9 d-flex align-items-center">
                                <a
                                  className="tutr"
                                  target="-blank"
                                  href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Install+Wordpress+in+cPanel.pdf"
                                >
                                  Install Wordpress from cPanel
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer p-0"></div>
                        </div>
                      </div>
                      <div className="col-11 mt-2">
                        <div className="card fb-card border-0">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-3 d-flex align-items-center justify-content-center">
                                <img
                                  src="./assets/images/round-icon-import.png"
                                  alt="import"
                                  style={{ width: "3rem", height: "3rem" }}
                                />
                              </div>
                              <div className="col-9 d-flex align-items-center">
                                <a
                                  className="tutr"
                                  target="-blank"
                                  href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Import+Agency+Data.pdf"
                                >
                                  Import SyVid Agency data
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer p-0"></div>
                        </div>
                      </div>
                      <div className="col-11 mt-2">
                        <div className="card fb-card border-0">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-3 d-flex align-items-center justify-content-center">
                                <img
                                  src="./assets/images/round-icon-customize.png"
                                  alt="customize"
                                  style={{ width: "3rem", height: "3rem" }}
                                />
                              </div>
                              <div className="col-9 d-flex align-items-center">
                                <a
                                  className="tutr"
                                  target="-blank"
                                  href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Customize+Agency+Website.pdf"
                                >
                                  Customize website
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer p-0"></div>
                        </div>
                      </div>
                      <div className="col-11 mt-2">
                        <div className="card fb-card border-0">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-3 d-flex align-items-center justify-content-center">
                                <img
                                  src="./assets/images/round-icon-usage.png"
                                  alt="usage"
                                  style={{ width: "3rem", height: "3rem" }}
                                />
                              </div>
                              <div className="col-9 d-flex align-items-center">
                                <a
                                  className="tutr"
                                  target="-blank"
                                  href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Upload+Video+and+Usage+in+Agency+Website.pdf"
                                >
                                  Upload Video and Usage in Agency website
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer p-0"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mt-2">
                    <div className="card fb-card border-0">
                      <div
                        className="card-header text-center"
                        style={{ background: "#007bff" }}
                      >
                        <h6
                          className="text-white Account-Management-headings"
                          style={{
                            fontSize: "18px !important",
                          }}
                        >
                          How to Install the Agency Website
                        </h6>
                      </div>
                      <div
                        className="card-body p-0"
                        style={{ height: "19rem" }}
                      >
                        <div className="vid-wrapper">
                          <div
                            className="plyr__video-embed"
                            id="player"
                            // data-plyr-provider="vimeo"
                            // data-plyr-embed-id="512159766"
                          >
                            <iframe
                              src="https://player.vimeo.com/video/514577280?background=1&loop=1&byline=0&title=0"
                              frameBorder="0"
                              allow="autoplay"
                              allowFullScreen
                              width="100%"
                              height="100%"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </AgencyStyle>
  );
};

const AgencyStyle = styled.div`
  .btn-3 {
    max-width: 100% !important;
  }

  .tutr {
    cursor: pointer;
    font-weight: bold;
  }

  .tutr:hover {
    color: #1cb7f2 !important;
  }
`;

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
});

export default connect(mapStateToProps)(AgencyWebsite);
