import React, { useState, useEffect } from "react";
import HoverVideoPlayer from "react-hover-video-player";
import PropTypes from "prop-types";
import { fetchAllProject } from "../../actions/video";
import { connect } from "react-redux";

const Media = ({
  auth: { user, loading },
  fetchAllProject,
  project,
  setSelectVideo,
  prevwImg,
}) => {
  const [user_id, setUserId] = useState("");
  const [projects, setProjects] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setUserId(loading || !user.user_id ? "" : user.user_id);
  }, [user, loading]);

  useEffect(() => {
    if (user_id) {
      fetchAllProject({ user_id });
    }
  }, [user_id, fetchAllProject]);

  useEffect(() => {
    setProjects(project.allProject);
  }, [project.allProject, project.loading]);

  return (
    <div>
      <ul
        id="media-tabs"
        className="nav nav-tabs border-0 justify-content-center mb-4"
        role="tablist"
      >
        <li className="nav-item">
          <a
            id="vid-clips-tab"
            className="nav-link label active"
            data-toggle="tab"
            href="#vid-clips"
            role="tab"
            aria-controls="vid-clips"
            aria-selected="false"
          >
            My Projects
          </a>
        </li>
      </ul>
      <div id="media-tab-cont" className="tab-content">
        <div
          id="vid-clips"
          className="tab-pane fade d-block show"
          role="tabpanel"
          aria-labelledby="vid-clips-tab"
        >
          <div id="videos_scroll" className="results-wrapper d-flex flex-wrap">
            {projects.filter((curElem)=>curElem.output_video!=="").map((value, index) => (
              <a
                href="!#"
                key={index}
                className="add-img"
                style={{ position: "relative" }}
                onClick={(e) => {
                  e.preventDefault();
                  if (prevwImg.includes(value.project_id)) {
                    return;
                  }

                  setSelectVideo((select) => {
                    return [
                      ...select,
                      {
                        preview_image: value.preview_image_url,
                        url: value.output_video,
                        name: value.project_name,
                        project_id: value.project_id,
                      },
                    ];
                  });
                }}
              >
                <img
                  src={value.preview_image_url}
                  className="img-fluid"
                  style={{
                    margin: "0px",
                    padding: "0px",
                    width: "100%",
                    height: "100%",
                  }}
                  alt=""
                />
                <div
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    width: "22px",
                    height: "22px",

                    display: `${
                      prevwImg.includes(value.project_id) ? "block" : "none"
                    }`,

                    cursor: "pointer",
                  }}
                >
                  <img
                    src="./assets/images/tik.png"
                    alt=""
                    style={{ minHeight: "22px" }}
                  />
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  project: state.video,
});

Media.propTypes = {
  auth: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  fetchAllProject: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  fetchAllProject,
})(Media);
