import React, { useState } from "react";
import { ReactComponent as ImagePlusIcon } from "../../../../../../assets/images/plus_1.svg";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { fetchLoadTemplate } from "../../../../../../actions/video";
import { setAlert, removeAlert } from "../../../../../../actions/alert";
import { baseURL } from "../../../../../../global/global";
import { applyObjectReplace } from "../../../../../../actions/normalTemplateActions";

import axios from "axios";
import ConfirmDelete from "../../../../../confirmdelete/ConfirmDelete";

function Upload({
  uploadData,
  uploadImages,
  uploadLoader,
  user_id,
  fetchLoadTemplate,
  applyObjectReplace,
}) {
  const [hoverData, setHoverData] = useState("");
  const [modal, setModal] = useState({
    isOpen: false,
  });
  const [deleteData, setDeleteData] = useState({});

  // Close delete modal
  const close = () => {
    setModal({ isOpen: false });
  };

  // delete project by modal
  const deleteProjectByModal = () => {
    deleteUploadedData(deleteData);
  };

  // Delete Upload File
  const deleteUploadedData = async (data) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(data);

    try {
      const res = await axios.post(
        `${baseURL}delete-user-uploaded-file`,
        body,
        config
      );

      if (res.data.status === true) {
        setAlert(
          data.type === "tts" ? res.data.message : res.data.data,
          "danger"
        );
        if (data.type === "image") {
          fetchLoadTemplate({ type: "image", user_id: user_id });
        }

        setDeleteData({});
      }
      close();
    } catch (err) {
      console.log(err.response);
    }
  };

  return (
    <>
      <div
        id="upload-clips"
        className="tab-pane fade show "
        role="tabpanel"
        aria-labelledby="upload-tab"
      >
        <div className="input-group mb-3">
          <button
            id="up-media-img"
            className="up-img-btn btn btn-3 mb-3 text-white d-block mx-auto"
          >
            <input
              type="file"
              className="file-type"
              accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
              onChange={(e) => {
                uploadImages(e);
              }}
            />
            <svg
              width="12px"
              height="12px"
              className="ico v-center"
              viewBox=" 0 0 12 12"
              style={{ color: "#d19df8" }}
            >
              <ImagePlusIcon />
            </svg>
            Upload Media
          </button>
        </div>
        <div className="results-wrapper d-flex flex-wrap">
          {uploadLoader ? (
            <div
              style={{
                width: "50%",
                height: "75px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="spinner-grow" style={{ color: "#1cb7f2" }}>
                {" "}
              </div>
            </div>
          ) : null}
          {uploadData.map((value, index) => {
            return (
              <div
                className="add-img m-1"
                key={value.id}
                style={{
                  width: "45%",
                  height: "75px",
                  position: "relative",
                }}
                onMouseOver={() => setHoverData(value.id)}
                onMouseOut={() => setHoverData("")}
                onClick={() => {
                  applyObjectReplace({
                    type: "image",
                    id: 0,
                    source: {
                      thumbnail:
                        value.thumbnail !== ""
                          ? value.thumbnail
                          : value.image_url,
                      url: value.image_url,
                    },
                  });
                }}
              >
                <img
                  src={
                    value.thumbnail.length > 0
                      ? value.thumbnail
                      : value.image_url
                  }
                  className="img-fluid"
                  alt=""
                  style={{ objectFit: "cover", width: "100%", height: "100%" }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    width: "22px",
                    height: "22px",
                    background: "white",
                    borderRadius: "50%",
                    display: `${hoverData === value.id ? "flex" : "none"}`,
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  className="delete-preview-editor"
                  onClick={() => {
                    removeAlert();
                    setDeleteData({
                      type: "image",
                      id: value.id,
                    });
                    setModal({ isOpen: true });
                  }}
                >
                  <i
                    class="fa fa-trash preview-delete-icon"
                    style={{ fontSize: "15px", color: "black" }}
                  ></i>
                </div>
              </div>
            );
          })}
        </div>
        {modal.isOpen ? (
          <ConfirmDelete close={close} confirm={deleteProjectByModal} />
        ) : null}
      </div>
    </>
  );
}

Upload.propTypes = {
  uploadData: PropTypes.array.isRequired,
  uploadImages: PropTypes.func.isRequired,
  uploadLoader: PropTypes.bool.isRequired,
};

export default connect(null, {
  setAlert,
  removeAlert,
  fetchLoadTemplate,
  applyObjectReplace,
})(Upload);
