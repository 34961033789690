import {
  NORMAL_TIMELINE,
  RECONFIGURE_TIMELINE,
  RESELECT_TIMELINE,
  REARRANGE_TIMELINE,
  DELETE_REARRANGE_TIMELINE,
  SET_TIMELINE_PLAYTIME,
  CLEAR_NORMAL_TEMPLATE,
  ADD_TIMELINE
} from "./Types";
import axios from "axios";
import { baseURL } from "../global/global";

// const JsonData = require("../global/source.json");
export const loadTemplate = (data) => async (dispatch, getState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body =
    data.name === "template"
      ? JSON.stringify({ template_id: data.id })
      : JSON.stringify({ project_id: data.id });

  try {
    const res =
      data.name === "template"
        ? await axios.post(`${baseURL}create-project`, body, config)
        : await axios.post(`${baseURL}edit-project`, body, config);
    if (res.data.status === true) {
      dispatch({
        type: NORMAL_TIMELINE,
        payload: res.data.data[0].data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const applyObjectSelect = (id) => (dispatch, getState) => {
  const oldState = getState().normalTemplates.present.code;
  let currentSeekTime = 0;
  const newState = oldState.map((tracks) => {
    return {
      ...tracks,
      objects: tracks.objects.map((object) => {
        const newObject = {
          ...object,
        };
        if (object.id === id) {
          newObject.isSelected = true;
          currentSeekTime = newObject.startTime;
        } else {
          newObject.isSelected = false;
        }
        return newObject;
      }),
    };
  });

  const stateWithSeek = {
    code: newState,
    currentSeek: currentSeekTime,
  };
  dispatch({ type: RESELECT_TIMELINE, payload: stateWithSeek });
};

export const applyObjectDelete = (delObject) => (dispatch, getState) => {
  const oldState = getState().normalTemplates.present.code;
  // Since each object is in their Respective Track, we can delete the tracks to make object delete
  const newState = oldState.filter((track) => track.id !== delObject.trackId);
  newState[0].objects[0].isSelected = true;
  dispatch({ type: DELETE_REARRANGE_TIMELINE, payload: newState });
};

export const applyObjectRearrange = (rearrange) => (dispatch, getState) => {
  const oldState = getState().normalTemplates.present.code;
  const newState = oldState.map((tracks) => {
    return {
      ...tracks,
    };
  });
  // Since each object is in their Respective Track, we can rearrange the tracks to make object move front and back
  const selectedItem = (track) => track.id === rearrange.trackId;
  const from = newState.findIndex(selectedItem);

  // If rearrange type is move back then to is always from-1 and if rearrange type move forward, then to is always from +1
  let to = 0;
  if (rearrange.type === "back") {
    to = from < newState.length ? from + 1 : from;
  } else {
    to = from > 0 ? from - 1 : 0;
  }

  // Pick the item from position
  const f = newState.splice(from, 1)[0];
  // insert at to position
  newState.splice(to, 0, f);

  dispatch({ type: REARRANGE_TIMELINE, payload: newState });
};

export const applyObjectConfig = (newConfig) => (dispatch, getState) => {
  const oldState = getState().normalTemplates.present.code;
  const newState = oldState.map((tracks) => {
    return {
      ...tracks,
      objects: tracks.objects.map((object) => {
        if (object.id === newConfig.id) {
          if (newConfig.type === "dimension") {
            const style = {
              ...object.styles,
              width: newConfig.width,
              height: newConfig.height,
            };
            return {
              ...object,
              styles: style,
            };
          } else if (newConfig.type === "coordinates") {
            const style = {
              ...object.styles,
              left: newConfig.left,
              top: newConfig.top,
              translateX: newConfig.left + "px",
              translateY: newConfig.top + "px",
              translateZ: "0px",
            };
            return {
              ...object,
              styles: style,
            };
          } else {
            return {
              ...object,
            };
          }
        } else {
          return {
            ...object,
          };
        }
      }),
    };
  });
  dispatch({ type: RECONFIGURE_TIMELINE, payload: newState });
};

export const applyObjectReplace = (newObject) => (dispatch, getState) => {
  const oldState = getState().normalTemplates.present.code;
  if (newObject.id === 0) {
    // set the id as the current selected object
    oldState.some((track) => {
      const selectedObject = track.objects.find(
        (object) => object.isSelected === true
      );
      if (typeof selectedObject === "object" && selectedObject !== null) {
        newObject.id = selectedObject.id;
      }
    });
    console.log(newObject, "aaaaaaaaaaaaaaaaa");
  }

  const newState = oldState.map((tracks) => {
    return {
      ...tracks,
      objects: tracks.objects.map((object) => {
        const newReturnObject = {
          ...object,
        };
        if (object.id === newObject.id) {
          if (
            object.objectType === newObject.type &&
            newObject.type === "text"
          ) {
            newReturnObject.value = newObject.value;
          }
          if (object.objectType === "video" || object.objectType === "image") {
            newReturnObject.source.url = newObject.source.url;
            newReturnObject.source.thumbnail = newObject.source.thumbnail;
            newReturnObject.objectType = newObject.type;
          }
          // if (
          //   object.objectType === newObject.type &&
          //   newObject.type === "image"
          // ) {
          //   console.log(newObject, "Reached Here");
          //   newReturnObject.source.url = newObject.source.url;
          //   newReturnObject.source.thumbnail = newObject.source.thumbnail;
          // }
        }
        return newReturnObject;
      }),
    };
  });
  dispatch({ type: RECONFIGURE_TIMELINE, payload: newState });
};

export const applyTextObjectProperties = (newObj) => (dispatch, getState) => {
  const oldState = getState().normalTemplates.present.code;
  const newState = oldState.map((tracks) => {
    return {
      ...tracks,
      objects: tracks.objects.map((object) => {
        const newReturnObject = {
          ...object,
        };
        if (object.id === newObj.id) {
          const style = {
            ...newReturnObject.styles,
          };
          switch (newObj.type) {
            case "volume":
              newReturnObject.volume = newObj.value
              break;
            case "fontSize":
              style.fontSize = parseInt(newObj.value);
              break;
            case "fontFamily":
              style.fontFamily = newObj.value;
              break;
            case "lineHeight":
              style.lineHeight = newObj.value;
              break;
            case "color":
              style.color = newObj.value;
              break;
            case "backgroundColor":
              style.backgroundColor = newObj.value;
              break;
            case "bold":
              style.fontWeight = style.fontWeight === 'bold' ? 'normal' : 'bold'
              break;
            case "italic":
              style.fontStyle = style.fontStyle === 'italic' ? 'normal' : 'italic'
              break;
            case "uppercase":
              style.textTransform = newObj.value;
              break;
            case "lowercase":
              style.textTransform = newObj.value;
              break;
            case "underline":
              style.textDecoration = style.textDecoration === 'underline' ? 'none' : 'underline'
              break;
            case "strikethrough":
              style.textDecoration = style.textDecoration === 'line-through' ? 'none' : 'line-through'
              break;
            case "left":
              style.textAlign = newObj.value;
              break;
            case "center":
              style.textAlign = newObj.value;
              break;
            case "right":
              style.textAlign = newObj.value;
              break;
            case "justify":
              style.textAlign = newObj.value;
              break;
          }
          return {
            ...newReturnObject,
            styles: style,
          };
        }
        return newReturnObject;
      }),
    };
  });
  dispatch({ type: RECONFIGURE_TIMELINE, payload: newState });
};
export const applyObjectAdd = (objectMeta) => (dispatch, getState) => {
  const oldState = getState().normalTemplates.present.code;
  const newState = oldState.map((tracks) => {
    return {
      ...tracks,
      objects: tracks.objects.map((object) => object)
    };
  });

  const newObject = require("../global/object.json");
  newObject.id = 'track-' + Math.random().toString(36).substr(2, 9);
  newObject.objects[0].id = 'object-' + Math.random().toString(36).substr(2, 9);
  newObject.objects[0].trackId = newObject.id;

  if (objectMeta.type === 'audio') {
    newObject.objects[0].objectType = 'audio';
    newObject.objects[0].source.url = objectMeta.value;

    newObject.objects[0].muted = false;
    newObject.objects[0].volume = 1;
  }

  newState.unshift(newObject);
  dispatch({ type: ADD_TIMELINE, payload: newState });
};
export const setTimeLinePlayTime = (newTime) => (dispatch, getState) => {
  dispatch({ type: SET_TIMELINE_PLAYTIME, payload: newTime });
};

export const get_into_initialState = () => (dispatch) => {
  dispatch({ type: CLEAR_NORMAL_TEMPLATE });
};
