import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { appName } from "../../global/global.js";

const TitleBar = (props) => {
    const rebrand = useSelector(state => state.brandingdata.brandingdata)
    return (
        <>
            <Helmet>
                <title>{rebrand?.reseller?.app_name ? rebrand?.reseller?.app_name : appName} | {props.title}</title>
            </Helmet>
        </>
    )
}

export default TitleBar;