import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import SideNav from "../CreateVideos/SideNav";
import PropTypes from "prop-types";
import { fetchAllMergedVideos } from "../../actions/video";
import { Link, useHistory } from "react-router-dom";
import { baseURL } from "../../global/global";
import Alert from "../alert/Alert";
import axios from "axios";
import { removeAlert, setAlert } from "../../actions/alert";
import ConfirmDelete from "../confirmdelete/ConfirmDelete";
import Loader from "../loader/Loader";
import { Redirect } from "react-router-dom";
import TitleBar from "../Common/TitleBar";

const MyMergeVideo = ({
  auth: { user, loading },
  project,
  setAlert,
  fetchAllMergedVideos,
  removeAlert,
}) => {
  const [user_id, setUserId] = useState("");
  const [projects, setProjects] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [modal, setModal] = useState({
    isOpen: false,
  });

  const [deleteData, setDeleteData] = useState({});
  const [is_client, setIsClient] = useState(false);
  const [afterSearching, setAfterSearching] = useState(false);
  const [accountType, setAccountType] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  useEffect(() => {
    if (user) {
      setIsClient(loading || !user.is_client ? false : user.is_client);
    }
  }, [user, loading]);

  useEffect(() => {
    setUserId(loading || !user.user_id ? "" : user.user_id);
  }, [user, loading]);

  useEffect(() => {
    if (user_id) {
      fetchAllMergedVideos({ user_id: user_id });
    }
  }, [user_id, fetchAllMergedVideos]);

  useEffect(() => {
    setProjects(project.allMergedVideo);
  }, [project.allMergedVideo, project.loading]);

  useEffect(() => {
    if (afterSearching === false) {
      setProjects(project.allMergedVideo);
    }
  }, [project, afterSearching]);

  // Fetching All Projects again and again
  useEffect(() => {
    let local = null;
    if (projects) {
      const check = projects.find(
        (item) => item.status !== "Finished" && item.status !== "Failed"
      );

      if (check) {
        local = setInterval(
          () => fetchAllMergedVideos({ user_id: user_id }),
          25000
        );
      } else {
        clearInterval(local);
      }
    }

    return () => {
      if (local) {
        clearInterval(local);
      }
    };
  }, [projects, user_id, fetchAllMergedVideos]);

  // Set true for hit rendering status
  // useEffect(() => {
  //   if (projects) {
  //     const check = projects.find(
  //       (item) =>
  //         item.render_status === "Rendering" ||
  //         item.render_status === "Awaiting Rendering"
  //     );

  //     if (check) {
  //       setRenderId(check.project_id);
  //       setRenderStatus(true);
  //     }
  //   }
  // }, [projects]);

  // Close delete modal
  const close = () => {
    setModal({ isOpen: false });
  };

  // delete project by modal

  const deleteProjectByModal = () => {
    deleteProject(deleteData);
  };

  const deleteProject = async (data) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(data);

    try {
      const res = await axios.post(`${baseURL}delete-merge`, body, config);

      if (res.data.status === true) {
        fetchAllMergedVideos({ user_id });
        setAlert(res.data.message, "success");
      } else {
        setAlert(res.data.message, "danger");
      }
      close();
    } catch (err) {
      console.log(err.response);
    }
  };

  // Searching
  useEffect(() => {
    if (searchKeyword === "") {
      setProjects(project.allMergedVideo);
      setAfterSearching(false);
    } else {
      setAfterSearching(true);
      setProjects(
        project.allMergedVideo.filter((value) => {
          if (value.name.toLowerCase().includes(searchKeyword.toLowerCase())) {
            return value;
          }
          return null;
        })
      );
    }
  }, [searchKeyword, project.allMergedVideo, project.loading]);

  const searchingValue = (e) => {
    setSearchKeyword(e.target.value);
  };

  const dateToYMD = (date) => {
    console.log(date, "date")
    var strArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var d = date.getDate();
    var m = strArray[date.getMonth()];
    var y = date.getFullYear();
    console.log(d, "date")
    console.log(m, "month")
    return "" + (d <= 9 ? "0" + d : d) + " " + m + ", " + y;
  };
  if (accountType.length > 0) {
    if (
      !(
        accountType.includes("unlimited") ||
        accountType.includes("lite") ||
        accountType.includes("bundle") || accountType.includes("com")
      )
    ) {
      return <Redirect to="/dashboard" />;
    }
  }

  return (
    <div>
      <TitleBar title="MyVideo" />

      <section id="editor-section" className="bg-dark section-topmargin">
        <Alert />
        <div className="container-fluid px-0" style={{ minHeight: "90vh" }}>
          <div className="row mx-auto" style={{ minHeight: "90vh" }}>
            {/* <div className="col colmn-1 col-1 px-0 bg-dark">
              <SideNav activeTab={"myvideo"} />
            </div> */}
            <div
              className="col colmn-2 col-11 px-0 flex-grow-1"
              style={{ maxWidth: "100%" }}
            >
              <div id="editor-tab-wrap" className="tab-content">
                <div
                  id="videos-tab-cont"
                  className="tab-pane fade show active"
                  role="tabpanel"
                  aria-labelledby="script-tab"
                >
                  <div id="proj-title-bar" className="title-header-bar">
                    <div className="container-fluid px-4 create-title">
                      <div className="row align-items-center justify-content-space-between">
                        <div className="col-4 p-0">
                          <div className=" text-left d-flex align-items-center txlCent">
                            <h1
                              className="md-txt text-white"
                              style={{ textTransform: "capitalize" }}
                            >
                              my merge videos &nbsp;
                            </h1>
                            <span> | &nbsp; </span>
                            <span
                              style={{
                                fontFamily: "SourceSansPro",
                                fontSize: "14px",
                                fontWeight: 600,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                color: "#ffffff",
                              }}
                            >
                              {projects.length} Videos
                            </span>
                          </div>
                        </div>

                        <div
                          className="col-8"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <form
                            action="#"
                            method="post"
                            className="d-block w-100 mr-2"
                            style={{ maxWidth: "336px" }}
                            onSubmit={(e) => e.preventDefault()}
                          >
                            <div
                              className="input-group mb-3 mb-md-0"
                              style={{ width: "90%", position: "relative" }}
                            >
                              <input
                                id="search"
                                type="search"
                                name="search"
                                className="form-control"
                                placeholder="Search"
                                value={searchKeyword}
                                onChange={(e) => searchingValue(e)}
                              />
                              <div className="input-group-prepend"
                                style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}
                              >
                                <svg
                                  width="18px"
                                  height="18px"
                                  viewBox=" 0 0 18 18"
                                // className="proj-bar-search-icon"
                                >
                                  <use xlinkHref="./assets/images/search.svg#search-ico" />
                                </svg>
                              </div>
                            </div>
                          </form>
                          {is_client === true ? null : (
                            <>
                              <Link
                                id="add-proj"
                                className="btn btn-3 sm-txt ml-1"
                                type="button"
                                to="/reel-merge"
                              >
                                {/* <img className="img-fluid" src="/static/media/plus.5a7c7ac1.svg" alt="Plus" /> */}
                                + Create Merge Videos
                              </Link>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <section id="project-section" className="bg-dark p-0">
                    <div className="container-fluid">
                      <div className="row row-2">
                        <Alert />
                        {project.loading ? (
                          <>
                            <div
                              className="d-flex justify-content-center align-items-center w-100"
                              style={{ height: "80vh" }}
                            >
                              <Loader />
                            </div>
                          </>
                        ) : projects.length <= 0 ? (
                          <div
                            className="d-flex justify-content-center align-items-center w-100"
                            style={{ height: "80vh" }}
                          >
                            <h4>
                              {searchKeyword !== ""
                                ? "Sorry, you have not created any video with that name"
                                : 'You have not created any video yet. Click "Create Merge Videos" to start creating stunning videos'}
                            </h4>
                          </div>
                        ) : (
                          projects.map((project) =>
                            project.status === "Failed" ? (
                              <React.Fragment key={project.id}>
                                <div
                                  key={project.id}
                                  className="col colmn-3 col-xl-3 col-lg-4 col-sm-6 col-12 px-2 mb-lg-0 mb-2 mt-3"
                                >
                                  <div className="card-wrapper">
                                    <div
                                      className="status transcribing w-100 d-flex align-items-center justify-content-center"
                                      style={{
                                        backgroundImage:
                                          "linear-gradient(to bottom, #ff7e73 -11%, #c90618 39% , #a2000f 59%,#a2000f 78%)",
                                      }}
                                    >
                                      <img
                                        className="img-fluid status-img"
                                        alt=""
                                        src="./assets/images/vr-mark-grey.svg"
                                      />
                                      <p
                                        className="md-txt font-italic mb-0 text-light"
                                        style={{ fontWeight: 500 }}
                                      >
                                        Merging Failed
                                      </p>
                                      <div className="actions-icon row align-items-center mx-0">
                                        {is_client === true ? null : (
                                          <>
                                            <div className="icon-wrapper">
                                              <a
                                                href="!#"
                                                className="action-icon edit-icon mb-1"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  return history.push(
                                                    `/reel-merge?project_id=${project.id}`
                                                  );
                                                }}
                                              >
                                                <svg
                                                  width="20px"
                                                  height="20px"
                                                  viewBox=" 0 0 20 20"
                                                >
                                                  <use xlinkHref="./assets/images/edit.svg#edit-ico" />
                                                </svg>
                                              </a>
                                              <p className="sm-txt text-white">
                                                Edit
                                              </p>
                                            </div>

                                            <div className="icon-wrapper">
                                              <a
                                                href="!#"
                                                className="action-icon delete-icon remove mb-1"
                                                data-toggle="modal"
                                                data-target="#confirm-del"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  removeAlert();
                                                  setDeleteData({
                                                    id: project.id,
                                                    user_id: user_id,
                                                  });
                                                  setModal({ isOpen: true });
                                                }}
                                              >
                                                <svg
                                                  width="20px"
                                                  height="20px"
                                                  viewBox=" 0 0 20 20"
                                                >
                                                  <use xlinkHref="./assets/images/delete.svg#delete-ico" />
                                                </svg>
                                              </a>
                                              <p className="sm-txt text-white">
                                                Delete
                                              </p>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    <div className="details-wrapper row mx-0">
                                      <div className="meta-wrapper flex-grow-1">
                                        <div className="date mb-1 text-left px-1">
                                          <span className="sm-txt text-white ">
                                            {project.name}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="time">
                                        <span className="sm-txt text-white">
                                          {dateToYMD(
                                            new Date(
                                              parseInt(
                                                project.created.split("-")[0]
                                              ),
                                              parseInt(
                                                project.created.split("-")[1]
                                              ),
                                              parseInt(
                                                project.created.split("-")[2]
                                              )
                                            )
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            ) : project.status === "Finished" ? (
                              <div
                                key={project.id}
                                className="col colmn-3 col-xl-3 col-lg-4 col-sm-6 col-12 px-2 mb-lg-0 mb-5 mt-3"
                              >
                                <div className="card-wrapper">
                                  <div className="status w-100 d-flex align-items-center justify-content-center">
                                    <img
                                      className="img-fluid proj_img poster"
                                      src={`${project.preview_image}`}
                                      alt=""
                                    />
                                    <div className="actions-icon row align-items-center mx-0">
                                      {is_client === true ? null : (
                                        <>
                                          <div className="icon-wrapper">
                                            <a
                                              href="!#"
                                              className="action-icon edit-icon mb-1"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                return history.push(
                                                  `/reel-merge?project_id=${project.id}`
                                                );
                                              }}
                                            >
                                              <svg
                                                width="20px"
                                                height="20px"
                                                viewBox=" 0 0 20 20"
                                              >
                                                <use xlinkHref="./assets/images/edit.svg#edit-ico" />
                                              </svg>
                                            </a>
                                            <p className="sm-txt text-white">
                                              Edit
                                            </p>
                                          </div>
                                        </>
                                      )}

                                      <div className="icon-wrapper ">
                                        <a
                                          href="!#"
                                          className="action-icon download-icon mb-1"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            window.location.href =
                                              project.output_video;
                                          }}
                                        >
                                          <svg
                                            width="20px"
                                            height="20px"
                                            viewBox=" 0 0 20 20"
                                          >
                                            <use xlinkHref="./assets/images/download.svg#download-ico" />
                                          </svg>
                                        </a>
                                        <p className="sm-txt text-white">
                                          Download
                                        </p>
                                      </div>

                                      {is_client === true ? null : (
                                        <>
                                          <div className="icon-wrapper">
                                            <a
                                              href="!#"
                                              className="action-icon delete-icon remove mb-1"
                                              data-toggle="modal"
                                              data-target="#confirm-del"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                removeAlert();
                                                setDeleteData({
                                                  id: project.id,
                                                  user_id: user_id,
                                                });
                                                setModal({ isOpen: true });
                                              }}
                                            >
                                              <svg
                                                width="20px"
                                                height="20px"
                                                viewBox=" 0 0 20 20"
                                              >
                                                <use xlinkHref="./assets/images/delete.svg#delete-ico" />
                                              </svg>
                                            </a>
                                            <p className="sm-txt text-white">
                                              Delete
                                            </p>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="details-wrapper row mx-0">
                                    <div className="meta-wrapper flex-grow-1">
                                      <div className="date mb-1 text-left px-1">
                                        <span className="sm-txt text-white ">
                                          {project.name}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="time">
                                      <span className="sm-txt text-white">
                                        {dateToYMD(
                                          new Date(
                                            parseInt(
                                              project.created.split("-")[0]
                                            ),
                                            parseInt(
                                              project.created.split("-")[1]
                                            ),
                                            parseInt(
                                              project.created.split("-")[2]
                                            )
                                          )
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <React.Fragment key={project.id}>
                                <div
                                  key={project.id}
                                  className="col colmn-3 col-xl-3 col-lg-4 col-sm-6 col-12 px-2 mb-lg-0 mb-5 mt-3"
                                >
                                  <div className="card-wrapper">
                                    <div
                                      className="status transcribing w-100 d-flex align-items-center justify-content-center"
                                      style={{
                                        backgroundImage:
                                          "linear-gradient( 150deg, #17d583 -11%, #00d2b3 39%, #5bc1de 59%, #1cb7f2 78% )",
                                      }}
                                    >
                                      <img
                                        className="img-fluid status-img"
                                        alt=""
                                        src="./assets/images/vr-mark.png"
                                      />
                                      <p
                                        className="md-txt text-white font-italic mb-0"
                                        style={{
                                          fontWeight: 500,
                                        }}
                                      >
                                        {project.status}
                                      </p>
                                      <div className="actions-icon row align-items-center mx-0">
                                        {is_client === true ? null : (
                                          <>
                                            <div className="icon-wrapper">
                                              <a
                                                href="!#"
                                                className="action-icon edit-icon mb-1"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  return history.push(
                                                    `/reel-merge?project_id=${project.id}`
                                                  );
                                                }}
                                              >
                                                <svg
                                                  width="20px"
                                                  height="20px"
                                                  viewBox=" 0 0 20 20"
                                                >
                                                  <use xlinkHref="./assets/images/edit.svg#edit-ico" />
                                                </svg>
                                              </a>
                                              <p className="sm-txt text-white">
                                                Edit
                                              </p>
                                            </div>
                                            <div className="icon-wrapper">
                                              <a
                                                href="!#"
                                                className="action-icon delete-icon remove mb-1"
                                                data-toggle="modal"
                                                data-target="#confirm-del"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  removeAlert();
                                                  setDeleteData({
                                                    id: project.id,
                                                    user_id: user_id,
                                                  });
                                                  setModal({ isOpen: true });
                                                }}
                                              >
                                                <svg
                                                  width="20px"
                                                  height="20px"
                                                  viewBox=" 0 0 20 20"
                                                >
                                                  <use xlinkHref="./assets/images/delete.svg#delete-ico" />
                                                </svg>
                                              </a>
                                              <p className="sm-txt text-white">
                                                Delete
                                              </p>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    <div className="details-wrapper row mx-0">
                                      <div className="meta-wrapper flex-grow-1">
                                        <div className="date mb-1 text-left px-1">
                                          <span className="sm-txt text-white ">
                                            {project.name}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="time">
                                        <span className="sm-txt text-white">
                                          {dateToYMD(
                                            new Date(
                                              parseInt(
                                                project.created.split("-")[0]
                                              ),
                                              parseInt(
                                                project.created.split("-")[1]
                                              ),
                                              parseInt(
                                                project.created.split("-")[2]
                                              )
                                            )
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            )
                          )
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {modal.isOpen ? (
        <ConfirmDelete close={close} confirm={deleteProjectByModal} />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  project: state.video,
});

MyMergeVideo.propTypes = {
  auth: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  removeAlert: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  setAlert,
  removeAlert,
  fetchAllMergedVideos,
})(MyMergeVideo);
