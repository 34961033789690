import React, {useEffect, useRef} from "react";
import "./style.css";
import { gsap, Draggable, TweenLite, CSSPlugin} from "gsap/all"
import {applyObjectConfig} from "../../../../actions/normalTemplateActions";
import {connect} from "react-redux";
gsap.registerPlugin(Draggable, CSSPlugin);
function GsDrag(props) {
    const draggable = useRef(null);
    const dragHandle = useRef(null);

    const clamp = (value, min, max) => {
        return value < min ? min : (value > max ? max : value);
    }

    useEffect(() => {
        const rect1 = draggable.current.getBoundingClientRect()
        TweenLite.set(dragHandle.current, { x: rect1.width, y: rect1.height });

        const rect2 = dragHandle.current.getBoundingClientRect();
        const offset = {
            x1: rect2.left - rect1.right,
            y1: rect2.top - rect1.bottom,
            x2: rect2.right - rect1.right,
            y2: rect2.bottom - rect1.bottom
        };
        // Draggable.create([draggable.current], {
        //     bounds:'.canvas',
        //     autoScroll: 0,
        //     onDragEnd: function() {
        //         props.applyReConfig({
        //             left:this.x,
        //             top:this.y,
        //             id:props.object.id,
        //             width:props.object.styles.width,
        //             height:props.object.styles.height,
        //             type:'coordinates'
        //         });
        //     }
        // });

        Draggable.create([dragHandle.current], {
            bounds: '.canvas',
            autoScroll: 0,
            onPress: (e) => {
                e.stopPropagation();
            },
            onDrag: function() {
                TweenLite.set(draggable.current, { width: this.x + 0, height: this.y + 0 });
            },
            onDragEnd: function(){
                props.applyReConfig({
                    left:props.object.styles.left,
                    top:props.object.styles.top,
                    id:props.object.id,
                    width:this.x,
                    height:this.y,
                    type:'dimension'
                });
            },
            liveSnap: {
                x: function(x) {
                    return clamp(x, -offset.x1, x + offset.x2);
                },
                y: function (y){
                    return clamp(y, -offset.y1, y + offset.y2);
                }
            }
        });

    },[])
        const style = {
            width:props.object.styles.width,
            height:props.object.styles.height,
            top:props.object.styles.top,
            left:props.object.styles.left
        }


    return (
        <div className="trig draggable" ref={draggable} style={style}>
            {props.children}
            <div className="resize-handle" ref={dragHandle}> </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        applyReConfig: (newConfig) => dispatch(applyObjectConfig(newConfig))
    }
}
export default connect(null,mapDispatchToProps)(GsDrag);
