import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { deleteStripeAccounts } from '../../../actions/WhiteLabelActions'
import CommonAlert from '../../Common/CommonAlert'

const InteRow = ({ curElem, fetchSocial }) => {
    const dispatch = useDispatch()
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Disconnect",
        loader: false
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Disconnect",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: curElem.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Disconnecting",
            loader: true
        })
        dispatch(deleteStripeAccounts(data, fetchSocial, setSweet))
    }

    return (
        <>
            <hr style={{ opacity: "0.1" }} />
            <div className="white_label_integration px-5">
                <div className="white_label_integration_text">
                    <h6>{curElem.username}</h6>
                    <p>Added on - {curElem.created}</p>
                </div>
                <div className="white_label_right_integration">
                    <button className='white-theme-btn' onClick={onDelete}>Disconnect</button>
                </div>
            </div>

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to disconnect this connection?"}
                confirmButtonColor={""}
                icon={"delete"}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </>
    )
}

export default InteRow