import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import Navigation from "./Navigation/Navigation";
import Controls from "./Controls/Controls";
import PreviewControls from "./PreviewControls/PreviewControls";
import Text from "./Text/Text";
import Media from "./Media/Media";
import AudioComp from "./Audio/Audio";
import Transition from "./Transition/Transition";
import VideoObject from "./VideoObject";
import TextObject from "./TextObject";
import useDynamicRefs from "use-dynamic-refs";
import { gsap, TimelineMax, TweenLite, CSSPlugin } from "gsap";
import ImageObject from "./ImageObject";
import Timeline from "./Timeline/Timeline";
gsap.registerPlugin(CSSPlugin);
let globalTimeline = false;
let timeInterval = false;
let globalAudio = [];
function Right(props) {
  // Template Code
  const code = props.templateCode;
  let objectArray = [];
  if (code.length > 0) {
    code.map((item, index) => {
      item.objects.map((objectItem) => {
        objectArray.push(objectItem);
      });
    });
  }
  const [template, setTemplate] = useState({
    objects: objectArray,
    selectedObject: objectArray.find((object) => object.isSelected === true),
    previewPlay: false,
  });
  const [activeMenu, setActiveMenu] = useState("text");
  //const [previewPlay, setPreviewPlay] = useState(false);
  const [canvasPlayTime, setCanvasPlayTime] = useState({
    totalTime: 0,
    currentTime: props.currentSeek,
  });

  // Dynamic Refs
  const [getRef, setRef] = useDynamicRefs();
  const onMenuClick = (type) => {
    setActiveMenu(type);
  };

  useEffect(() => {
    setTemplate({
      ...template,
      objects: objectArray,
      selectedObject: objectArray.find((object) => object.isSelected === true),
    });
  }, [JSON.stringify(objectArray)]);

  // set Menu Active based on the layer Selected
  useEffect(() => {
    if (template.selectedObject.objectType === "text") {
      setActiveMenu("text");
    }
    if (
      template.selectedObject.objectType === "image" ||
      template.selectedObject.objectType === "video"
    ) {
      setActiveMenu("media");
    }
    if (template.selectedObject.objectType === "audio") {
      setActiveMenu("audio");
    }
  }, [template.selectedObject]);

  // Timeline On Complete Event
  const timelineOnComplete = () => {
    handleClickPlay("pause");
  };

  // create Set Interval to get the current timeLine and call it on PLay
  const getCurrentTimeLineTime = () => {
    timeInterval = setInterval(function () {
      // console.log(globalTimeline.time(),'GSAP Timeline',globalTimeline)
      setCanvasPlayTime({
        ...canvasPlayTime,
        currentTime: globalTimeline.time().toFixed(2),
      });
    }, 10);
  };
  // create Clear Interval to stop the current timeLine and call it on Pause
  const stopCurrentTimeLineTime = () => {
    //console.log('Called Stop Interval',timeInterval)
    clearInterval(timeInterval);
  };

  const playAudio = (audioObject) => {
    if (audioObject.source.playing === true) {
      const fetchAudio = globalAudio.find((item) => item.id === audioObject.id);
      if (typeof fetchAudio === "undefined") {
        console.log(globalAudio, "Creating New Object");
        // creat a new audio element
        const audioElement = {
          id: audioObject.id,
          audio: new Audio(audioObject.source.url),
        };
        audioElement.audio.currentTime = canvasPlayTime.currentTime;
        globalAudio.push(audioElement);
        audioElement.audio
          .play()
          .then((res) => {
            console.log("suc", res);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        console.log(globalAudio, "Found the Object");
        fetchAudio.audio.currentTime = canvasPlayTime.currentTime;
        fetchAudio.audio
          .play()
          .then((res) => {
            console.log("suc", res);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (audioObject.source.playing === false) {
      console.log(globalAudio, "Pause the audio");
      const fetchAudio = globalAudio.find((item) => item.id === audioObject.id);
      fetchAudio.audio.pause();
    }
  };

  // On Play On Pause Click Event
  const handleClickPlay = (type) => {
    if (type === "play") {
      const updatedCode = template.objects.map((item) => {
        if (
          item.objectType === "video" ||
          item.objectType === "audio" ||
          item.objectType === "image"
        ) {
          const object = { ...item };
          object.source.playing = true;
          if (object.objectType === "audio") playAudio(object);
          return object;
        } else return item;
      });
      setTemplate({
        ...template,
        objects: updatedCode,
        previewPlay: true,
      });
      //setPreviewPlay(true);
    } else {
      const updatedCode = template.objects.map((item) => {
        if (
          item.objectType === "video" ||
          item.objectType === "audio" ||
          item.objectType === "image"
        ) {
          const object = { ...item };
          object.source.playing = false;
          if (object.objectType === "audio") playAudio(object);
          return object;
        } else return item;
      });
      stopCurrentTimeLineTime();
      globalTimeline.pause();
      setTemplate({
        ...template,
        objects: updatedCode,
        previewPlay: false,
      });
      //setPreviewPlay(false);
    }
  };

  useEffect(() => {
    globalTimeline = new TimelineMax({
      paused: true,
      onComplete: timelineOnComplete,
    });

    template.objects.forEach((item, index) => {
      // if(item.objectType === 'audio')
      // {
      //   // ignore the Audio object from Animation
      //   console.log('Ignore Audio');
      // }
      // else
      // {
      const subTimeLine = new TimelineMax();
      item.transition.forEach((trans, transIndex) => {
        subTimeLine.fromTo(
          getRef(item.id).current,
          { ...trans.from },
          { ...trans.to }
        );
      });
      globalTimeline.add(subTimeLine, item.startTime);
      // }
    });
    globalTimeline.seek(canvasPlayTime.currentTime);
    setCanvasPlayTime({
      ...canvasPlayTime,
      totalTime: globalTimeline.totalDuration(),
    });
    if (template.previewPlay === true) {
      const animationTimeRemaining =
        canvasPlayTime.totalTime - Math.round(canvasPlayTime.currentTime);
      if (animationTimeRemaining >= 1) {
        globalTimeline.play(canvasPlayTime.currentTime);
      } else {
        globalTimeline.restart();
      }
      getCurrentTimeLineTime();
    }
  }, [JSON.stringify(template.objects)]);

  useEffect(() => {
    setCanvasPlayTime({
      ...canvasPlayTime,
      currentTime: props.currentSeek,
      totalTime: globalTimeline.totalDuration(),
    });
    globalTimeline.seek(props.currentSeek);
  }, [props.currentSeek, template.objects.length]);

  return (
    <>
      <div
        id="editor-tab-cont"
        className="fade show active my-tab-pane flex-row"
        role="tabpanel"
        aria-labelledby="editor-tab"
        style={{marginBottom:"70px !important"}}
      >
        <div className="nav-wrapper w-100" style={{maxWidth:"unset"}}>
          <Navigation
            activeMenu={activeMenu}
            handleClick={onMenuClick}
            template_id={props.template_id}
            project_id={props.project_id}
          />
          <div className="preview-wrapper">
            <div className="controls-wrapper">
              <Controls object={template.selectedObject} />
              <div className="object-prev">
                <div className="canvas">
                  {template.objects
                    .slice(0)
                    .reverse()
                    .map((objectItem, objectIndex) => {
                      if (objectItem.objectType === "video") {

                        return (
                          <VideoObject
                            key={"obj_" + objectIndex}
                            objectItem={objectItem}
                            playStatus={template.previewPlay}
                            canvasPlayTime={canvasPlayTime}
                            ref={setRef(objectItem.id)}
                          />
                        );
                      } else if (objectItem.objectType === "text") {
                        return (
                          <TextObject
                            key={"obj_" + objectIndex}
                            objectItem={objectItem}
                            playStatus={template.previewPlay}
                            ref={setRef(objectItem.id)}
                          />
                        );
                      } else if (objectItem.objectType === "image") {
                        return (
                          <ImageObject
                            key={"obj_" + objectIndex}
                            objectItem={objectItem}
                            playStatus={template.previewPlay}
                            ref={setRef(objectItem.id)}
                          />
                        );
                      } else {
                        return false;
                      }
                    })}
                </div>
              </div>
              <PreviewControls
                previewPlay={template.previewPlay}
                handleClickPlay={handleClickPlay}
                canvasPlayTime={canvasPlayTime}
              />
            </div>
          </div>
        </div>

        <div id="inner-tab-cont" className="tab-content my-inner-tab-cont">
          <div
            id="text"
            className={
              "tab-pane fade " + (activeMenu === "text" ? "show active" : "")
            }
          >
            <Text object={template.selectedObject} />
          </div>
          <div
            id="media"
            className={
              "tab-pane fade " + (activeMenu === "media" ? "show active" : "")
            }
          >
            <Media object={template.selectedObject} />
          </div>
          <div
            id="audio"
            className={
              "tab-pane fade " + (activeMenu === "audio" ? "show active" : "")
            }
          >
            <AudioComp />
          </div>
          <div
            id="transition"
            className={
              "tab-pane fade " +
              (activeMenu === "transition" ? "show active" : "")
            }
          >
            <Transition />
          </div>
        </div>
        <Timeline timeLine={template} canvasPlayTime={canvasPlayTime} />
      </div>
    </>
  );
}
export default Right;
