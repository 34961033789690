import React, { useState, useCallback, useEffect } from "react";
import BackgroundMusic from "./BackgroundMusic";
import TextToSpeech from "./TextToSpeech";
import { baseURL } from "../../../../../../global/global";
import axios from "axios";
import PropTypes from "prop-types";
import UploadAudio from "./UploadAudio";

// Redux
import { connect } from "react-redux";
import { removeAlert, setAlert } from "../../../../../../actions/alert";
import { fetchUploadedAudio } from "../../../../../../actions/video";
import UploadModal from "../../../../../uploadModal/UploadModal";

import { getTtsData, generateTts } from "../../../../../../actions/tts";

function Audio({
  setAlert,
  removeAlert,
  auth: { user, loading },
  loadingTemplate,
  fetchUploadedAudio,
  getTtsData,
  ttsValue,
  generateTts,
}) {
  const [user_id, setUserId] = useState("");
  const [audio, setAudio] = useState([]);
  const [audioPageNum, setAudioPageNum] = useState(1);
  const [loader, setLoader] = useState(false);
  const [isBottom, setIsBottom] = useState(false);
  const [uploadAudioData, setUploadAudioData] = useState([]);
  const [uploadStuff, setUploadStuff] = useState({
    uploadedPercentage: 0,
    show: false,
  });
  const [selectedFile, setSelectedFile] = useState({});
  const [uploadLoader, setUploadLoader] = useState(false);
  const [scrollNot, setScrollNot] = useState(false);
  const [sourceCancel, setSourceCancel] = useState({});

  const [tts, setTts] = useState([]);
  const [ttsLangVoice, setTtsLangVoice] = useState({
    language: "-Select-",
    voice: "-Select-",
    text: "",
  });
  const [ttsVoice, setTtsVoice] = useState([]);
  const [ttsAudioUrl, setTtsAudioUrl] = useState([]);
  const [ttsLoader, setTtsLoader] = useState(false);
  const [ttsName, setTtsName] = useState("");

  // get use id from redux
  useEffect(() => {
    if (user) {
      setUserId(loading || !user.user_id ? "" : user.user_id);
    }
  }, [user, loading]);

  // Fetching tts data
  useEffect(() => {
    setTtsAudioUrl(
      ttsValue.ttsAudio.length > 0
        ? ttsValue.ttsAudio.map((value) => ({
            audio_url: value.url,
            url: value.url,
            name: value.name,
          }))
        : []
    );
  }, [ttsValue.ttsAudio]);

  // call tts api for fetching data
  useEffect(() => {
    if (user_id !== "") {
      getTtsData(user_id);
    }
  }, [user_id, getTtsData]);

  // getting tts data from redux
  useEffect(() => {
    if (ttsValue.tts !== null) {
      setTts(ttsValue.tts);
    }
  }, [ttsValue]);

  // fetching uploaded audio
  useEffect(() => {
    if (user_id !== "") {
      fetchUploadedAudio({ type: "audio", user_id: user_id });
    }
  }, [fetchUploadedAudio, user_id]);

  useEffect(() => {
    setUploadAudioData(
      loadingTemplate.uploadAudio.map((value) => ({
        ...value,
        url: value.audio_url,
      }))
    );
  }, [loadingTemplate.loading, loadingTemplate.uploadAudio]);

  // Getting audio from library
  const getAudio = useCallback(
    () => async () => {
      if (audioPageNum === 1) {
        setAudio([]);
      }
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      setLoader(true);
      const body = JSON.stringify({ page_number: audioPageNum });

      try {
        const res = await axios.post(
          `${baseURL}load-library-audio`,
          body,
          config
        );

        if (res.data.status === true) {
          setLoader(true);
          setAudio((value) => [...value, ...res.data.data]);
          setIsBottom(false);
        }
      } catch (err) {
        setLoader(true);
        console.log(err.response);
      }
    },
    [audioPageNum]
  );

  useEffect(() => {
    getAudio()();
  }, [audioPageNum, getAudio]);

  // Upload Audio

  const uploadAudio = async (e) => {
    if (!e.target.files[0]) return;
    const fileSize = e.target.files[0].size / (1024 * 1024);
    const fileName = e.target.files[0].name;

    if (fileSize <= 5) {
      const fileExtensionArray = fileName.split(".");
      const fileExtension = fileExtensionArray[fileExtensionArray.length - 1];
      if (fileExtension === "mp3") {
        setUploadStuff((value) => ({
          ...value,
          show: true,
        }));
        setUploadLoader(true);
        setSelectedFile(e.target.files[0]);
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("upload_type", "audio");
        formData.append("user_id", user_id);

        let source = axios.CancelToken.source();
        setSourceCancel(source);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percentage = Math.floor((loaded * 100) / total);
            if (percentage < 99) {
              setUploadStuff((value) => ({
                ...value,
                uploadedPercentage: percentage,
              }));
            }
          },
          cancelToken: source.token,
        };

        try {
          const res = await axios.post(
            `${baseURL}project-file-upload`,
            formData,
            config
          );

          if (res.data.status === true) {
            fetchUploadedAudio({ type: "audio", user_id: user_id });
            setUploadLoader(false);
            setSelectedFile({});
            setUploadStuff((value) => ({
              ...value,
              show: false,
              uploadedPercentage: 0,
            }));
          }
        } catch (err) {
          console.log(err.response);
          setUploadLoader(false);
        }
      } else {
        setAlert(
          "The file extension you are trying to upload is not allowed, extension allowed only(mp3)",
          "danger"
        );
      }
    } else {
      setAlert("You cannot upload more then 5MB in size", "danger");
    }
  };

  const LoadMoreAudio = () => {
    setAudioPageNum((value) => ++value);
  };

  // Scroll part

  useEffect(() => {
    if (document.getElementById("audio_scroll")) {
      const getScrollId = document.getElementById("audio_scroll");

      if (getScrollId) {
        getScrollId.addEventListener("scroll", handleScroll);
      }
      return () => {
        if (getScrollId) {
          getScrollId.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, []);

  function handleScroll() {
    const getScrollId = document.getElementById("audio_scroll");
    // if (document.getElementById("library").classList.value.includes("active")) {
    if (getScrollId) {
      const scrollTop = getScrollId.scrollTop;
      const scrollHeight = getScrollId.scrollHeight;

      if (scrollTop + window.innerHeight + 50 >= scrollHeight) {
        setIsBottom(true);
      }
    } else {
      return;
    }
  }

  useEffect(() => {
    if (!scrollNot) {
      if (isBottom) {
        if (document.getElementById("audio_scroll")) {
          LoadMoreAudio();
        }
      }
    }
  }, [isBottom, scrollNot]);

  // Ending scroll part

  // Tts update data(all Tts Funciton) //
  const ttsUpdation = (e) => {
    let target = e.target;

    setTtsLangVoice((value) => ({
      ...value,
      [target.name]: target.value,
    }));
  };

  useEffect(() => {
    if (ttsLangVoice.language !== "-Select-") {
      let voices = tts.find(
        (value) => value.language_code === ttsLangVoice.language
      );
      setTtsVoice(() => {
        return voices.voices.length > 0 ? voices.voices : [];
      });
    }
  }, [ttsLangVoice.language, tts]);

  // Generate Tts
  const generate = async () => {
    setTtsLoader(true);
    const data = {
      user_id,
      language: ttsLangVoice.language,
      voice: ttsLangVoice.voice,
      text: ttsLangVoice.text,
      name: ttsName,
    };
    await generateTts(data, setTtsLoader);
  };

  // Tts function end

  return (
    <>
      <ul
        id="media-tabs"
        className="nav nav-tabs border-0 justify-content-center mb-4"
        role="tablist"
      >
        <li className="nav-item">
          <a
            id="audio-tab"
            className="nav-link label active"
            data-toggle="tab"
            href="#audioTab"
            role="tab"
            aria-controls="audioTab"
            aria-selected="true"
          >
            Audio
          </a>
        </li>
        <li className="nav-item">
          <a
            id="tts-tab"
            className="nav-link label"
            data-toggle="tab"
            href="#tts"
            role="tab"
            aria-controls="tts"
            aria-selected="false"
          >
            TTS
          </a>
        </li>
        <li className="nav-item">
          <a
            id="upload-tab"
            className="nav-link label"
            data-toggle="tab"
            href="#upload-audio"
            role="tab"
            aria-controls="upload-audio"
            aria-selected="false"
          >
            Upload
          </a>
        </li>
      </ul>
      <div id="media-tab-cont" className="tab-content">
        <BackgroundMusic
          audio={audio}
          uploadAudio={uploadAudioData}
          uploadAudioFunc={uploadAudio}
          loader={loader}
          uploadLoader={uploadLoader}
        />

        <TextToSpeech
          ttsLangVoice={ttsLangVoice}
          ttsUpdation={ttsUpdation}
          tts={tts}
          ttsVoice={ttsVoice}
          ttsAudioUrl={ttsAudioUrl}
          generate={generate}
          ttsLoader={ttsLoader}
          ttsName={ttsName}
          setTtsName={setTtsName}
          user_id={user_id}
        />
        <UploadAudio
          audio={audio}
          uploadAudio={uploadAudioData}
          uploadAudioFunc={uploadAudio}
          loader={loader}
          uploadLoader={uploadLoader}
          user_id={user_id}
        />
      </div>
      {uploadStuff.show ? (
        <UploadModal
          show={setUploadStuff}
          percentage={uploadStuff.uploadedPercentage}
          removeLoader={() => setUploadLoader(false)}
          sourceCancel={sourceCancel}
          close={() =>
            setUploadStuff((value) => ({
              ...value,
              show: false,
              uploadedPercentage: 0,
            }))
          }
          heading={"Uploading Audio..."}
          desc={"Your audio is uploading"}
        />
      ) : null}
    </>
  );
}

Audio.propTypes = {
  removeAlert: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  fetchUploadedAudio: PropTypes.func.isRequired,
  getTtsData: PropTypes.func.isRequired,
  generateTts: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loadingTemplate: state.loadingTemplate,
  ttsValue: state.ttsValue,
});

export default connect(mapStateToProps, {
  setAlert,
  removeAlert,
  fetchUploadedAudio,
  getTtsData,
  generateTts,
})(Audio);
