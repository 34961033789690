import { FETCH_CLIENT_TEAM_ACCOUNT,CLEAR_CLIENT_TEAM_ACCOUNT } from "../actions/Types";

const initialState = {
  loading:true,
  clients:[],
  team_members:[]
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CLIENT_TEAM_ACCOUNT:
      return { 
        ...state,
        clients:payload.clients,
        team_members:payload.team_members,
        loading:false
       };
    case CLEAR_CLIENT_TEAM_ACCOUNT:
      return { initialState };
    default:
      return state;
  }
}
