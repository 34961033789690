import React from "react";

const UploadModal = ({
  show,
  percentage,
  close,
  sourceCancel,
  removeLoader,
  heading,
  desc,
}) => {
  return (
    <div
      id="prog-modal"
      className={`modal fade show d-block`}
      tabIndex="9"
      role="dialog"
      data-backdrop="static"
      aria-labelledby="modal-title"
      aria-hidden="true"
      style={{ background: "rgba(0,0,0,0.9)" }}
    >
      <div
        className="modal-dialog modal-dialog-centered mx-auto"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body bg-white text-center">
            <div className="img-wrapper mb-1">
              <img src="./assets/images/upload.png" alt="" />
            </div>
            <h4 className="modal-title mb-2 upload_title" id="delete-warn">
              {heading}
            </h4>
            <p className="w-100 mx-auto mb-3 para1">
              <span className="d-block sp1">{desc}</span>
            </p>
            <div className="progress-label mb-3">
              {percentage !== undefined ? percentage + "%" : 0 + "%"}
            </div>
            <div className="progress mb-3">
              <div
                className="progress-bar"
                style={{ width: percentage + "%" }}
                role="progressbar"
                aria-valuenow={percentage}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <a
              href="!#"
              className="btn btn-2 mt-3"
              data-dismiss="modal"
              onClick={(e) => {
                e.preventDefault();
                sourceCancel.cancel();
                close();
                removeLoader();
                show((value) => ({ ...value, show: false }));
              }}
            >
              Cancel
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadModal;
