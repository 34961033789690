import React, { useState, useEffect } from "react";
import Profile from "./Profile";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

function NavSection({ auth: { user, loading } }) {
  const [active, setActive] = useState("");
  const [is_client, setIsClient] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (user) {
      setIsClient(loading || !user.is_client ? false : user.is_client);
    }
  }, [user, loading]);

  useEffect(() => {
    if (location) {
      if (location.pathname === "/integration") {
        setActive("integrations");
      } else if (
        location.pathname === "/create-videos" ||
        location.pathname === "/editor"
      ) {
        setActive("create_project");
      } else if (location.pathname === "/my-videos") {
        setActive("my_project");
      } else if (location.pathname === "/dashboard") {
        setActive("dashboard");
      } else {
        setActive("");
      }
    }
  }, [location]);

  return (
    <>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#app-nav"
        aria-controls="app-nav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div
        id="app-nav"
        className="collapse navbar-collapse justify-content-end"
      >
        <ul className="navbar-nav align-items-lg-center">
          <li className="nav-item nav-item-1">
            <Link
              className={`nav-link text-white ${
                active === "dashboard" ? "active" : ""
              }`}
              to="/dashboard"
            >
              Dashboard
            </Link>
          </li>
          {is_client === true ? null : (
            <li className="nav-item nav-item-2">
              <Link
                className={`nav-link text-white ${
                  active === "create_project" ? "active" : ""
                }`}
                to="/create-videos"
              >
                Create Videos
              </Link>
            </li>
          )}
          <li className="nav-item nav-item-2">
            <Link
              className={`nav-link text-white ${
                active === "my_project" ? "active" : ""
              }`}
              to="/my-videos"
            >
              My Videos
            </Link>
          </li>
          {is_client === true ? null : (
            <li className="nav-item nav-item-3">
              <Link
                className={`nav-link text-white ${
                  active === "integrations" ? "active" : ""
                }`}
                to="/integration"
              >
                Integrations
              </Link>
            </li>
          )}
          {/* <li className="nav-item nav-item-4">
                  <Link className="nav-link text-white" to="/tutorials">Tutorials</Link>
                </li>
                <li className="nav-item nav-item-5">
                  <Link className="nav-link text-white" to="/support"> Support</Link>
                </li> */}
          <Profile />
        </ul>
      </div>
    </>
  );
}

NavSection.propTypes = {
  activeTab: PropTypes.string,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(NavSection);
