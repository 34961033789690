import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

function WatchNow({ brandingData }) {
  const [brandingName, setBrandingName] = useState("");

  useEffect(() => {
    if (brandingData.brandingdata.reseller) {
      setBrandingName(
        !brandingData.loading || !brandingData.brandingdata.reseller
          ? brandingData.brandingdata.reseller.app_name
          : ""
      );
    }
  }, [brandingData]);
  return (
    <>
      <div className="col colmn-1 col-xl-6 col-lg-6 col-md-12 col-12 mb-lg-0 mb-5">
        <div className="title-area text-lg-left text-center mb-2">
          <h1 className="big-heading">WELCOME</h1>
          <h2 className="big-heading font-weight-bold text-white">
            TO {brandingName !== "" ? brandingName : "VIDEOREEL"}
          </h2>
        </div>
        <div
          className="desc text-lg-left text-center w-100 mx-lg-0 mx-auto mb-3 pb-3"
          style={{ maxWidth: "680px" }}
        >
          <p
            className="text-white font-weight-lighter"
            style={{ lineHeight: "1.75" }}
          >
            CREATE GORGEOUS SHORT VIDEO For Social Media, Ads and more using
            premium templates. Watch how easy it is for you to create an
            animated video using ready-made templates.
          </p>
        </div>
        <div className="intro-btn text-lg-left text-center">
          <Link
            to="#"
            className="btn btn-1 md-txt text-white d-flex justify-content-center align-items-center"
            data-toggle="modal"
            data-target="#watch-video"
          >
            <i className="fa fa-play pr-1" aria-hidden="true" />
            <span
              className="pl-1 text-white"
              style={{ fontWeight: 600, fontSize: "18px" }}
            >
              Watch Now
            </span>
          </Link>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  brandingData: state.brandingdata,
});

export default connect(mapStateToProps)(WatchNow);
