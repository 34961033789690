import React from "react";
import ReactPlayer from "react-player";
const VideoObject = (props, ref) => {
  const handleVideo = (e, id) => {
    //props.objectOnClick(id);
  };
  
  return (
    <div
      onClick={(e) => handleVideo(e, props.objectItem.id)}
      ref={ref}
      className="absolute video-object"
      style={props.objectItem.styles}
    >
      <ReactPlayer
        url={props.objectItem.source.url}
        playing={props.objectItem.source.playing}
        muted={true}
        width={480}
        height={270}
      />
    </div>
  );
};
export default React.forwardRef(VideoObject);
