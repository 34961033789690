import {
  FETCH_CLIENT_TEAM_ACCOUNT
} from './Types';
import { baseURL } from '../global/global';
import axios from 'axios';
import { setAlert } from './alert';
import { removeLoader } from './loader';

// Fetch Team and Client Account Detail
export const fetchClientTeamAccount = (user_id) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ user_id });


  try {
    const res = await axios.post(
      `${baseURL}fetch-client-team-account`,
      body,
      config
    );

    if (res.data.status === true) {
      dispatch({
        type: FETCH_CLIENT_TEAM_ACCOUNT,
        payload: {
          clients: res.data.data.clients,
          team_members: res.data.data.team_members
        }
      })
    } else {

    }
  } catch (err) {
    console.log(err.response);
  }
}


// Add Client Account

export const addClientAccount = (data) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(data);


  try {
    const res = await axios.post(
      `${baseURL}create-client-account`,
      body,
      config
    );

    if (res.data.status === true) {
      dispatch(setAlert(res.data.message, "success"));
      dispatch(fetchClientTeamAccount(data.user_id));
      dispatch(removeLoader());
    } else {
      dispatch(setAlert(res.data.message, "danger"));
      dispatch(removeLoader());
    }
  } catch (err) {
    console.log(err.response);
  }
}


// Add Team Account

export const addTeamAccount = (data) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(data);


  try {
    const res = await axios.post(
      `${baseURL}create-team-account`,
      body,
      config
    );

    if (res.data.status === true) {
      dispatch(setAlert(res.data.message, "success"));
      dispatch(fetchClientTeamAccount(data.user_id));
      dispatch(removeLoader());
    } else {
      dispatch(setAlert(res.data.message, "danger"));
      dispatch(removeLoader());
    }
  } catch (err) {
    console.log(err.response);
  }
}


// Delete Client or Team Account
export const deleteClientTeamAccount = (data) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(data);


  try {
    const res = await axios.post(
      `${baseURL}delete-client-team-account`,
      body,
      config
    );

    if (res.data.status === true) {
      dispatch(setAlert(res.data.message, "success"));
      dispatch(fetchClientTeamAccount(data.user_id));
    } else {
      dispatch(setAlert(res.data.message, "danger"));
    }
  } catch (err) {
    console.log(err.response);
  }
}


export const deleteWhitelabel = (data) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(data);

  try {
    const res = await axios.post(
      `${baseURL}delete-whitelabel`,
      body,
      config
    );

    if (res.data.status === true) {
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "danger"));
    }
  } catch (err) {
    console.log(err.response);
  }
}



// Edit Team Client Account

export const editClientTeamAccount = (data, id) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(data);


  try {
    const res = await axios.post(
      `${baseURL}edit-team-client-account`,
      body,
      config
    );

    if (res.data.status === true) {
      dispatch(setAlert(res.data.message, "success"));
      dispatch(fetchClientTeamAccount(id));
      dispatch(removeLoader());
    } else {
      dispatch(setAlert(res.data.message, "danger"));
      dispatch(removeLoader());
    }
  } catch (err) {
    console.log(err.response);
  }
}

// Reset Client Password

export const resetClientPassword = (data, user_id, close) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(data);


  try {
    const res = await axios.post(
      `${baseURL}reset-client-password`,
      body,
      config
    );

    if (res.data.status === true) {
      dispatch(setAlert(res.data.message, "success"));
      dispatch(fetchClientTeamAccount(user_id));
      dispatch(close());
    } else {
      dispatch(setAlert(res.data.message, "danger"));
    }
  } catch (err) {
    console.log(err.response);
  }
}



// Reset Team Password

export const resetTeamPassword = (data, user_id, close) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(data);


  try {
    const res = await axios.post(
      `${baseURL}reset-team-password`,
      body,
      config
    );

    if (res.data.status === true) {
      dispatch(setAlert(res.data.message, "success"));
      dispatch(fetchClientTeamAccount(user_id));
      dispatch(close());
    } else {
      dispatch(setAlert(res.data.message, "danger"));
    }
  } catch (err) {
    console.log(err.response);
  }
}



