import React from "react";

const TrimReel = ({ click, deleteAcc, accounts }) => {
  return (
    <div
      id="tr-account"
      className={`acc-details-wrapper row mx-auto p-4 mb-3 bg-white ${
        accounts.length > 0 ? "" : "not-connected"
      }`}
    >
      <div className="img-wrapper pr-2">
        {accounts.length > 0 ? (
          <img
            className="img-fluid pt-2"
            src="./assets/images/logo-trim.svg"
            alt="TrimReel Logo"
            style={{ display: "block" }}
          />
        ) : (
          <img
            className="img-fluid pt-2"
            src="./assets/images/logo-trim-grey.png"
            alt="TrimReel Logo"
          />
        )}
      </div>
      <div className="inner-row-wrapper flex-grow-1">
        {accounts.length <= 0 ? (
          <div className="inner-row d-flex flex-row align-items-center">
            <div className="acc-details flex-grow-1 px-2">
              <h6 className="mb-1">
                <span className="md-txt">TrimReel </span>
              </h6>
              <p className="status">Not Connected</p>
            </div>
            <div className="btns-wrapper d-flex pl-lg-3">
              <button
                onClick={() => click("trimreel")}
                className="btn btn-2 w-100 connect"
              >
                Connect
              </button>
            </div>
          </div>
        ) : (
          <>
            {accounts.map((account) => (
              <div
                key={account.id}
                className="inner-row d-flex flex-row align-items-center"
              >
                <div className="acc-details flex-grow-1 px-2">
                  <h6 className="mb-1 text-left">
                    <span className="md-txt text-left">
                      {account.app_name}{" "}
                    </span>
                    <span className="usr-email md-txt">{account.email}</span>
                  </h6>
                  <p className="status text-left">
                    Added on{" "}
                    <span className="date-added sm-txt">
                      {" "}
                      {account.created}
                    </span>
                  </p>
                </div>
                <div className="btns-wrapper d-flex pl-lg-3">
                  {/* <Link to="#" className="btn btn-2 reconnect w-100 mr-2">
              Reconnect
            </Link> */}
                  <button
                    className="btn btn-2 delt w-100 ml-xl-2 mb-3"
                    data-toggle="modal"
                    data-target="#confirm-del"
                    onClick={() => deleteAcc(account.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
            <div className="conn-btn-wrapper text-center">
              <button
                onClick={() => click("trimreel")}
                // data-toggle="modal"
                // data-target="#publish"
                className="btn btn-2 w-100"
              >
                Connect New Account
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TrimReel;
