import React, {useEffect, useRef} from "react";
import Zoom from "./Zoom/Zoom";
import Ruler from "./Ruler/Ruler";
import Tracks from "./Tracks/Tracks";
import './style.css';
import {gsap, Draggable, TweenLite} from "gsap/all";
import {setTimeLinePlayTime} from "../../../../../../actions/normalTemplateActions";
import {connect} from "react-redux";
gsap.registerPlugin(Draggable);

function Timeline(props) {
    const needle = useRef(null)
    const rulerLength = 60;
    const rulerArray = [];
    for (let i=0; i<= rulerLength;i++)
    {
        rulerArray.push(<Ruler canvasPlayTime={props.canvasPlayTime} left={i}/>)
    }
    const style = {
        left:props.canvasPlayTime.currentTime*60
    }

    const handleDrag = (e) => {
        //console.log()
        //props.setTimeLinePlayTime(e.offsetX)
    }

    useEffect(() => {
        Draggable.create([needle.current],{
            type:"x",
            autoScroll: 0,
            onPress: (e) => {
                e.stopPropagation();
            },
            onDragEnd:function () {
                if(this.x < props.canvasPlayTime.totalTime)
                    TweenLite.set(needle.current, { left: this.x });
            },
            cursor:"pointer",
            bounds:{top:0,left:0,width:props.canvasPlayTime.totalTime*60,height:0}
        });
    },[props.canvasPlayTime.totalTime])

    return (
        <div id="timeline-row" className="row mx-auto">
            <div className="timeline-wrapper w-100">
                <div className="timeline-body">
                    <div className="timeline-component-main">
                        <div className="timeline-component-track_wrapper">
                            <div className="timeline-header d-flex w-100 ">
                                <div className="timeline-component-zoom">
                                    {/*<Zoom  />*/}
                                </div>
                                <div className="timeline-component-ruler">
                                    {rulerArray}
                                    <div className="timeline-component-pin" style={style} ref={needle}> </div>
                                </div>
                            </div>
                            {props.timeLine.objects.map(object => <Tracks  object={object} /> )}
                            <div className="timeline-component-track">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setTimeLinePlayTime: (newTime) => dispatch(setTimeLinePlayTime(newTime))
    };
};
export default connect(null, mapDispatchToProps)(Timeline);