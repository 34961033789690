import React, { useEffect, useState } from "react";
import './Resizable.css';
import { ReactComponent as DeleteIcon } from "../../../../../../../assets/images/delete.svg";
import Track from "./Track";
import { ActionCreators as UndoActionCreators } from "redux-undo";
import {
	applyObjectDelete,
	applyObjectRearrange,
	applyTextObjectProperties
} from "../../../../../../../actions/normalTemplateActions";
import { connect } from "react-redux";
import ConfirmDelete from "../../../../../../confirmdelete/ConfirmDelete";

function Tracks(props) {
	const [object, setObject] = useState(props.object);
	const [modal, setModal] = useState({
		isOpen: false,
	});
	useEffect(() => {
		setObject(props.object);
	}, [props.object]);
	const close = () => {
		setModal({ isOpen: false });
	};
	const handleDelete = (e) => {
		e.preventDefault();
		setModal({ isOpen: true });
	};

	const confirmDelete = () => {
		// const confirmDelete = window.confirm("Are you sure you want to delete this track?");

		// if (confirmDelete) {
		const delObject = {
			id: object.id,
			trackId: object.trackId
		};
		props.deleteObject(delObject);
		setModal({ isOpen: false });
		// }
	}

	const handleChange = (e) => {
		const value = e.target.value;
		props.applyTextObjectProperties({
			id: object.id,
			type: 'volume',
			value: e.target.value
		});
	};

	return (
		<div className="timeline-component-track" id="track_id_">
			<div className="timeline-track">
				{object.objectType === 'audio' ? (
					<div>
						<span className="track-title">Delete</span>
						<div className="track-btns">

							<a href="/" className="ico icon-delete" onClick={handleDelete}>
								<svg width="12px" height="12px" viewBox="0 0 12 12">
									<DeleteIcon />
								</svg>
							</a>
						</div>

						<span className="track-title">Volume</span>
						<div className="volume-wrapper">
							<input className="volume form-control-range" type="range" name="volume" step="0.1" min="0"
								max="1" onChange={handleChange} value={object.volume} />
						</div>
					</div>
				) : (
					<div>
						<span className="track-title">Delete</span>
						<div className="track-btns">

							<a href="/" className="ico icon-delete" onClick={handleDelete}>
								<svg width="12px" height="12px" viewBox="0 0 12 12">
									<DeleteIcon />
								</svg>
							</a>
						</div>
					</div>
				)}
			</div>
			<div className="timeline-track-layer track-layer-1">
				<Track object={object} />
			</div>
			{modal.isOpen ? (
				<ConfirmDelete close={close} confirm={confirmDelete} />
			) : null}
		</div>
	);
}

const mapDispatchToProps = dispatch => {
	return {
		deleteObject: (newObject) => dispatch(applyObjectDelete(newObject)),
		applyTextObjectProperties: (newObj) => dispatch(applyTextObjectProperties(newObj))
	};
};

export default connect(null, mapDispatchToProps)(Tracks);
