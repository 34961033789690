import React from "react";
import AudioTrack from "./AudioTrack";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {removeAlert, setAlert} from "../../../../../../actions/alert";
import {fetchLoadTemplate} from "../../../../../../actions/video";

function BackgroundMusic({ audio, loader }) {
  return (
    <div
      id="audioTab"
      className="tab-pane fade show active"
      role="tabpanel"
      aria-labelledby="audio-tab"
    >
      <div className="card">
        <div className="card-body mt-3 p-0">
          <div
            id="audio_scroll"
            className="results-wrapper d-flex flex-wrap justify-content-center"
          >
            <AudioTrack audio={audio} />

            {loader ? (
              <div
                style={{ width: "100%", height: "80px" }}
                className="d-flex justify-content-center align-item-center"
              >
                <div className="spinner-grow" style={{ color: "#1cb7f2" }}>
                  {" "}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

BackgroundMusic.propTypes = {
  audio: PropTypes.array.isRequired,

  loader: PropTypes.bool.isRequired,
};

export default BackgroundMusic;
