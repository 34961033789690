import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { fetchingTemplate, fetchLanguageForTranslate } from "../../../actions/video";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";
import Loader from "../../loader/Loader";

const AllTemplate = ({
  template: { loading, allTemplate },
  fetchingTemplate,
  auth,
  loader,
}) => {
  const [templates, setTemplates] = useState([]);
  const [user_id, setUser_id] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [modal, setModal] = useState({
    isOpen: false,
  });
  const dispatch = useDispatch();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [templateDropdownType, setTemplateDropdownType] = useState("premium");
  // const templateType = ["premium", "intro", "outro", "legacy"];
  const templateType = ["normal", "premium", "intro", "outro", "legacy"];

  const history = useHistory();

  useEffect(() => {
    if (auth) {
      if (auth.user.account_type.length === 1 && auth.user.account_type[0] === "IO") {
        setTemplateDropdownType("intro")
      }
    }
  }, [])

  useEffect(() => {
    setUser_id(auth.loading || !auth.user.user_id ? "" : auth.user.user_id);
  }, [auth.user, auth.loading]);

  useEffect(() => {
    if (user_id !== "") {
      fetchingTemplate({
        user_id: user_id,
        type: templateDropdownType,
        max_count: 1,
      });
    }
  }, [user_id, fetchingTemplate, templateDropdownType]);

  useEffect(() => {
    setTemplates(allTemplate);
  }, [allTemplate, loading]);

  useEffect(() => {
    if (searchKeyword === "") {
      setTemplates(allTemplate);
    } else {
      setTemplates(
        allTemplate.filter((value) => {
          if (
            value.template_name
              .toLowerCase()
              .includes(searchKeyword.toLowerCase()) ||
            value.tags.toLowerCase().includes(searchKeyword.toLowerCase())
          ) {
            return value;
          }
          return null;
        })
      );
    }
  }, [searchKeyword, allTemplate]);

  const previewVideo = (url) => {
    setVideoUrl(url);
    setModal({ isOpen: true });
  };

  const close = () => {
    setModal({ isOpen: false });
  };

  const searchingValue = (e) => {
    setSearchKeyword(e.target.value);
  };

  if (auth.user !== null) {
    if (auth.user.account_type.length === 1 && auth.user.account_type[0] === "IO") {
      templateType.splice(0, 2)
      templateType.splice(2)
    }
  }


  useEffect(() => {
    dispatch(fetchLanguageForTranslate());
  }, [])



  // const searchTemplate = (e) => {
  //   e.preventDefault();
  //   setTemplates((temp) => {
  //     return temp.filter((value) => {
  //       if (value.template_name.includes(searchKeyword)) {
  //         console.log(value);
  //         return value;
  //       }
  //     });
  //   });
  // };

  return (
    <>
      <div
        id="template-tab-cont"
        className="tab-pane fade show active"
        role="tabpanel"
        aria-labelledby="projects-tab"
      >
        <div id="proj-title-bar" className="title-header-bar">
          <div className="container-fluid px-4 create-title">
            <div className="row align-items-center justify-content-space-between">
              <div className="col-6">
                <div className=" text-left d-flex align-items-center txlCent">
                  <h1
                    className="md-txt text-white text"
                    style={{ textTransform: "capitalize" }}
                  >
                    {" "}
                    {templateDropdownType} Templates&nbsp;
                  </h1>
                  <span> | &nbsp; </span>
                  <span
                    style={{
                      fontFamily: "SourceSansPro",
                      fontSize: "14px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      color: "#ffffff",
                    }}
                  >
                    {templates.length} Templates
                  </span>
                </div>
              </div>

              <div
                className="col-3"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <form
                  className="d-block w-100 mr-2"
                  style={{ maxWidth: "336px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="input-group">
                    <div className="form-group form-group-mb m-0 w-100">
                      <select
                        className="form-control w-100 pl-1"
                        id="search"
                        name="voice"
                        style={{
                          textTransform: "capitalize",
                          height: "36px",
                        }}
                        value={templateDropdownType}
                        onChange={(e) => {
                          setTemplateDropdownType(e.target.value);
                        }}
                      >
                        {templateType.map((value, index) => (
                          <option
                            value={value}
                            key={index}
                            style={{ textTransform: "capitalize" }}
                          >
                            {value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </form>
              </div>
              <div
                className="col-3"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <form
                  className="d-block w-100 mr-2"
                  style={{ maxWidth: "336px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="input-group">
                    <input
                      id="search"
                      type="search"
                      name="search"
                      className="form-control"
                      placeholder="Search"
                      value={searchKeyword}
                      onChange={(e) => searchingValue(e)}
                    />
                    <div className="input-group-prepend searching-temp">
                      <svg
                        width="18px"
                        height="18px"
                        viewBox=" 0 0 18 18"
                        className="proj-bar-search-icon"
                      >
                        <use xlinkHref="./assets/images/search.svg#search-ico" />
                      </svg>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <BottomButtonStyle>
          <div className="row mx-1">
            {loading || loader.loader ? (
              <>
                <div
                  className="d-flex justify-content-center align-items-center w-100"
                  style={{ height: "80vh" }}
                >
                  <Loader />
                </div>
              </>
            ) : templates.length <= 0 ? (
              <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "80vh" }}
              >
                <h4>
                  There is no template matching that keyword. Please try another
                </h4>
              </div>
            ) : (
              templates.map((template) => (
                <React.Fragment key={template.template_id}>
                  <div className="col colmn-3 col-xl-3 col-lg-4 col-sm-6 col-12 px-2 mb-lg-0 mt-3">
                    <div className="card-wrapper">
                      <div
                        className="status w-100 d-flex align-items-center mb-1 temp_video"
                        style={{ position: "relative" }}
                      >
                        <img
                          alt=""
                          className="proj_img poster"
                          src={template.prview_image_url}
                          onClick={() =>
                            previewVideo(template.preview_video_url)
                          }
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            height: "11rem",
                            objectFit: "cover",
                          }}
                        />
                        <span
                          style={{
                            position: "absolute",
                            top: "8px",
                            left: "8px",
                            backgroundColor: "rgba(255, 255, 255, 0.8)",
                            fontWeight: "bold",
                            color: "black",
                            width: "50px",
                            padding: "4px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "5px",
                            fontFamily: "SourceSansPro",
                          }}
                        >
                          {template.duration} s
                        </span>
                        <div id="overlay">
                          <div id="overlay-content">
                            <div className="details-wrapper row mx-0 justify-content-center">
                              <div className="icon-wrapper pr-3">
                                <button
                                  className="action-icon edit-icon mb-1 custom-ico-bg"
                                  title="Play"
                                  style={{ background: "none", border: "none" }}
                                >
                                  <img
                                    width="25"
                                    height="25"
                                    src="./assets/images/play-button.svg"
                                    alt="Icon"
                                    onClick={() =>
                                      previewVideo(template.preview_video_url)
                                    }
                                  />
                                </button>
                                <p className="sm-txt text-white">Preview</p>
                              </div>

                              <div className="icon-wrapper">
                                <button
                                  className="action-icon edit-icon mb-1 custom-ico-bg"
                                  style={{
                                    background: "none",
                                    border: "none",
                                    color: "#fff",
                                  }}
                                  onClick={() => {
                                    return history.push(
                                      `/${templateDropdownType === "normal" ||
                                        templateDropdownType === "legacy"
                                        ? "preview-editor"
                                        : "editor"
                                      }?template_id=${template.template_id}`
                                    );
                                  }}
                                >
                                  <svg
                                    width="25px"
                                    height="25px"
                                    viewBox=" 0 0 20 20"
                                  >
                                    <use xlinkHref="./assets/images/edit.svg#edit-ico" />
                                  </svg>
                                </button>
                                <p className="sm-txt text-white">Customize</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="details-wrapper row mx-0">
                        <div className="meta-wrapper flex-grow-1">
                          <div className="date mb-1 d-flex justify-content-center">
                            <span className="sm-txt text-white">
                              {template.template_name}

                              {/* <span className="pt-3 text-white">
                              #{template.template_name}Tags Ipsum
                            </span>
                          </span>
                          <span className="sm-txt text-white pt-2"> 90s </span> */}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))
            )}
          </div>
        </BottomButtonStyle>
      </div>

      {modal.isOpen ? (
        <div
          className="modal fade show d-block"
          style={{ background: "rgba(0,0,0,0.9)" }}
          tabIndex={-1}
          role="dialog"
          data-backdrop="static"
          aria-labelledby="delete-warn"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered mx-auto"
            role="document"
          >
            <div className="modal-content">
              <button
                type="button"
                className="close mb-3 text-right"
                data-dismiss="modal"
                aria-label="Close"
                style={{ opacity: "1", outline: "none" }}
                onClick={close}
              >
                <img
                  className="img-fluid"
                  src="./assets/images/close.svg"
                  alt="Close"
                />
              </button>
              <div className="modal-body bg-white text-center p-0">
                <div className="w-100 mx-auto text-center mr-auto">
                  <Plyr
                    autoPlay="true"
                    source={{
                      type: "video",
                      sources: [
                        {
                          src: videoUrl,
                          type: "video/mp4",
                          size: 720,
                        },
                      ],
                    }}
                  />
                  {/* <div className="embed-responsive embed-responsive-16by9 z-depth-1-half">
                    <iframe
                      className="embed-responsive-item"
                      src={videoUrl}
                      title={videoUrl}
                      allowFullScreen
                    ></iframe>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const BottomButtonStyle = styled.div`
  #overlay {
    height: 0%;
    width: 100%;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.7);
    overflow-y: hidden;
    transition: 0.5s;
  }

  #overlay-content {
    position: relative;
    top: 10%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .temp_video:hover #overlay {
    height: 100%;
    position: realtive;
  }
`;

const mapStateToProps = (state) => ({
  template: state.video,
  auth: state.auth,
  loader: state.loader,
});

AllTemplate.propTypes = {
  template: PropTypes.object.isRequired,
  fetchingTemplate: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { fetchingTemplate })(AllTemplate);
