import { SET_LOADER, REMOVE_LOADER, SET_VERIFY_LOADER } from "../actions/Types";

const initialState = {
  loader: false,
  verifyLoader: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOADER:
      return { ...state, loader: true };
    case SET_VERIFY_LOADER:
      return { ...state, verifyLoader: true };
    case REMOVE_LOADER:
      return { loader: false, verifyLoader: false };
    default:
      return state;
  }
}
