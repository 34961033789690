import { SketchPicker } from "react-color";
import React, { useState } from "react";
import hexToRgb from "../../../../../../global/hexToRgb";
import reactCSS from "reactcss";
import { applyTextObjectProperties } from "../../../../../../actions/normalTemplateActions";
import { connect } from "react-redux";
function FontBackgroundColor(props) {
  const [activeColor, setActiveColor] = useState({
    displayColorPicker: false,
    hex: props.BgColor === "none" ? "" : props.BgColor,
    color: hexToRgb(props.BgColor),
  });
  const handleClick = () => {
    setActiveColor({
      ...activeColor,
      displayColorPicker: !activeColor.displayColorPicker,
    });
  };

  const handleClose = () => {
    setActiveColor({
      ...activeColor,
      displayColorPicker: false,
    });
  };

  const handleChange = (color) => {
    setActiveColor({
      ...activeColor,
      hex: color.hex,
      color: color.rgb,
    });

    props.applyTextObjectProperties({
      id: props.object.id,
      type: 'backgroundColor',
      value: activeColor.hex
    })
  };

  const onSwatchClickHandler = (color) => {
    if (color === "none") {
      setActiveColor({
        ...activeColor,
        hex: "",
        color: { r: 0, g: 0, b: 0 },
      });
    } else {
      setActiveColor({
        ...activeColor,
        hex: color,
        color: hexToRgb(color),
      });
    }

    props.applyTextObjectProperties({
      id: props.object.id,
      type: 'backgroundColor',
      value: color === 'none' ? 'transparent' : color
    })
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: `rgba(${activeColor.color.r}, ${activeColor.color.g}, ${activeColor.color.b}, ${activeColor.color.a})`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });
  const txtBackgroundColor = [
    "ffffff",
    "e1e1e1",
    "d7d7d7",
    "cccccc",
    "c2c2c2",
    "b7b7b7",
    "a1a1a1",
    "959595",
    "00aaff",
    "adbcda",
    "c4ad6a",
    "596a95",
    "4bc9e5",
    "f58a6d",
    "72c4b9",
    "cbd6ec",
    "c4927a",
  ];
  return (
    <div className="text-bg form-group mb-2 d-flex flex-wrap">
      <div className="input-color text-center mb-2">
        <button
          id="t-bg-btn"
          style={{ background: activeColor.hex }}
          onClick={handleClick}
        >
          {" "}
        </button>
        <input
          id="tBg-hex"
          className="input-dark text-white form-control edit-input-height"
          value={activeColor.hex}
        />
        <label>HEX</label>
        {activeColor.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={handleClose} />
            <SketchPicker color={activeColor.color} onChange={handleChange} />
          </div>
        ) : null}
      </div>
      <div className="rgb-wrapper text-center">
        <input
          id="tBg-red"
          className="form-control input-dark edit-input-height"
          type="text"
          name="Red"
          value={activeColor.color.r}
        />
        <label htmlFor="tBg-red">R</label>
      </div>
      <div className="rgb-wrapper text-center">
        <input
          id="tBg-grn"
          className="form-control input-dark edit-input-height"
          type="text"
          name="Green"
          value={activeColor.color.g}
        />
        <label htmlFor="tBg-green">G</label>
      </div>
      <div className="rgb-wrapper text-center mr-0">
        <input
          id="tBg-blu"
          className="form-control input-dark edit-input-height"
          type="text"
          name="Blue"
          value={activeColor.color.b}
        />
        <label htmlFor="tBg-blue">B</label>
      </div>
      <div className="swatches w-100 d-flex flex-wrap">
        <button
          className="tBg no-color"
          style={{ backgroundColor: "#ffffff" }}
          onClick={() => onSwatchClickHandler("none")}
        >
          {" "}
        </button>
        {txtBackgroundColor.map((element, index) => (
          <button
            key={index}
            className="tBg swatch mb-0"
            style={{ backgroundColor: "#" + element }}
            onClick={() => onSwatchClickHandler("#" + element)}
          >
            {" "}
          </button>
        ))}
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    applyTextObjectProperties: (newObj) => dispatch(applyTextObjectProperties(newObj))
  };
};
export default connect(null, mapDispatchToProps)(FontBackgroundColor);
