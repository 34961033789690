import React, { useState } from "react";
import Modal from "./Modal";

const DownloadData = () => {
  const [modal, setModal] = useState({
    isOpen: false,
    value: {},
  });

  const allModalData = [
    {
      img: "./assets/images/error.svg",
      headers: "Delete My Data",
      msg: `Are you sure you want to delete your data?
      This action is permanent and cannot be reversed.`,
      firstButton: "cancel",
      secondButton: "Delete My Data",
      type: "deleteUserData",
      text: "Deleting Data...",
    },
    {
      img: "./assets/images/warning.svg",
      headers: "Stop Processing Data",
      msg: `Are you sure you want to stop processing your data? Doing so
      will disable your account and in order to reactivate your
      account, you would have to contact our support team.`,
      firstButton: "cancel",
      secondButton: "Stop Processing Data",
      type: "stopProcessingData",
      text: "stop Processing...",
    },
    {
      img: "./assets/images/warning.svg",
      headers: "Download My Data",
      msg: `VideoReel2.0 stores your private informations. If you wish to
      download these data, you can do that by clicking Download My
      Data button.`,
      firstButton: "cancel",
      secondButton: "Download My Data",
      type: "downloadUserData",
      text: "Downloading Data...",
    },
  ];

  const modalOpen = (val) => {
    setModal({
      ...modal,
      isOpen: true,
      value: val,
    });
  };

  const modalClose = () => {
    setModal({
      ...modal,
      isOpen: false,
      value: "",
    });
  };

  return (
    <>
      <div className="container px-4 mb-5">
        <h4 className="text-white personal-info-text mb-3 text-left">
          Manage Your Account Data
        </h4>
        <div className="row profile-info-box">
          <div className="row row-1  d-flex justify-content-flex-start">
            <div className="col">
              <p className="profile-title-gdpr text-left">
                VideoReel2.0 stores your private informations like your name,
                email, password. It also stores your uploaded graphics such as
                images, videos and your music files along with TTS ( Text to
                speech). If you wish to download these data, you can do that "by
                clicking" download your data.
              </p>
              <button
                className="btn btn-download d-block"
                data-toggle="modal"
                data-target="#Download-My-Data"
                onClick={() => modalOpen(allModalData[2])}
              >
                {" "}
                Downlad My Data{" "}
              </button>
            </div>
          </div>
          <div className="row row-2 mt-4">
            <div className="col">
              <p className="profile-title-gdpr text-left">
                If you wish to stop your private data from getting processed on
                VideoReel2.0 you can click the button to do so. Please note that
                doing this will disable your account and you will not be able to
                use VideoReel2.0 further. In order to reactivate your account,
                you would have to contact our support team at
                <span>
                  <a
                    className="support-link-text profile-title-gdpr "
                    href="support@vineasx.com"
                    target="_blank"
                  >
                    {" "}
                    support@vineasx.com.{" "}
                  </a>
                </span>
                <span className="warning-text profile-title-gdpr ">
                  {" "}
                  Please Proceed With Caution.
                </span>
              </p>
              <button
                className="btn btn-deactiavate d-block"
                data-toggle="modal"
                data-target="#stop-processing-data"
                onClick={() => modalOpen(allModalData[1])}
              >
                {" "}
                Stop Processing My Data
              </button>
            </div>
          </div>
          <div className="row row-3 mt-4 mb-1 pl-2">
            <div className="col">
              <p className="profile-title-gdpr text-left">
                If you wish to delete all your private data, you can do so by
                clicking this button. Please note that this will permanently
                delete all the data of yours from our server. This step is not
                reversible and you cannot use VideoReel2.0 further. You cannot
                undo this step from support also. So if you wish to re-use
                VideoReel2.0 again, then you will have to start all over again
                from scratch.
                <span className="warning-text profile-title-gdpr ">
                  {" "}
                  Please Proceed With Caution.
                </span>
              </p>
              <button
                className="btn btn-delete d-block"
                data-toggle="modal"
                data-target="#confirm-del-account"
                onClick={() => modalOpen(allModalData[0])}
              >
                {" "}
                Delete My Data{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      {modal.isOpen ? <Modal close={modalClose} values={modal.value} /> : <></>}
    </>
  );
};

export default DownloadData;
