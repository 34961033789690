import React, { useEffect, useRef, useState } from "react";
import GsDrag from "../../GsDrag/GsDrag";
import ContentEditable from 'react-contenteditable'
import { applyObjectConfig, applyObjectReplace } from "../../../../../actions/normalTemplateActions";
import { connect } from "react-redux";
import WebFontLoader from '@dr-kobros/react-webfont-loader';
import { ResizableBox } from 'react-resizable';
const TextObject = (props, ref) => {
    const fontArray = [];
    if (typeof props.objectItem.styles.fontFamily === 'undefined')
        fontArray.push('Poppins')
    else
        fontArray.push(props.objectItem.styles.fontFamily)

    const [textState, setTextState] = useState({
        object: props.objectItem,
        isSelected: props.objectItem.isSelected,
        config: {
            google: {
                families: fontArray,
            }
        },
        nrStyle: {
            ...props.objectItem.styles
        }

    })
    useEffect(() => {
        setTextState({
            ...textState,
            object: props.objectItem,
            isSelected: props.objectItem.isSelected,
            config: {
                google: {
                    families: fontArray,
                }
            },
            nrStyle: {
                ...props.objectItem.styles
            }
        })
    }, [JSON.stringify(props.objectItem)])

    //const isSelected = props.objectItem.isSelected;
    const handleVideo = (e, id) => {
        //props.objectOnClick(id);
    }
    const content = useRef(null);

    const handleChange = (e) => {
        // Update Redux with the value
        const newObject = {
            value: e.target.value,
            type: 'text',
            id: props.objectItem.id
        };
        props.applyObjectReplace(newObject);

    }
    const callback = status => {
        console.log(status, 'Loaded');
    };

    const handleResize = (e, data) => {
        console.log(e, data);
        props.applyReConfig({
            left: props.objectItem.styles.left,
            top: props.objectItem.styles.top,
            id: props.objectItem.id,
            width: data.size.width,
            height: data.size.height,
            type: 'dimension'
        });
    }
    return (
        <WebFontLoader config={textState.config} >
            {props.playStatus === false && textState.isSelected ?
                <div ref={ref} className="absolute text-object z-index" style={{ ...textState.nrStyle, width: parseInt(props.objectItem.styles.width) + 5, height: parseInt(props.objectItem.styles.height) + 5 }}>
                    <ResizableBox width={parseInt(props.objectItem.styles.width)}
                        height={parseInt(props.objectItem.styles.height)}
                        minConstraints={[10, 10]}
                        maxConstraints={[480, 270]}
                        onResize={(e, data) => handleResize(e, data)}
                        style={{ border: '4px dotted #1cb7f2' }}
                    >
                        <ContentEditable innerRef={content} html={textState.object.value} disabled={false} onChange={handleChange} tagName='article' />
                    </ResizableBox>
                </div>

                : <div onClick={(e) => handleVideo(e, textState.object.id)} ref={ref} className="absolute text-object" style={textState.nrStyle}>
                    {textState.object.value}
                </div>}
        </WebFontLoader>
    )

}

const forwardComponent = React.forwardRef(TextObject);
const mapDispatchToProps = dispatch => {
    return {
        applyObjectReplace: (newObject) => dispatch(applyObjectReplace(newObject)),
        applyReConfig: (newConfig) => dispatch(applyObjectConfig(newConfig))
    };
}
export default connect(null, mapDispatchToProps, null, { forwardRef: true })(forwardComponent);