import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import Alert from "../alert/Alert";
import DownloadData from "./DownloadData";
import UpdateEmail from "./UpdateEmail";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import TitleBar from "../Common/TitleBar";

const Privacy = ({ auth: { user, loading } }) => {
  const [is_client, setIsClient] = useState(false);
  const [is_team, setIsTeam] = useState(false);
  useEffect(() => {
    if (user) {
      setIsClient(loading || !user.is_client ? false : user.is_client);
      setIsTeam(loading || !user.is_team ? false : user.is_team);
    }
  }, [user, loading]);

  if (is_client === true || is_team === true) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <div>
      <TitleBar title="Privacy" />
      <section
        id="project-section"
        style={{ backgroundColor: "#171717", paddingTop: "100px" }}
        className="pad-section"
      >
        <Alert className="mb-3" />
        <UpdateEmail />
        <DownloadData />
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Privacy);
