import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import queryString from "query-string";

const BusinessNav = ({ user, loading, location }) => {
  const [userId, setUserId] = useState("");
  const [is_client, setIsClient] = useState(false);
  const [accountType, setAccountType] = useState([]);
  const active_data = queryString.parse(location);
  const [mainIs, setMainIs] = useState(false);
  console.log(mainIs, "ll")
  console.log(window.location.pathname);

  useEffect(() => {
    if (user) {
      setIsClient(loading || !user.is_client ? false : user.is_client);
      setMainIs(!loading && user.isWl);
    }
  }, [user, loading]);

  useEffect(() => {
    setUserId(loading || !user ? "" : user.user_id);
  }, [user, loading]);

  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  return (
    <div className="col-3 " style={{ marginTop: "5rem" }}>
      <div className="row align-items-center justify-content-space-between m-2">
        <div className="col-12 p-0">
          <nav
            className="navbar navbar-dark m-0"
            style={{ background: "black" }}
          >
            <ul className="navbar-nav business-nav">
              <li
                className="nav-item align-left"
                data-toggle="tooltip"
                data-placement="bottom"
                title="FB Templates"
              >
                {is_client === true ? null : accountType.includes("business") || accountType.includes("com") ||
                  accountType.includes("bundle") ? (
                  <Link
                    className={`nav-link ${window.location.pathname === "/fb-templates"
                      ? "active"
                      : ""
                      }`}
                    to="./fb-templates"
                  >
                    FB Templates
                  </Link>
                ) : null}
              </li>
              <li
                className="nav-item"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Video Flix"
              >
                {is_client === true ? null : accountType.includes("bundle") || accountType.includes("com") ||
                  accountType.includes("unlimited") ? (
                  <Link
                    className={`nav-link ${window.location.pathname === "/video-flix" ? "active" : ""
                      }`}
                    to="./video-flix"
                  >
                    Video Flix
                  </Link>
                ) : null}
              </li>
              <li
                className="nav-item"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Udemy Style website"
              >
                {is_client === true ? null : accountType.includes("bundle") || accountType.includes("com") ||
                  accountType.includes("dfy") ? (
                  <Link
                    className={`nav-link ${window.location.pathname === "/udemy-style"
                      ? "active"
                      : ""
                      }`}
                    to="./udemy-style"
                  >
                    Udemy Style website
                  </Link>
                ) : null}
              </li>

              <li
                className="nav-item"
                data-toggle="tooltip"
                data-placement="bottom"
                title="DFY Course"
              >
                {is_client === true ? null : accountType.includes("bundle") || accountType.includes("com") ||
                  accountType.includes("dfy") ? (
                  <Link
                    className={`nav-link ${window.location.pathname === "/dfy-course" ? "active" : ""
                      }`}
                    to="./dfy-course"
                  >
                    DFY Course
                  </Link>
                ) : null}
              </li>

              <li
                className="nav-item"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Client Contract"
              >
                {is_client === true ? null : accountType.includes("business") || accountType.includes("com") ||
                  accountType.includes("bundle") ? (
                  <Link
                    className={`nav-link ${window.location.pathname === "/client-contract"
                      ? "active"
                      : ""
                      }`}
                    to="./client-contract"
                  >
                    Client Contract
                  </Link>
                ) : null}
              </li>

              <li
                className="nav-item"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Reseller License"
              >
                {is_client === true ? null : accountType.includes("bundle") || accountType.includes("com") ||
                  accountType.includes("business") ? (
                  <Link
                    className={`nav-link ${window.location.pathname === "/reseller-license"
                      ? "active"
                      : ""
                      }`}
                    to="./reseller-license"
                  >
                    Reseller License
                  </Link>
                ) : null}
              </li>

              <li
                className="nav-item"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Web Hosting"
              >
                {is_client === true ? null : accountType.includes("business") || accountType.includes("com") ||
                  accountType.includes("bundle") ? (
                  <Link
                    className={`nav-link ${window.location.pathname === "/web-hosting"
                      ? "active"
                      : ""
                      }`}
                    to="./web-hosting"
                  >
                    Web Hosting
                  </Link>
                ) : null}
              </li>
              <li
                className="nav-item"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Video Commercial"
              >
                {is_client === true ? null : accountType.includes("business") || accountType.includes("com") ||
                  accountType.includes("bundle") ? (
                  <Link
                    className={`nav-link ${window.location.pathname === "/video-commercial"
                      ? "active"
                      : ""
                      }`}
                    to="/video-commercial"
                  >
                    Video Commercial
                  </Link>
                ) : null}
              </li>
              <li
                className="nav-item"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Video Promos"
              >
                {is_client === true ? null : accountType.includes("bundle") || accountType.includes("com") ||
                  accountType.includes("dfy") ? (
                  <Link
                    className={`nav-link ${window.location.pathname === "/video-promos"
                      ? "active"
                      : ""
                      }`}
                    to="./video-promos"
                  >
                    Video Promos
                  </Link>
                ) : null}
              </li>
              <li
                className="nav-item"
                data-toggle="tooltip"
                data-placement="bottom"
                title="DFY Lead Magnets"
              >
                {is_client === true ? null : accountType.includes("business") || accountType.includes("com") ||
                  accountType.includes("bundle") ? (
                  <a
                    className="nav-link"
                    href="!#"
                    onClick={(e) => {
                      e.preventDefault();
                      window.location =
                        "https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Sendiio/100.zip";
                    }}
                  >
                    DFY Lead Magnets
                  </a>
                ) : null}
              </li>
              <li
                className="nav-item"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Agency Website"
              >
                {is_client === true ? null : accountType.includes("business") || accountType.includes("com") ||
                  accountType.includes("bundle") ? (
                  <Link
                    className={`nav-link ${window.location.pathname === "/agency-website"
                      ? "active"
                      : ""
                      }`}
                    to="./agency-website"
                  >
                    Agency Website
                  </Link>
                ) : null}
              </li>
              <li
                className="nav-item"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Social Media Assets"
              >
                {is_client === true ? null : accountType.includes("bundle") || accountType.includes("com") ||
                  accountType.includes("dfylite") ||
                  accountType.includes("dfy") ? (
                  <Link
                    className={`nav-link ${window.location.pathname === "/social-media-assets"
                      ? "active"
                      : ""
                      }`}
                    to="./social-media-assets"
                  >
                    Social Media Assets
                  </Link>
                ) : null}
              </li>
              <li
                className="nav-item"
                data-toggle="tooltip"
                data-placement="bottom"
                title="DFY Articles"
              >
                {is_client === true ? null : accountType.includes("bundle") || accountType.includes("com") ||
                  accountType.includes("dfy") ? (
                  <Link
                    className={`nav-link ${window.location.pathname === "/dfy-articles"
                      ? "active"
                      : ""
                      }`}
                    to="./dfy-articles"
                  >
                    DFY Articles
                  </Link>
                ) : null}
              </li>

              {mainIs === "0" && (

                <li
                  className="nav-item"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="WL Rebrandings"
                >
                  {is_client === true ? null : accountType.includes("business") || accountType.includes("com") ||
                    accountType.includes("bundle") ? (
                    <Link
                      className={`nav-link ${window.location.pathname === "/rebranding" ? "active" : ""
                        }`}
                      to="./rebranding"
                    >
                      WL Rebranding
                    </Link>
                  ) : null}
                </li>
              )}

              <li
                className="nav-item"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Business Finder"
              >
                {is_client === true ? null : accountType.includes("business") || accountType.includes("com") ||
                  accountType.includes("bundle") ? (
                  <Link
                    className={`nav-link ${window.location.pathname === "/business-finder"
                      ? "active"
                      : ""
                      }`}
                    to="./business-finder"
                  >
                    Business Finder
                  </Link>
                ) : null}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

BusinessNav.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
});

export default connect(mapStateToProps)(BusinessNav);
