import React, { useState, useEffect } from "react";
import { frontURL } from "../../../../../../global/global";
import { connect } from "react-redux";
import { fetchUploadedAudio } from "../../../../../../actions/video";
import { generateTtsAudio } from "../../../../../../actions/tts";
import { setAlert, removeAlert } from "../../../../../../actions/alert";
import { baseURL } from "../../../../../../global/global";

import axios from "axios";
import ConfirmDelete from "../../../../../confirmdelete/ConfirmDelete";
import { applyObjectAdd } from "../../../../../../actions/normalTemplateActions";

function AudioTrack({
  audio,
  user_id,
  fetchUploadedAudio,
  generateTtsAudio,
  setAlert,
  applyObjectAdd,
}) {
  const [audioObject, setAudioObject] = useState({});
  const [audioEvent, setAudioEvent] = useState({});
  const [hoverData, setHoverData] = useState("");
  const [modal, setModal] = useState({
    isOpen: false,
  });
  const [deleteData, setDeleteData] = useState({});

  useEffect(() => {
    if (
      !(
        Object.keys(audioObject).length === 0 &&
        audioObject.constructor === Object
      )
    ) {
      audioObject.play();
    }
  }, [audioObject]);

  useEffect(() => {
    if (
      !(
        Object.keys(audioObject).length === 0 &&
        audioObject.constructor === Object
      )
    ) {
      audioObject.onended = () => {
        audioEvent.src = "./assets/images/play_1.svg";
      };
    }
  }, [audioEvent, audioObject]);

  const playOrPauseAudio = (audio) => {
    if (
      Object.keys(audioObject).length === 0 &&
      audioObject.constructor === Object
    ) {
      setAudioObject(audio);
      return;
    } else {
      audioObject.pause();
    }

    setAudioObject(audio);
  };

  // Close delete modal
  const close = () => {
    setModal({ isOpen: false });
  };

  // delete project by modal
  const deleteProjectByModal = () => {
    deleteUploadedData(deleteData);
  };

  const deleteUploadedData = async (data) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(
      data.type === "tts" ? { user_id: user_id, url: data.url } : data
    );

    try {
      const res = await axios.post(
        `${baseURL}${
          data.type === "tts" ? "delete-tts" : "delete-user-uploaded-file"
        }`,
        body,
        config
      );

      if (res.data.status === true) {
        setAlert(
          data.type === "tts" ? res.data.message : res.data.data,
          "danger"
        );

        if (data.type === "audio") {
          fetchUploadedAudio({ type: "audio", user_id: user_id });
        } else if (data.type === "tts") {
          generateTtsAudio({ user_id });
        }
        setDeleteData({});
      }
      close();
    } catch (err) {
      console.log(err.response);
    }
  };

  const handleClick = (e, data) => {
    applyObjectAdd({
      type: "audio",
      value: data.url,
    });
  };
  return (
    <>
      {audio &&
        audio.map((value, index) => {
          return (
            <div
              className="aud-wrapper"
              key={index}
              style={{ position: "relative" }}
              onMouseOver={() => setHoverData(value.url)}
              onMouseOut={() => setHoverData("")}
              onClick={(e) => handleClick(e, value)}
            >
              <div className="aud-ctrls">
                <button className="aud-ctrl-btn btn">
                  {/* <div className="spinner-grow" style={{color:'#6b33d3'}}> </div> */}
                  <img
                    src={"./assets/images/play_1.svg"}
                    alt=""
                    style={{
                      borderRadius: "50%",
                      width: "12px",
                      height: "15px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (value.url === audioObject.src) {
                        if (
                          e.target.src ===
                          `${frontURL}/assets/images/play_1.svg`
                        ) {
                          audioObject.play();
                          e.target.src = "./assets/images/pause.svg";
                        } else {
                          e.target.src = "./assets/images/play_1.svg";
                          audioObject.pause();
                        }
                        return;
                      }
                      if (
                        !(
                          Object.keys(audioEvent).length === 0 &&
                          audioEvent.constructor === Object
                        )
                      ) {
                        audioEvent.src = "./assets/images/play_1.svg";
                      }
                      setAudioEvent(e.target);
                      playOrPauseAudio(new Audio(value.url));
                      e.target.src = "./assets/images/pause.svg";
                    }}
                  />
                </button>
                <span className="white">
                  {value.title
                    ? value.title.split(" ")[0]
                    : value.name
                    ? value.name
                    : "mp3"}
                </span>
              </div>

              {/* <div
                style={{
                  position: "absolute",
                  top: "2px",
                  left: "8px",
                  width: "22px",
                  height: "22px",
                  background: "white",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  removeAlert();
                  setDeleteData(
                    value.name
                      ? { type: "tts", url: value.audio_url }
                      : {
                          type: "audio",
                          id: value.id,
                        }
                  );
                  setModal({ isOpen: true });
                }}
              >
                <img
                  src="./assets/images/tik.png"
                  alt=""
                  style={{
                    borderRadius: "50%",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div> */}
              {value.id || value.name ? (
                <div
                  style={{
                    position: "absolute",
                    top: "2px",
                    right: "8px",
                    width: "22px",
                    height: "22px",
                    background: "white",
                    borderRadius: "50%",
                    display: `${hoverData === value.url ? "flex" : "none"}`,
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  className="delete-preview-editor"
                  onClick={() => {
                    removeAlert();
                    setDeleteData(
                      value.name
                        ? { type: "tts", url: value.audio_url }
                        : {
                            type: "audio",
                            id: value.id,
                          }
                    );
                    setModal({ isOpen: true });
                  }}
                >
                  <i
                    class="fa fa-trash preview-delete-icon"
                    style={{ fontSize: "15px", color: "black" }}
                  ></i>
                </div>
              ) : null}
              {modal.isOpen ? (
                <ConfirmDelete close={close} confirm={deleteProjectByModal} />
              ) : null}
            </div>
          );
        })}
    </>
  );
}

export default connect(null, {
  generateTtsAudio,
  fetchUploadedAudio,
  setAlert,
  removeAlert,
  applyObjectAdd,
})(AudioTrack);
