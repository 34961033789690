import React from "react";
import Loader from "../loader/Loader";
import { connect } from "react-redux";

const LoginLoader = ({ loader }) => {
  return (
    <>
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            width: "100%",
            height: "100vh",
            backgroundColor: " rgba(0, 0, 0)",
            zIndex: "150",
            position: "fixed",
            top: "0px",
            left: "0px",
          }}
        >
          <Loader />
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  loader: state.loader.verifyLoader,
});

export default connect(mapStateToProps)(LoginLoader);
