import axios from "axios";
import { baseURL } from "../global/global";

import {
  GET_TEMPLATE,
  // LOAD_TEMPLATE_ID,
  LOAD_TEMPLATE,
  CLEAR_TEMPLATE,
  FETCH_UPLOADED_DATA,
  FETCH_UPLOADED_AUDIO,
  FETCH_UPLOADED_LOGO,
  FETCH_ALL_PROJECT,
  FETCH_MERGED_VIDEOS,
  LOAD_MERGE_DATA,
  SET_DROPDOWN_DATA,
  FETCH_LANGUAGE_FOR_TRANSLATE,
} from "./Types";

import { setLoader, removeLoader } from "./loader";

// Fetching all Template
export const fetchingTemplate = (max_count) => async (dispatch) => {
  dispatch(setLoader());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(max_count);

  try {
    const res = await axios.post(`${baseURL}fetch-templates`, body, config);

    if (res.data.status === true) {
      dispatch({
        type: GET_TEMPLATE,
        payload: res.data.data,
      });
      dispatch(removeLoader());
    }
  } catch (err) {
    dispatch(removeLoader());
    console.log(err);
  }
};

// // Load Template id
// export const loadTemplateId = data => async dispatch => {
//   dispatch({
//     type: LOAD_TEMPLATE_ID,
//     payload: data
//   })
// }

// Load All Data of template
export const loadTemplate = (data) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body =
    data.name === "template"
      ? JSON.stringify({ template_id: data.id })
      : JSON.stringify({ project_id: data.id });

  try {
    const res =
      data.name === "template"
        ? await axios.post(`${baseURL}create-project`, body, config)
        : await axios.post(`${baseURL}edit-project`, body, config);
        
    if (res.data.status === true) {
      dispatch({
        type: LOAD_TEMPLATE,
        payload: res.data.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Load All Data of single merged videos
export const loadMergeData = (data) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(data);

  try {
    const res = await axios.post(`${baseURL}fetch-merge`, body, config);
    if (res.data.status === true) {
      dispatch({
        type: LOAD_MERGE_DATA,
        payload: res.data.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Fetch Uploaded Audio

export const fetchUploadedAudio = (data) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(data);

  try {
    const res = await axios.post(
      `${baseURL}fetch-user-uploaded-file`,
      body,
      config
    );

    if (res.data.status === true) {
      dispatch({
        type: FETCH_UPLOADED_AUDIO,
        payload: res.data.data,
      });
    } else if (res.data.status === false) {
      dispatch({
        type: FETCH_UPLOADED_AUDIO,
        payload: [],
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Fetch Uploaded Logo

export const fetchUploadedLogo = (data, setUploadLoader) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(data);

  try {
    const res = await axios.post(
      `${baseURL}fetch-user-uploaded-file`,
      body,
      config
    );

    if (res.data.status === true) {
      dispatch({
        type: FETCH_UPLOADED_LOGO,
        payload: res.data.data,
      });
      setUploadLoader(false);
    } else if (res.data.status === false) {
      dispatch({
        type: FETCH_UPLOADED_LOGO,
        payload: [],
      });
      setUploadLoader(false);
    }
  } catch (err) {
    console.log(err);
  }
};

// Fetch All upload DAta
export const fetchLoadTemplate = (data) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(data);

  try {
    const res = await axios.post(
      `${baseURL}fetch-user-uploaded-file`,
      body,
      config
    );

    if (res.data.status === true) {
      dispatch({
        type: FETCH_UPLOADED_DATA,
        payload: res.data.data,
      });
    } else if (res.data.status === false) {
      dispatch({
        type: FETCH_UPLOADED_DATA,
        payload: [],
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Fetch all project
export const fetchAllProject = (data) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(data);

  try {
    const res = await axios.post(`${baseURL}fetch-all-projects`, body, config);

    if (res.data.status === true) {
      dispatch({
        type: FETCH_ALL_PROJECT,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: FETCH_ALL_PROJECT,
        payload: [],
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Fetch all project
export const fetchAllMergedVideos = (data) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(data);

  try {
    const res = await axios.post(
      `${baseURL}fetch-merge-campaigns`,
      body,
      config
    );

    if (res.data.status === true) {
      dispatch({
        type: FETCH_MERGED_VIDEOS,
        payload: res.data.data.data,
      });
    } else {
      dispatch({
        type: FETCH_MERGED_VIDEOS,
        payload: [],
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const setDropdownValue = (data) => async (dispatch) => {
  dispatch({
    type: SET_DROPDOWN_DATA,
    payload: data,
  });
};

// Fetch language for translate
export const fetchLanguageForTranslate = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({});

  try {
    const res = await axios.post(
      `${baseURL}api/fetch-google-language`,
      body,
      config
    );

    if (res.data.status === true) {
      dispatch({
        type: FETCH_LANGUAGE_FOR_TRANSLATE,
        payload: res.data.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Clear Template
export const clearTemplate = () => async (dispatch) => {
  dispatch({
    type: CLEAR_TEMPLATE,
  });
};
