import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updatePersonalInformation } from "../../actions/auth";
import { setLoader } from "../../actions/loader";
import PropTypes from "prop-types";

function PersonalInformation({
  user,
  loading,
  updatePersonalInformation,
  loader,
  setLoader,
}) {
  const [personalInformation, setPersonalInformation] = useState({
    first_name: "",
    email: "",
    id: "",
  });

  const [check, setCheck] = useState(false);

  // Fetching User Information
  useEffect(
    () =>
      setPersonalInformation({
        first_name: loading || !user.name ? "" : user.name,

        email: loading || !user.email ? "" : user.email,
        id: loading || !user.user_id ? "" : user.user_id,
      }),
    [loading, user]
  );

  const updateInformation = (e) => {
    setPersonalInformation({
      ...personalInformation,
      [e.target.name]: e.target.value,
    });
  };

  const submitPersonalInformation = async (e) => {
    e.preventDefault();
    setCheck(true);
    setLoader();
    let { first_name, email, id } = personalInformation;

    const userInformation = {
      first_name: first_name.split(" ")[0],
      last_name: first_name.split(" ").slice(1)
        ? first_name.split(" ").slice(1).join(" ")
        : "",
      oldEmail: email,
      id:
        user.client_id !== null
          ? user.client_id
          : user.team_id !== null
          ? user.team_id
          : id,
    };

    await updatePersonalInformation(userInformation);
    setCheck(false);
  };

  return (
    <div className="container px-4 mb-4 mb-md-5">
      <h4 className="text-white personal-info mb-3 text-left">
        Personal Information
      </h4>
      <div className="profile-info-box">
        <form onSubmit={(e) => submitPersonalInformation(e)}>
          <div className="row d-flex justify-content-center ">
            <div className="col col-lg-6 col-12 pr-xl-0">
              <div className="form-group form-group-mr-profile ">
                <label className="input-label">Profile Name </label>
                <input
                  className="form-control input-dark personal-info"
                  type="text"
                  name="first_name"
                  placeholder="............"
                  value={personalInformation.first_name}
                  onChange={(e) => updateInformation(e)}
                  required
                />
              </div>
            </div>

            <div className="col col-lg-6 col-12 pr-xl-0">
              <div className="form-group form-group-mr-profile">
                <label className="input-label"> Your Email Address </label>
                <input
                  className="form-control input-dark personal-info"
                  type="email"
                  name="email"
                  placeholder="............"
                  value={personalInformation.email}
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className="col col-md-12 text-center mt-5">
            {loader && check ? (
              <button className="btn btn-3 update-pass" type="button" disabled>
                <span
                  class="spinner-border spinner-border-sm text-light mx-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                <strong>Loading...</strong>
              </button>
            ) : (
              <button className="btn btn-3 update-pass">Update</button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

PersonalInformation.propTypes = {
  user: PropTypes.object,
  updatePersonalInformation: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  loader: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
  loader: state.loader.loader,
});

export default connect(mapStateToProps, {
  updatePersonalInformation,
  setLoader,
})(PersonalInformation);
