import {
  // LOAD_TEMPLATE_ID,
  LOAD_TEMPLATE,
  CLEAR_TEMPLATE,
  FETCH_UPLOADED_DATA,
  FETCH_UPLOADED_LOGO,
  FETCH_UPLOADED_AUDIO,
  LOAD_MERGE_DATA,
  FETCH_LANGUAGE_FOR_TRANSLATE,
} from "../actions/Types";

const initialState = {
  loading: true,
  // template_id: {},
  templateData: [],
  uploadImage: [],
  uploadAudio: [],
  uploadLogo: [],
  mergeData: [],
  languageTranslate: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    // case LOAD_TEMPLATE_ID:
    //   return {
    //     ...state,
    //     loading: false,
    //     template_id: payload
    //   }
    case LOAD_TEMPLATE:
      return {
        ...state,
        loading: false,
        templateData: payload,
      };
    case LOAD_MERGE_DATA:
      return {
        ...state,
        loading: false,
        mergeData: payload,
      };
    case FETCH_UPLOADED_DATA:
      return {
        ...state,
        loading: false,
        uploadImage: payload,
      };

    case FETCH_LANGUAGE_FOR_TRANSLATE:
      return {
        ...state,
        loading: false,
        languageTranslate: payload,
      };
    case FETCH_UPLOADED_LOGO:
      return {
        ...state,
        loading: false,
        uploadLogo: payload,
      };
    case FETCH_UPLOADED_AUDIO:
      return {
        ...state,
        loading: false,
        uploadAudio: payload,
      };
    case CLEAR_TEMPLATE:
      return initialState;

    default:
      return state;
  }
}
