import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { saveReelapp } from "../../actions/socialAccount";
import Alert from "../alert/Alert";

const CommonModal = ({
  close,
  appName,
  saveReelapp,
  auth: { user, loading },
}) => {
  const [details, setDetails] = useState({
    email: false,
    password: false,
    isLoading: false,
    buttonText: "Connect",
  });

  const [user_id, setUserId] = useState("");

  // fetching useId
  useEffect(() => {
    setUserId(loading || !user.user_id ? "" : user.user_id);
  }, [user, loading]);

  const handleChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDetails({
      ...details,
      isLoading: true,
      buttonText: "Connecting...",
    });

    const data = {
      user_id: user_id,
      email: details.email,
      password: details.password,
      app_name: appName,
    };

    await saveReelapp(data, close);
    setDetails({
      ...details,
      isLoading: false,
      buttonText: "Connect",
    });
  };

  let image = false;
  switch (appName) {
    case "syvid":
      image = "./assets/images/logo-syvideo.svg";
      break;
    case "trimreel":
      image = "./assets/images/logo-trim.svg";
      break;
    case "livereel":
      image = "./assets/images/logo-livereel.svg";
      break;
    case "scriptreel":
      image = "./assets/images/logo-scriptreel.svg";
      break;
    default:
      image = false;
      break;
  }

  return (
    <div
      id="publish"
      className="modal fade d-block show"
      style={{ background: "rgba(0,0,0,0.9)" }}
      tabIndex="-1"
      role="dialog"
      data-backdrop="static"
      aria-labelledby="modal-title"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered mx-auto"
        role="document"
      >
        <div className="modal-content">
          <button
            onClick={close}
            type="button"
            className="close mb-3 text-right"
            data-dismiss="modal"
            aria-label="Close"
            style={{ opacity: "1" }}
          >
            <img
              className="img-fluid"
              src="./assets/images/close.svg"
              alt="Close"
            />
          </button>
          <div className="modal-body bg-white text-center">
            <div className="row inner-row icons-wrapper justify-content-center mb-4 mx-auto">
              <div className="icon-wrapper text-center mr-3">
                <div className="img-wrapper soc-icon mx-auto mb-2">
                  <img className="img-fluid" src={image} alt="Logo" />
                </div>
              </div>
            </div>
            <h4
              className="modal-title mb-4"
              style={{ lineHeight: "1.33", color: "#000000" }}
            >
              Connect your {appName} account{" "}
            </h4>
            <Alert authStyle={"authStyle"} />
            <div className="mb-2">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    autoComplete="on"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                    autoComplete="off"
                    required
                    onChange={handleChange}
                  />
                </div>
                <button id="pub-proj" type="submit" className="btn btn-3">
                  {details.buttonText}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

CommonModal.propTypes = {
  auth: PropTypes.object.isRequired,
  saveReelapp: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  appName: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, { saveReelapp })(CommonModal);
