import React from "react";
import {setTimeLinePlayTime} from "../../../../../../../actions/normalTemplateActions";
import {connect} from "react-redux";
function Ruler(props) {
    const handleClick = () => {
        if(props.left <= props.canvasPlayTime.totalTime)
        {
            props.setTimeLinePlayTime(props.left);
        }
    }
    return(
        <div className="sec"  style={{left:props.left*60+'px'}} onClick={handleClick}>
            <div className="sec-pin"> </div>
            <span className="sec-time">{props.left}</span>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setTimeLinePlayTime: (newTime) => dispatch(setTimeLinePlayTime(newTime))
    };
};
export default connect(null, mapDispatchToProps)(Ruler);