import {
  FETCH_SUPPORT_ARTICLE,
  FETCH_SUPPORT_VIDEO,
  CLEAR_SUPPORT_DATA,
  GET_ARTICLE_CONTENT,
  GET_ONE_ARTICLE,
} from "../actions/Types";

const initialState = {
  support_videos: [],
  support_articles: [],
  article: {},
  item: "",
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SUPPORT_ARTICLE:
      return {
        ...state,
        loading: false,
        support_articles: payload,
      };
    case FETCH_SUPPORT_VIDEO:
      return {
        ...state,
        loading: false,
        support_videos: payload,
      };
    case GET_ONE_ARTICLE:
      return {
        ...state,
        loading: false,
        article: payload,
      };
    case GET_ARTICLE_CONTENT:
      return {
        ...state,
        loading: false,
        item: payload,
      };
    case CLEAR_SUPPORT_DATA:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
}
