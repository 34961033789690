import React from "react";
import {ReactComponent as PreviousIcon} from "../../../../../../assets/images/back.svg";
import {ReactComponent as RewindIcon} from "../../../../../../assets/images/rewind.svg";
import {ReactComponent as ForwardIcon} from "../../../../../../assets/images/fast-forward.svg";
import {ReactComponent as PlayIcon} from "../../../../../../assets/images/play_1.svg";
import {ReactComponent as NextIcon} from "../../../../../../assets/images/forward.svg";
import {ReactComponent as FullScreenIcon} from "../../../../../../assets/images/fullscreen.svg";
import {ReactComponent as PauseIcon} from "../../../../../../assets/images/pause.svg";
import {applyObjectSelect, setTimeLinePlayTime} from "../../../../../../actions/normalTemplateActions";
import {connect} from "react-redux";

function PreviewControls(props) {
    const playClick = (e,type) => {
        e.preventDefault();
        props.handleClickPlay(type);
    }

    const handleClick = (e,type) => {
        e.preventDefault();
        if(type === 'first')
        {
            props.setTimeLinePlayTime(0)
        }
        if(type === 'last')
        {
            props.setTimeLinePlayTime(props.canvasPlayTime.totalTime)
        }
        if(type === 'rewind')
        {
            const currentTime = props.canvasPlayTime.currentTime;
            const newTime = (currentTime - 1) < 0 ? 0 : (currentTime-1)
            props.setTimeLinePlayTime(newTime)
        }
        if(type === 'forward')
        {
            const currentTime = props.canvasPlayTime.currentTime;
            const newTime = (currentTime + 1) > props.canvasPlayTime.totalTime ? props.canvasPlayTime.totalTime : (currentTime + 1)
            props.setTimeLinePlayTime(newTime)
        }
    }
    return(
        <div className="vid-controls mt-2 d-flex flex-wrap align-items-center">
            <div className="time-wrapper d-flex">
                <span className="current-time text-white mr-2">{Math.round(props.canvasPlayTime.currentTime)} </span>
                <span className="text-white mr-1"> / </span>
                <span className="duration-time">{props.canvasPlayTime.totalTime} Sec</span></div>
            <ul className="controls-wrapper d-flex">
                <li className="control-btn pr-3" >
                    <a id="back" href="/" className="text-white" data-toggle="tooltip" title="Back" onClick={(e) => handleClick(e,'first')}>
                        <svg width="24px" height="24px" className="d-block" viewBox="0 0 24 24">
                            <PreviousIcon />
                        </svg>
                    </a>
                </li>
                <li className="control-btn pl-3 pr-3" >
                    <a id="rewind" href="/" className="text-white" data-toggle="tooltip" title="Rewind" onClick={(e) => handleClick(e,'rewind')}>
                        <svg width="24px" height="24px" className="d-block" viewBox="0 0 24 24">
                            <RewindIcon />
                        </svg>
                    </a>
                </li>
                { props.previewPlay ? <li className="control-btn pl-3 pr-3" >
                    <a id="play" href="/" className="text-white" data-toggle="tooltip" title="Play" onClick={(e) => playClick(e,'pause')}>
                        <svg width="24px" height="24px" className="d-block" viewBox="0 0 24 24">
                            <PauseIcon />
                        </svg>
                    </a>
                </li> :
                    <li className="control-btn pl-3 pr-3" onClick={(e) => playClick(e,'play')}>
                        <a id="play" href="/" className="text-white" data-toggle="tooltip" title="Play">
                            <svg width="24px" height="24px" className="d-block" viewBox="0 0 24 24">
                                <PlayIcon />
                            </svg>
                        </a>
                    </li>

                }

                <li className="control-btn pl-3 pr-3" >
                    <a id="fast-forward" href="/" className="text-white" data-toggle="tooltip"
                       title="Fast Forward" onClick={(e) => handleClick(e,'forward')}>
                        <svg width="24px" height="24px" className="d-block" viewBox="0 0 24 24">
                            <ForwardIcon />
                        </svg>
                    </a>
                </li>
                <li className="control-btn pl-3" >
                    <a id="forward" href="/" className="text-white" data-toggle="tooltip" title="Forward" onClick={(e) => handleClick(e,'last')}>
                        <svg width="24px" height="24px" className="d-block" viewBox="0 0 24 24">
                            <NextIcon />
                        </svg>
                    </a>
                </li>
            </ul>
            {/*<div className="icon-wrapper">*/}
            {/*    <a id="fullscreen" href="/" className="text-white" data-toggle="tooltip"*/}
            {/*       title="Full Screen">*/}
            {/*        <svg width="24px" height="24px" className="d-block" viewBox="0 0 24 24">*/}
            {/*            <FullScreenIcon />*/}
            {/*        </svg>*/}
            {/*    </a>*/}
            {/*</div>*/}
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setTimeLinePlayTime: (newTime) => dispatch(setTimeLinePlayTime(newTime)),
    };
};
export default connect(null, mapDispatchToProps)(PreviewControls);