import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import Webinar from "../webinar/Webinar";
import MyConnection from "./MyConnection";
import SocialLink from "./SocialLink";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchSocialAccounts } from "../../actions/socialAccount";
import { Redirect } from "react-router-dom";
import TitleBar from "../Common/TitleBar";

export function Integration({
  social,
  fetchSocialAccounts,
  auth: { user, loading },
}) {
  const [user_id, setUserId] = useState("");
  const [is_client, setIsClient] = useState(false);
  const [allSocialAccounts, setAllSocialAccounts] = useState({});

  // fetching client-id
  useEffect(() => {
    if (user) {
      setIsClient(loading || !user.is_client ? false : user.is_client);
    }
  }, [user, loading]);

  // fetching useId
  useEffect(() => {
    setUserId(loading || !user.user_id ? "" : user.user_id);
  }, [user, loading]);

  // useEffect(() => {
  //   if (user_id) {
  //     fetchSocialAccounts(user_id);
  //   }
  // }, [user_id, social.loading, fetchSocialAccounts]);

  useEffect(() => {
    if (!(Object.keys(social).length === 0 && social.constructor === Object)) {
      setAllSocialAccounts(social);
    }
  }, [social, social.loading]);

  useEffect(() => {
    if (user_id) {
      fetchSocialAccounts(user_id);
    }
  }, [user_id, fetchSocialAccounts]);

  if (is_client === true) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div>
      <TitleBar title="Integration" />
      <section id="int-sec-1" className="bg-dark pt-3">
        <div className="container">
          {Object.keys(allSocialAccounts).length === 0 &&
            allSocialAccounts.constructor === Object ? null : (
            <SocialLink social={allSocialAccounts} />
          )}
        </div>
      </section>
      <section id="int-sec-2" className="bg-dark">
        <div className="container custom-wrapper">
          {Object.keys(allSocialAccounts).length === 0 &&
            allSocialAccounts.constructor === Object ? null : (
            <MyConnection social={allSocialAccounts} userGenId={user_id} />
          )}
        </div>
      </section>

      <Webinar />
    </div>
  );
}

Integration.propTypes = {
  social: PropTypes.object,
  fetchSocialAccounts: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  social: state.social,
  auth: state.auth,
});

export default connect(mapStateToProps, { fetchSocialAccounts })(Integration);
