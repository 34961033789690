import { FETCH_TTS_DATA, FETCH_TTS_AUDIO } from "./Types";
import axios from "axios";
import { baseURL } from "../global/global";

export const getTtsData = (data) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ user_id: data });

  try {
    const res = await axios.post(`${baseURL}get-polly-languages`, body, config);
    if (res.data.status === true) {
      dispatch({
        type: FETCH_TTS_DATA,
        payload: res.data.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const generateTts = (data, ttsLoader) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(data);

  try {
    const res = await axios.post(`${baseURL}generate-tts`, body, config);
    if (res.data.status === true) {
      ttsLoader(false);
      dispatch(generateTtsAudio({ user_id: data.user_id }));
    } else {
      ttsLoader(false);
    }
  } catch (err) {
    console.log(err);
  }
};

export const generateTtsAudio = (data) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(data);

  try {
    const res = await axios.post(`${baseURL}fetch-tts`, body, config);
    if (res.data.status === true) {
      dispatch({
        type: FETCH_TTS_AUDIO,
        payload: res.data.data.tts,
      });
    }
  } catch (err) {
    console.log(err);
  }
};
