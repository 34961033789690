import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

const TimeLine = ({ selectVideo, setSelectVideo }) => {
  const [hoverData, setHoverData] = useState("");
  return (
    <div id="timeline-row" className="row mx-auto">
      <div className="timeline-wrapper w-100">
        <div className="timeline-body">
          <div className="timeline-component-main">
            <div className="timeline-component-track_wrapper">
              <div className="timeline-header d-flex w-100 ">
                <div className="timeline-component-ruler"></div>
              </div>
              <div className="timeline-component-track" id="track_id_">
                <div className="timeline-track-layer track-layer-1">
                  {selectVideo.map((value, index) => (
                    <div className={"timeline-slide selected"} key={uuidv4()}>
                      <div
                        className="slide-tile"
                        onMouseOver={() => setHoverData(value.project_id)}
                        onMouseOut={() => setHoverData("")}
                        style={{
                          background: `url(${value.preview_image})`,
                          backgroundRepeat: "no-repeat",
                          width: "10rem",
                          backgroundSize: "cover",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "8px",
                            right: "8px",
                            width: "22px",
                            height: "22px",
                            background: "white",
                            borderRadius: "50%",
                            display: `${
                              hoverData === value.project_id ? "flex" : "none"
                            }`,
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          className="delete-preview-editor"
                          onClick={() =>
                            setSelectVideo((value) =>
                              value.filter(
                                (datas) => datas.project_id !== hoverData
                              )
                            )
                          }
                        >
                          <i
                            className="fa fa-trash preview-delete-icon"
                            style={{ fontSize: "15px", color: "black" }}
                          ></i>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="timeline-component-track"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeLine;
