import React from "react";

function Notification() {
  return (
    <>
      <tr>
        <td className="notification-text">
          Andrew Kissel has joined as a team collaborator on VideoReel as a Team
          Member.
          <br />
          <small className="time-info-text">just now</small>
        </td>
        <td className="notification-status">
          <span className="badges active-badges">New Member</span>
        </td>
      </tr>
      <tr>
        <td className="notification-text">
          Megan Kursawe has joined as a team collaborator on VideoReel as a
          Client.
          <br />
          <small className="time-info-text"> 47 minutes ago</small>
        </td>
        <td className="notification-status">
          <span className="badges active-badges">New Client</span>
        </td>
      </tr>
      <tr>
        <td className="notification-text">
          Your video Lorem Ipsum Via Sed failed during rendering.
          <br />
          <small className="time-info-text"> 1 hour ago</small>
        </td>
        <td className="notification-status">
          <span className="badges failed-badges">Video Failed</span>
        </td>
      </tr>
      <tr>
        <td className="notification-text">
          Your video Lorem Ipsum Via Sed is ready for you to view.
          <br />
          <small className="time-info-text"> 3 hours ago</small>
        </td>
        <td className="notification-status">
          <span className="badges success-badges">Video Ready</span>
        </td>
      </tr>
      <tr>
        <td className="notification-text">
          Your video Lorem Ipsum Via Sed has been posted to Facebook.
          <br />
          <small className="time-info-text"> Yesterday</small>
        </td>
        <td className="notification-status">
          <span className="badges success-badges">Video Posted</span>
        </td>
      </tr>
      <tr>
        <td className="notification-text">
          Your video Lorem Ipsum Via Sed failed to post to Facebook.
          <br />
          <small className="time-info-text"> August 14, 2020 at 5:05PM</small>
        </td>
        <td className="notification-status">
          <span className="badges failed-badges">Post Failed</span>
        </td>
      </tr>
    </>
  );
}

export default Notification;
