import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import BusinessNav from "../businessNav/BusinessNav";

const VideoFlix = ({ user, loading }) => {
  const [accountType, setAccountType] = useState([]);

  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  if (accountType.length > 0) {
    if (
      !(accountType.includes("bundle") || accountType.includes("unlimited"))
    ) {
      return <Redirect to="/dashboard" />;
    }
  }

  return (
    <ContractStyle>
      <div className="contract-assets">
        <section id="contract-assets-content">
          <div className="container">
            <div className="row justify-content-center align-items-between fb-contract mt-5">
              <BusinessNav />
              <div className="col-9 mb-5">
                <h6
                  className="text-white  mb-0 mt-5 mb-2 Account-Management-headings"
                  style={{ fontSize: "18px !important" }}
                >
                  Video Flix
                </h6>
                <div className="row bg-table justify-content-center">
                  <div className="col-8">
                    <div className="card fb-card border-0">
                      <div
                        className="card-header"
                        style={{ background: "#1cb7f2" }}
                      >
                        <h5 className="text-light">
                          VideoFLIX - Netflix Style Video Site Builder
                        </h5>
                      </div>
                      <div className="card-body">
                        VideoFLIX is a subscription-based video content
                        management app built on cloud. Using VideoFLIX you will
                        be able to create your own video content sharing website
                        similar to NetFlix, HBO-Go, DisneyPLUS etc; sharing your
                        own or others unique video content as channels or
                        series. You will be able to create free, one-time or
                        monthly payment plans for your customers to access your
                        video content and build your video content subscription
                        business!
                      </div>

                      <div className="card-footer p-0"></div>
                    </div>
                  </div>
                </div>

                <div className="row bg-table justify-content-center">
                  <div className="col-4 mt-2">
                    <div className="card fb-card border-0">
                      <div className="card-body">
                        {" "}
                        <a
                          className="btn btn-3 accnt-mngmt-btn "
                          style={{ fontSize: "0.9rem" }}
                          href="!#"
                          onClick={(e) => {
                            e.preventDefault();
                            window.location =
                              "https://abhibonus.s3-us-west-2.amazonaws.com/VideoFlix/VideoFlix_2.2.zip";
                          }}
                        >
                          <div>Download VideoFlix</div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="col-4 mt-2">
                    <div className="card fb-card border-0">
                      <div className="card-body">
                        {" "}
                        <a
                          className="btn btn-3 accnt-mngmt-btn "
                          style={{ fontSize: "0.9rem" }}
                          href="!#"
                          onClick={(e) => {
                            e.preventDefault();
                            window.location =
                              "https://abhibonus.s3-us-west-2.amazonaws.com/VideoFlix/VideoFlix+Training+Docs+updated.zip";
                          }}
                        >
                          <div>Download Tutorial Docs</div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </ContractStyle>
  );
};

const ContractStyle = styled.div`
  .btn-3 {
    max-width: 100% !important;
  }

  a {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
});

export default connect(mapStateToProps)(VideoFlix);
