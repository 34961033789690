import React from "react";
import ReactPlayer from "react-player";
const ReelMergeVideo = (props, ref) => {
  return (
    <div
      className="absolute video-object"
      style={{
        backgroundColor: "none",
        color: "#ffffff",
        fontFamily: "Poppins",
        fontSize: "20",
        fontStyle: "normal",
        fontWeight: "bold",
        height: "270",
        left: "0",
        lineHeight: "1",
        overflow: "hidden",
        textAlign: "center",
        textDecoration: "none",
        textTransform: "uppercase",
        top: "0",
        whiteSpace: "nowrap",
        maxWidth: "480px",
      }}
    >
      {props.selectVideo.length > 0 ? (
        <ReactPlayer
          url={props.selectVideo[props.selectVideo.length - 1].url}
          playing={true}
          muted={true}
          width={480}
          height={270}
          controls
        />
      ) : null}
    </div>
  );
};
export default ReelMergeVideo;
