import {
  FETCH_ACCOUNTS
} from './Types';
import axios from 'axios';
import {baseURL} from '../global/global';
import {setAlert} from './alert';



// Fetching Account Details
export const fetchSocialAccounts = (user_id) => async (dispatch,getState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({user_id});
  try{
    const res = await axios.post(`${baseURL}fetch-social-accounts`,body,config);
   
    if(res.data.status === true){
      dispatch({type:FETCH_ACCOUNTS,payload:res.data.data})
    }
  }catch(err){
    console.log(err);
  }
}



// Save Facebook Data
export const saveFacebook = (data) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify(data);
  

  try{
    const res = await axios.post(`${baseURL}save-facebook`,body,config);
   
    if(res.data.status === true){
      
      dispatch(setAlert(res.data.message,"success"));
      dispatch(fetchSocialAccounts(data.user_id));
    }else{
      dispatch(setAlert(res.data.message,"danger"));
    }
  }catch(err){
    console.log(err);
  }

}


// Delete Social Accounts

export const deleteSocialAccount = data => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(data);
  try{
    const res = await axios.post(`${baseURL}delete-social-network`,body,config);
   
    if(res.data.status === true){
      dispatch(setAlert(res.data.message,"success"));
      dispatch(fetchSocialAccounts(data.user_id));
    }else{
      dispatch(setAlert(res.data.message,"danger"));
    }
  }catch(err){
    console.log(err);
  }
  
}



// Delete Integration Account


// Delete Social Accounts

export const deleteIntegrationAccount = data => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(data);
  try{
    const res = await axios.post(`${baseURL}delete-integration`,body,config);
   
    if(res.data.status === true){
      dispatch(setAlert(res.data.message,"success"));
      dispatch(fetchSocialAccounts(data.user_id));
    }else{
      dispatch(setAlert(res.data.message,"danger"));
    }
  }catch(err){
    console.log(err);
  }
  
}



// Save Youtube data
export const saveYoutube = (data) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify(data);
  

  try{
    const res = await axios.post(`${baseURL}save-youtube`,body,config);
   
    if(res.data.status === true){
      dispatch(setAlert(res.data.message,"success"));
      dispatch(fetchSocialAccounts(data.user_id));
    }else{
      dispatch(setAlert(res.data.message,"danger"));
    }
  }catch(err){
    console.log(err);
  }
}


// Save reel app data

export const saveReelapp = (data,close) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify(data);
  

  try{
    const res = await axios.post(`${baseURL}connect-to-app`,body,config);
   
    if(res.data.status === true){
      dispatch(setAlert(res.data.message,"success"));
      dispatch(fetchSocialAccounts(data.user_id));
      close();
    }else{
      dispatch(setAlert(res.data.message,"danger"));
    }
  }catch(err){
    console.log(err);
  }
}