import React from "react";
function SaveHeader() {
  return (
    <header
      id="app-editor-header"
      style={{ background: "transparent", zIndex: "-1" }}
    >
      <div className="container-fluid px-4">
        <div className="row align-items-center"></div>
      </div>
    </header>
  );
}
export default SaveHeader;
