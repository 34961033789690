import {
  FETCH_PAYMENT_METHODS,
  FETCH_WHITE_LABEL_DETAILS,
  FETCH_WHITELABEL_TEMPLATE,
  SET_GENERAL_DATA,
  SET_SMTP,
  SET_PAYMENT,
  SET_PRICE,
  SET_TEMPLATE_ID,
  FETCH_WHITELABEL_MEMBERSHIP,
  CLEAR_WHITELABEL_DATA,
} from "./Types";
import axios from "axios";
import { baseURL } from "../global/global";

// Fetching data of White label users
export const getWhiteLabelUsers = (user) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ ...user });

  try {
    const res = await axios.post(`${baseURL}api/domain-details`, body, config);
    if (res.data.status === true) {
      dispatch({ type: FETCH_WHITE_LABEL_DETAILS, payload: res.data.data });
    }
  } catch (err) {
    console.log(err);
  }
};

// Fetching WhiteLabel Membership
export const getWhiteLabelMembership = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({});

  try {
    const res = await axios.post(
      `${baseURL}api/fetch-whitelabel-membership`,
      body,
      config
    );
    if (res.data.status === true) {
      dispatch({ type: FETCH_WHITELABEL_MEMBERSHIP, payload: res.data.data });
    }
  } catch (err) {
    console.log(err);
  }
};

// Fetching Payment Methods

export const getPaymentMethods = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({});

  try {
    const res = await axios.post(
      `${baseURL}api/fetch-payment-methods`,
      body,
      config
    );
    if (res.data.status === true) {
      dispatch({ type: FETCH_PAYMENT_METHODS, payload: res.data.data });
    }
  } catch (err) {
    console.log(err);
  }
};

// Fetching Template Methods

export const getTemplatMethods = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({});

  try {
    const res = await axios.post(`${baseURL}api/fetch-themes`, body, config);
    if (res.data.status === true) {
      dispatch({ type: FETCH_WHITELABEL_TEMPLATE, payload: res.data.data });
    }
  } catch (err) {
    console.log(err);
  }
};

// Set General Data
export const setGeneralData = (data) => (dispatch) => {
  dispatch({
    type: SET_GENERAL_DATA,
    payload: { ...data },
  });
};

// Set Smtp Data
export const setSmtpData = (data) => (dispatch) => {
  dispatch({
    type: SET_SMTP,
    payload: data,
  });
};

// Set Payment Data
export const setPaymentData = (data) => (dispatch) => {
  dispatch({
    type: SET_PAYMENT,
    payload: data,
  });
};

// Set Price Data
export const setPriceData = (data) => (dispatch) => {
  dispatch({
    type: SET_PRICE,
    payload: data,
  });
};

// Set template id Data
export const setTemplateIdData = (data) => (dispatch) => {
  dispatch({
    type: SET_TEMPLATE_ID,
    payload: data,
  });
};

// Clear White label data

export const clearWhiteLabelData = () => (dispatch) => {
  dispatch({
    type: CLEAR_WHITELABEL_DATA,
  });
};
