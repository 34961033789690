import React from "react";
import ReactPlayer from "react-player";
const RecordVideoObject = (props, ref) => {
    return (
        <div
            ref={ref}
            className="record-absolute record-video-object"
            style={props.objectItem.styles}
        >
            <ReactPlayer
                url={props.objectItem.source.url}
                playing={true}
                muted={true}
                width={1920}
                height={1080}
            />
        </div>
    );
};
export default React.forwardRef(RecordVideoObject);
