import { ReactComponent as ImagePlusIcon } from "../../../../../../assets/images/plus_1.svg";
import React from "react";
import AudioTrack from "./AudioTrack";
import PropTypes from "prop-types";

function AudioUpload({ uploadAudio, uploadAudioFunc, uploadLoader, user_id }) {
  return (
    <>
      <div className="input-group mb-3">
        <button
          id="up-media-img"
          className="up-img-btn btn btn-3 mb-3 text-white d-block mx-auto"
        >
          <input
            type="file"
            className="file-type"
            accept=".mp3"
            onChange={(e) => {
              uploadAudioFunc(e);
            }}
          />
          <svg
            width="12px"
            height="12px"
            className="ico v-center"
            viewBox=" 0 0 12 12"
            style={{ color: "#d19df8" }}
          >
            <ImagePlusIcon />
          </svg>
          Upload Audio
        </button>
      </div>
      <div className="card pb-3">
        <div id="your-music" className="card-header"></div>

        <div className="card-body mt-3 p-0">
          <div className="results-wrapper d-flex flex-wrap justify-content-center">
            {uploadLoader ? (
              <div
                className="spinner-grow"
                style={{ color: "#1cb7f2", width: "80px", height: "80px" }}
              >
                {" "}
              </div>
            ) : null}

            <AudioTrack audio={uploadAudio} user_id={user_id} />
          </div>
        </div>
      </div>
    </>
  );
}

AudioUpload.propTypes = {
  uploadAudio: PropTypes.array.isRequired,
  uploadAudioFunc: PropTypes.func.isRequired,
  uploadLoader: PropTypes.bool.isRequired,
};

export default AudioUpload;
