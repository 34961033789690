import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchClientTeamAccount } from "../../actions/accountManagement";
import PropTypes from "prop-types";
import ClientManagement from "./ClientManagement";
import TeamMemberManagement from "./TeamMemberManagement";

function AccountManage({
  fetchClientTeamAccount,
  accountManagement,
  auth: { user, loading },
  showAlert,
}) {
  const [user_id, setUserId] = useState("");
  const [members, setMembers] = useState({ clients: [], team_members: [] });

  // fetching useId
  useEffect(() => {
    setUserId(loading || !user.user_id ? "" : user.user_id);
  }, [loading, user]);

  useEffect(() => {
    if (user_id) {
      fetchClientTeamAccount(user_id);
    }
  }, [user_id, fetchClientTeamAccount]);

  useEffect(() => {
    setMembers({
      clients: accountManagement.clients,
      team_members: accountManagement.team_members,
    });
  }, [
    accountManagement.clients,
    accountManagement.team_members,
    accountManagement.loading,
  ]);

  return (
    <>
      <div className="container px-4 mt-2 mb-3 mb-lg-5">
        <h6
          className="text-white  mb-3 mt-2 Account-Management-headings"
          style={{ fontSize: "18px !important" }}
        >
          Client Account Management
        </h6>
        <div className="bg-table bg-table-sm">
          <div className="row">
            <div className="container my-4">
              {members.clients.length > 0 ? (
                <div className="table-responsive">
                  <span></span>
                  <table className="table table-border">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email Address</th>
                        <th scope="col">Account Type</th>
                        <th scope="col">Created</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="tbody-bg">
                      {members.clients.map((client) => {
                        return (
                          <ClientManagement
                            key={client.id}
                            client={client}
                            userGenId={user_id}
                            showAlert={showAlert}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="text-center ">
                  <h4>There is No Client Account</h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container px-4 mt-2 mb-5">
        <h6
          className="text-white  mb-3 mt-2 Account-Management-headings"
          style={{ fontSize: "18px !important" }}
        >
          Team Member Management
        </h6>
        <div className="bg-table bg-table-sm">
          <div className="row">
            <div className="container my-4">
              {members.team_members.length > 0 ? (
                <div className="table-responsive">
                  <span></span>
                  <table className="table table-border">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email Address</th>
                        <th scope="col">Account Type</th>
                        <th scope="col">Created</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="tbody-bg">
                      {members.team_members.map((team_member) => {
                        return (
                          <TeamMemberManagement
                            key={team_member.id}
                            teamMember={team_member}
                            userGenId={user_id}
                            showAlert={showAlert}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="text-center ">
                  <h4>There is No Team Member Account</h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

AccountManage.propTypes = {
  fetchClientTeamAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  accountManagement: PropTypes.object.isRequired,
  showAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  accountManagement: state.accountManagement,
  auth: state.auth,
});

export default connect(mapStateToProps, { fetchClientTeamAccount })(
  AccountManage
);
