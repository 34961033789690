import { GET_BRANDING_DATA, CLEAR_BRANDING_DATA } from "../actions/Types";

const initialState = {
  brandingdata: {},
  loading: true,
  data:false
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_BRANDING_DATA:
      return {
        ...state,
        brandingdata: { ...payload },
        loading: false,
      };

    case CLEAR_BRANDING_DATA:
      return initialState;
      
    default:
      return state;
  }
}
