import React from "react";
import { Link } from "react-router-dom";

function SocialLink({ social }) {
  return (
    <div className="row">
      <div className="col colmn-1 col-12">
        <div
          className="title-area row mx-0 justify-content-center align-items-center mb-5"
          style={{ marginTop: "130px" }}
        >
          <h1 className="text-white pl-2">Integrations</h1>
        </div>
      </div>
      <div className="col colmn-2 col-12">
        <div
          className="acc-list row mx-auto w-100 justify-content-center"
          style={{ maxWidth: "840px" }}
        >
          <a
            href="#fb-account"
            className="acc-wrapper text-center bg-white ml-0"
          >
            <div className="img-wrapper mb-2">
              {social.facebook.length > 0 ? (
                <img
                  className="img-fluid"
                  src="./assets/images/logo-facebook.svg"
                  alt="Facebook Logo"
                  width={56}
                  style={{ display: "block" }}
                />
              ) : (
                <img
                  className="img-fluid d-block mx-auto"
                  src="./assets/images/logo-facebook-grey.svg"
                  alt="Facebook Logo"
                  width={56}
                />
              )}
            </div>
            <p className="label">Facebook</p>
          </a>
          <a
            href="#yt-account"
            className="acc-wrapper not-connected text-center bg-white"
          >
            <div className="img-wrapper mb-2">
              {social.youtube.length > 0 ? (
                <img
                  className="img-fluid"
                  src="./assets/images/logo-youtube.svg"
                  alt="Youtube Logo"
                  width={56}
                />
              ) : (
                <img
                  className="img-fluid d-block mx-auto"
                  src="./assets/images/logo-youtube-grey.svg"
                  alt="Youtube Logo"
                  width={56}
                />
              )}
            </div>
            <p className="label">YouTube</p>
          </a>
          <a href="#sv-account" className="acc-wrapper text-center bg-white">
            <div className="img-wrapper mb-2">
              {social.syvid.length > 0 ? (
                <img
                  className="img-fluid"
                  src="./assets/images/logo-syvideo.svg"
                  alt="SyVid Logo"
                  style={{ display: "block" }}
                  width={56}
                />
              ) : (
                <img
                  className="img-fluid d-block mx-auto"
                  src="./assets/images/logo-syvideo-grey.png"
                  alt="SyVid Logo"
                  width={56}
                />
              )}
            </div>
            <p className="label">SyVID</p>
          </a>
          <a
            href="#scr-account"
            className="acc-wrapper not-connected text-center bg-white"
          >
            <div className="img-wrapper mb-2">
              {social.scriptreel.length > 0 ? (
                <img
                  className="img-fluid"
                  src="./assets/images/logo-scriptreel.svg"
                  alt="ScriptReel Logo"
                  width={56}
                  style={{ display: "block" }}
                />
              ) : (
                <img
                  className="img-fluid d-block mx-auto"
                  src="./assets/images/logo-scriptreel-grey.png"
                  alt="ScriptReel Logo"
                  width={56}
                />
              )}
            </div>
            <p className="label">ScriptReel</p>
          </a>
          <a
            href="#tr-account"
            className="acc-wrapper not-connected text-center bg-white"
          >
            <div className="img-wrapper mb-2">
              {social.trimreel.length > 0 ? (
                <img
                  className="img-fluid"
                  src="./assets/images/logo-trim.svg"
                  alt="TrimReel Logo"
                  width={83}
                  style={{ display: "block" }}
                />
              ) : (
                <img
                  className="img-fluid d-block mx-auto"
                  src="./assets/images/logo-trim-grey.png"
                  alt="TrimReel Logo"
                  width={83}
                />
              )}
            </div>
            <p className="label">TrimReel</p>
          </a>
          <a
            href="#lr-account"
            className="acc-wrapper text-center bg-white mr-0"
          >
            <div className="img-wrapper mb-2">
              {social.livereel.length > 0 ? (
                <img
                  className="img-fluid"
                  src="./assets/images/logo-livereel.png"
                  alt="LiveReel Logo"
                  width={56}
                  style={{ top: "8px", left: "-6px", display: "block" }}
                />
              ) : (
                <img
                  className="img-fluid d-block mx-auto"
                  src="./assets/images/logo-livereel-grey.png"
                  alt="LiveReel Logo"
                  width={56}
                  style={{ top: "8px", left: "-6px" }}
                />
              )}
            </div>
            <p className="label">LiveReel</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default SocialLink;
