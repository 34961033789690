import React, { useEffect } from "react";
import styled from "styled-components";
import { setLoader } from "../../actions/loader";
import Loader from "../loader/Loader";

const DashboardPopup = ({
  setClose,
  showPopupOne,
  closeShowPopup,
  user_id,
  setshowPopupOne,
  loader,
  setLoader,
}) => {
  useEffect(() => {
    return () => {
      setLoader(false);
    };
  }, []);
  return (
    <DashboardStyle>
      <div
        id="confirm-del"
        className="modal fade show d-block"
        style={{ background: "rgba(0,0,0,0.9)" }}
        tabIndex={-1}
        role="dialog"
        data-backdrop="static"
        aria-labelledby="delete-warn"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-lg modal-dialog-centered mx-auto"
          role="document"
          style={{ maxWidth: "88vw" }}
        >
          <div
            className="modal-content"
            style={{
              width: "100%",
              position: "relative",
            }}
          >
            <button
              type="button"
              className="close mb-3 text-right"
              data-dismiss="modal"
              aria-label="Close"
              style={{
                opacity: "1",
                outline: "none",
              }}
              onClick={() => {
                setClose(false);
              }}
            >
              <img
                className="img-fluid"
                src="./assets/images/close.svg"
                alt="Close"
              />
            </button>
            <div
              className="modal-body text-center m-0 p-0"
              style={{ background: "#000000" }}
            >
              <div style={{ minHeight: "36rem" }}>
                <div className="row justify-content-center mt-2">
                  <div className="col-7">
                    <h3
                      style={{
                        backgroundImage: `linear-gradient(150deg, #17d583 -11%, #00d2b3 39%, #5bc1de 59%, #1cb7f2 78% )`,
                        borderRadius: "5px",
                        color: "white",
                      }}
                    >
                      Unlock ALL 4 VideoReel Upgrade
                    </h3>
                  </div>
                </div>
                <div className="row justify-content-center mt-2">
                  <div className="col-6">
                    <h4>
                      ONLY{" "}
                      <span
                        className="price-dash"
                        style={{
                          color: "red",
                          fontSize: "1.8rem",
                          fontWeight: "bold",
                          textDecoration: "line-through",
                        }}
                      >
                        $247
                      </span>
                    </h4>
                  </div>
                </div>

                <div className="row justify-content-center mt-2">
                  <div className="col-8">
                    <h6>
                      Coupon Code (minus your current purchase cost):{" "}
                      <span
                        className="px-1"
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                          backgroundImage: `linear-gradient(150deg, #17d583 -11%, #00d2b3 39%, #5bc1de 59%, #1cb7f2 78% )`,
                          borderRadius: "5px",
                        }}
                      >
                        yesvideo
                      </span>
                    </h6>
                  </div>
                </div>
                <div className="row justify-content-center mt-1">
                  <div className="col-6">
                    <iframe
                      style={{
                        width: "100%",
                        height: "6rem",
                        outline: "none",
                        border: "0px",
                      }}
                      src="https://neotimer.com/timer/957"
                    ></iframe>
                  </div>
                </div>
                <div className="row  justify-content-center mt-2">
                  <div className="col-3">
                    <a
                      className="btn btn-3 accnt-mngmt-btn p-2"
                      style={{ fontSize: "1.1rem" }}
                      href="https://videoreel.io/bundle/"
                      target="_blank"
                    >
                      Click Here To Unlock
                    </a>
                  </div>
                </div>
                <div className="row justify-content-center mt-3">
                  <div className="col-2">
                    <div className="row">
                      <div className="col-12">
                        <img
                          src="https://videoreel.io/bundle/assets/images/ecovers/unlimited.png"
                          alt=""
                          style={{
                            width: "100%",
                            height: "30vh",
                          }}
                        />
                      </div>
                      <div className="col-12">
                        <h6>UNLIMITED</h6>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-1 d-flex align-items-center justify-content-center"
                    style={{ height: "30vh" }}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </div>
                  <div className="col-2">
                    <div className="row">
                      <div className="col-12">
                        <img
                          src="https://videoreel.io/bundle/assets/images/ecovers/dfy.png"
                          alt=""
                          style={{ width: "100%", height: "30vh" }}
                        />
                      </div>
                      <div className="col-12">
                        <h6>DFY</h6>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-1 d-flex align-items-center justify-content-center"
                    style={{ height: "30vh" }}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </div>
                  <div className="col-2">
                    <div className="row">
                      <div className="col-12">
                        <img
                          src="https://videoreel.io/bundle/assets/images/ecovers/international.png"
                          alt=""
                          style={{ width: "100%", height: "30vh" }}
                        />
                      </div>
                      <div className="col-12">
                        <h6>INTERNATIONAL</h6>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-1 d-flex align-items-center justify-content-center"
                    style={{ height: "30vh" }}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </div>
                  <div className="col-2">
                    <div className="row">
                      <div className="col-12">
                        <img
                          src="https://videoreel.io/bundle/assets/images/ecovers/business.png"
                          alt=""
                          style={{ width: "100%", height: "30vh" }}
                        />
                      </div>
                      <div className="col-12">
                        <h6>BUSINESS</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  justify-content-center mt-4">
                  <div className="col-4">
                    <h6>& so much more</h6>
                    <div class="custom-control custom-checkbox mb-3 d-flex justify-content-center">
                      {loader === true ? (
                        <div style={{ height: "5rem" }}>
                          <Loader />
                        </div>
                      ) : (
                        <>
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheckbronze"
                            name="bronzeCheck"
                            checked={showPopupOne}
                            onChange={(e) => {
                              setshowPopupOne((value) => !value);
                              closeShowPopup({
                                user_id: user_id,
                                status: e.target.checked,
                              });
                            }}
                          />
                          <label
                            class="custom-control-label"
                            for="customCheckbronze"
                            style={{ paddingTop: "5px" }}
                          >
                            Never show again
                          </label>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardStyle>
  );
};

const DashboardStyle = styled.div`
  .btn-3 {
    max-width: 100% !important;
  }
  a {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #confirm-del {
    overflow: scroll;
  }

  .fa-plus {
    font-size: 1.5rem !important;
    color: white !important;
  }

  .price-dash {
    text-decoration: line-through !important;
  }

  h6,
  h3,
  h4,
  h5 {
    color: white;
  }
`;

export default DashboardPopup;
