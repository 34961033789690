import React, { useState } from "react";
import GoogleLogin from "react-google-login";
import ConfirmDelete from "../../confirmdelete/ConfirmDelete";
import {
  deleteSocialAccount,
  saveYoutube,
} from "../../../actions/socialAccount";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Youtube = ({ accounts, userGenId, deleteSocialAccount, saveYoutube }) => {
  const [confirmDelete, setConfirmDelete] = useState({
    status: false,
    id: 0,
  });

  const handleDelete = (id) => {
    setConfirmDelete({
      status: true,
      id: id,
    });
  };

  const handleConfirmClose = () => {
    setConfirmDelete({
      status: false,
      id: 0,
    });
  };

  const handleConfirmDelete = async () => {
    const data = {
      user_id: userGenId,
      network_id: confirmDelete.id,
    };

    await deleteSocialAccount(data);
    handleConfirmClose();
  };

  const responseGoogle = (response) => {
    if (response.code) {
      const data = {
        user_id: userGenId,
        code: response.code,
      };
      console.log(userGenId);
      saveYoutube(data);
    }
  };

  return (
    <>
      <div
        id="yt-account"
        className={`acc-details-wrapper row mx-auto p-4 mb-3 bg-white ${
          accounts.length > 0 ? "" : "not-connected"
        }`}
      >
        <div className="img-wrapper pr-2">
          {accounts.length > 0 ? (
            <img
              className="img-fluid"
              src="./assets/images/logo-youtube.svg"
              alt="YouTube Logo"
              style={{ display: "block" }}
            />
          ) : (
            <img
              className="img-fluid"
              src="./assets/images/logo-youtube-grey.svg"
              alt="YouTube Logo"
            />
          )}
        </div>
        <div className="inner-row-wrapper flex-grow-1">
          {accounts.length <= 0 ? (
            <div className="inner-row d-flex flex-row align-items-center">
              <div className="acc-details flex-grow-1 px-2">
                <h6 className="mb-1">
                  <span className="md-txt">YouTube </span>
                </h6>
                <p className="status">Not Connected</p>
              </div>
              <div className="btns-wrapper d-flex pl-lg-3">
                <GoogleLogin
                  clientId="385524880993-kb22h3hvlooun9i0908bsm29bkeafhio.apps.googleusercontent.com"
                  render={(renderProps) => (
                    <button
                      onClick={renderProps.onClick}
                      // disabled={renderProps.disabled}
                      className="btn btn-2 w-100 connect youtube"
                    >
                      Connect
                    </button>
                  )}
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  scope="https://www.googleapis.com/auth/youtube"
                  responseType="code"
                  accessType="offline"
                  prompt="consent"
                  cookiePolicy={"single_host_origin"}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="inner-row d-flex flex-row align-items-center">
                {accounts.map((account) => (
                  <>
                    <div className="acc-details flex-grow-1 px-2">
                      <h6 className="mb-1 text-left">
                        <span className="md-txt text-left">YouTube </span>
                        <span className="usr-email md-txt">
                          {account.email}
                        </span>
                      </h6>
                      <p className="status text-left">
                        <span className="security-number sm-txt text-left">
                          {account.title} - {account.channel_id}
                        </span>{" "}
                        Added on{" "}
                        <span className="date-added sm-txt">
                          {account.created}
                        </span>
                      </p>
                    </div>
                    <div className="btns-wrapper d-flex pl-lg-3">
                      {/* <Link
                      to="#"
                      className="btn btn-2 reconnect w-100 mr-xl-2  mb-3"
                    >
                      Reconnect
                    </Link> */}
                      <button
                        className="btn btn-2 delt w-100 ml-xl-2 mb-3"
                        data-toggle="modal"
                        data-target="#confirm-del"
                        onClick={() => handleDelete(account.id)}
                      >
                        Delete
                      </button>
                    </div>
                  </>
                ))}
              </div>

              <div className="conn-btn-wrapper text-center">
                <GoogleLogin
                  clientId="385524880993-h6bgtmsiisma1ab8d6stae9e5aasotjk.apps.googleusercontent.com"
                  render={(renderProps) => (
                    <button
                      onClick={renderProps.onClick}
                      // disabled={renderProps.disabled}
                      className="btn btn-2 w-100 mr-2 connect"
                    >
                      Connect New Account
                    </button>
                  )}
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  scope="https://www.googleapis.com/auth/youtube"
                  responseType="code"
                  accessType="offline"
                  prompt="consent"
                  cookiePolicy={"single_host_origin"}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {confirmDelete.status ? (
        <ConfirmDelete
          close={handleConfirmClose}
          confirm={handleConfirmDelete}
        />
      ) : null}
    </>
  );
};

Youtube.propTypes = {
  deleteSocialAccount: PropTypes.func.isRequired,
  saveYoutube: PropTypes.func.isRequired,
};

export default connect(null, { deleteSocialAccount, saveYoutube })(Youtube);
