import React from "react";
import GsDrag from "../../GsDrag/GsDrag";
const ImageObject = (props,ref) => {
    const isSelected = props.objectItem.isSelected;
    const handleVideo = (e,id) => {
        //props.objectOnClick(id);
    }
    const style = {
    ...props.objectItem.styles,
        top:0,
        left:0
    }
    return(
        <>
            {isSelected ? <GsDrag object={props.objectItem}> <div onClick={(e) => handleVideo(e,props.objectItem.id)} className="absolute image-object" style={style}>
                <img ref={ref} src={props.objectItem.source.url}  alt="Image"/>
            </div> </GsDrag> :<div onClick={(e) => handleVideo(e,props.objectItem.id)} className="absolute image-object" style={props.objectItem.styles}>
                <img ref={ref} src={props.objectItem.source.url}  alt="Image"/>
            </div> }
        </>

    )
}
export default React.forwardRef(ImageObject);