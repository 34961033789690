import React, { useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import ConfirmDelete from "../../confirmdelete/ConfirmDelete";
import { connect } from "react-redux";
import axios from "axios";
import PropTypes from "prop-types";
import {
  saveFacebook,
  deleteSocialAccount,
} from "../../../actions/socialAccount";

const Facebook = ({
  accounts,
  userGenId,
  saveFacebook,
  deleteSocialAccount,
}) => {
  const [details, setDetails] = useState({
    name: false,
    userId: false,
    accessToken: false,
    openPopup: false,
    buttonText: "Connect",
    email: false,
    pages: [],
  });

  const [groups, setGroups] = useState([]);
  const [selection, setSelection] = useState({
    type: false,
    id: false,
    accessToken: false,
    name: false,
  });

  const [dropValue, setDropValue] = useState("Select An Account");
  const [confirmDelete, setConfirmDelete] = useState({
    status: false,
    id: 0,
  });

  const handleDelete = (id) => {
    setConfirmDelete({
      status: true,
      id: id,
    });
  };

  const closeConnectModel = () => {
    setDetails({ ...details, openPopup: false });
  };
  const handleConfirmClose = () => {
    setConfirmDelete({
      status: false,
      id: 0,
    });
  };

  const handleConfirmDelete = async () => {
    const data = {
      user_id: userGenId,
      network_id: confirmDelete.id,
    };

    await deleteSocialAccount(data);
    handleConfirmClose();
  };

  const responseFacebook = async (response) => {
    try {
      const res = await axios.get(
        `https://graph.facebook.com/me/accounts?type=page&access_token=${response.accessToken}`
      );

      setDetails({
        ...details,
        name: response.name,
        userId: response.userID,
        openPopup: true,
        buttonText: "Connect",
        accessToken: response.accessToken,
        pages: res.data.data,
      });
    } catch (err) {
      console.log(err);
    }

    // Fetch the group List

    try {
      const res = await axios.get(
        " https://graph.facebook.com/me/groups?access_token=" +
          response.accessToken
      );

      setGroups(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    const selectedValue = e.target.value.split("___CR___");
    setSelection({
      type: selectedValue[2],
      id: selectedValue[3],
      accessToken: selectedValue[1],
      name: selectedValue[0],
    });
    setDropValue(e.target.value);
  };

  const handleClick = async () => {
    setDetails({
      ...details,
      buttonText: "Connecting...",
    });
    const data = {
      user_id: userGenId,
      title: selection.name,
      token: selection.accessToken,
      fb_id: selection.id,
      category: selection.type,
      email: details.email,
    };

    await saveFacebook(data);
    setDetails({
      ...details,
      openPopup: false,
    });
  };

  return (
    <>
      <div
        id="fb-account"
        className={`acc-details-wrapper row mx-auto p-4 mb-3 bg-white ${
          accounts.length > 0 ? "" : "not-connected"
        }`}
      >
        <div className="img-wrapper pr-2">
          {accounts.length > 0 ? (
            <img
              className="img-fluid"
              src="./assets/images/logo-facebook.svg"
              alt="Facebook Logo"
              style={{ display: "block" }}
            />
          ) : (
            <img
              className="img-fluid"
              src="./assets/images/logo-facebook-grey.svg"
              alt="Facebook Logo"
            />
          )}
        </div>
        <div className="inner-row-wrapper flex-grow-1">
          {accounts.length <= 0 ? (
            <div className="inner-row d-flex flex-row align-items-center">
              <div className="acc-details flex-grow-1 px-2">
                <h6 className="mb-1">
                  <span className="md-txt">Facebook </span>
                </h6>
                <p className="status">Not Connected</p>
              </div>
              <div className="btns-wrapper d-flex pl-lg-3">
                <FacebookLogin
                  appId="780210132546000"
                  fields="name,email"
                  scope="pages_manage_posts,publish_to_groups,publish_video"
                  callback={responseFacebook}
                  render={(renderProps) => (
                    <button
                      onClick={renderProps.onClick}
                      className="btn btn-2 w-100 connect"
                    >
                      Connect
                    </button>
                  )}
                />
              </div>
            </div>
          ) : (
            <>
              {accounts.map((account) => (
                <div
                  key={account.id}
                  className="inner-row d-flex flex-row  align-items-center"
                >
                  <div className="acc-details flex-grow-1 px-2">
                    <h6 className="mb-1 text-left">
                      <span className="md-txt text-left">Facebook </span>
                      <span className="usr-email md-txt">{account.email}</span>
                    </h6>
                    <p className="status text-left">
                      <span className="security-number sm-txt text-left">
                        {account.title} - {account.fb_id}
                      </span>{" "}
                      Added on{" "}
                      <span className="date-added sm-txt">
                        {account.created}
                      </span>
                    </p>
                  </div>
                  <div className="btns-wrapper d-flex pl-lg-3">
                    {/* <Link to="#" className="btn btn-2 reconnect w-100 mr-xl-2 mb-3">
              Reconnect
            </Link> */}
                    <button
                      className="btn btn-2 delt w-100 ml-xl-2 mb-3"
                      data-toggle="modal"
                      data-target="#confirm-del"
                      onClick={() => handleDelete(account.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
              <div className="conn-btn-wrapper text-center">
                <FacebookLogin
                  appId="3728059593892323" //APP ID NOT CREATED YET
                  fields="name,email"
                  scope="pages_manage_posts,publish_to_groups"
                  callback={responseFacebook}
                  render={(renderProps) => (
                    <button
                      onClick={renderProps.onClick}
                      className="btn btn-2 w-100 mr-2 connect"
                    >
                      Connect New Account
                    </button>
                  )}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div
        id="publish"
        className={`modal  ${details.openPopup ? "fade show d-block" : ""}`}
        style={{ background: "rgba(0,0,0,0.9)" }}
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="modal-title"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered mx-auto"
          role="document"
        >
          <div className="modal-content">
            <button
              type="button"
              className="close mb-3 text-right"
              data-dismiss="modal"
              aria-label="Close"
              style={{ opacity: "1" }}
              onClick={closeConnectModel}
            >
              <img
                className="img-fluid"
                src="./assets/images/close.svg"
                alt="Close"
              />
            </button>
            <div className="modal-body bg-white text-center">
              <h4
                className="modal-title mb-4"
                style={{ lineHeight: "1.33", color: "#000000" }}
              >
                Select an account
              </h4>

              <div className="mb-2">
                <select
                  className="form-control"
                  onChange={handleChange}
                  value={dropValue}
                >
                  <option value={"Select An Account"}>Select An Account</option>
                  <optgroup label="Your Facebook Pages">
                    {details.pages.map((page) => (
                      <option
                        key={page.id}
                        value={
                          page.name +
                          "___CR___" +
                          page.access_token +
                          "___CR___page___CR___" +
                          page.id
                        }
                      >
                        {page.name}
                      </option>
                    ))}
                  </optgroup>
                  <optgroup label="Your Facebook Groups">
                    {groups.map((group) => (
                      <option
                        key={group.id}
                        value={
                          group.name +
                          "___CR___" +
                          details.accessToken +
                          "___CR___group___CR___" +
                          group.id
                        }
                      >
                        {group.name}
                      </option>
                    ))}
                  </optgroup>
                </select>
              </div>
              <button
                //id="pub-proj"
                type="button"
                className="btn btn-2 w-100 mr-2 connect"
                onClick={handleClick}
                disabled={dropValue === "Select An Account" ? true : false}
                style={{ padding: "14px" }}
              >
                {details.buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
      {confirmDelete.status ? (
        <ConfirmDelete
          close={handleConfirmClose}
          confirm={handleConfirmDelete}
        />
      ) : null}
    </>
  );
};

Facebook.propTypes = {
  saveFacebook: PropTypes.func.isRequired,
  deleteSocialAccount: PropTypes.func.isRequired,
};

export default connect(null, { saveFacebook, deleteSocialAccount })(Facebook);
